import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GetImages from '../components/Home/GetImages.vue'
import Legal from '@/views/Legal.vue'
import checkLocalStorage from '@/helpers/checkLocalStorage'
import Account from '@/views/Account.vue'
import About from '@/views/About.vue'
import AddIfcHearts from '@/views/AddIfcHearts.vue'
import beforeEnterLoader from '@/helpers/beforeEnterLoader'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'GetImages',
    component: GetImages
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/add-IFCHearts',
    name: 'AddIFCHearts',
    component: AddIfcHearts
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const div = beforeEnterLoader()

  // @ts-ignore
  const token = localStorage.getItem(process.env.VUE_APP_COOKIE)

  if (token !== null && token !== '') {
    await checkLocalStorage(token)
  }

  document.body.removeChild(div)

  next()
})

export default router
