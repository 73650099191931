import Store from '@/store/modules/Viewer'
import csvBody from '@/controllers/processCsv/csvBody'
import csvDynamicHeaders from '@/controllers/processCsv/csvDynamicHeaders'
import handleSubsets from '@/controllers/processCsv/handleSubsets'

export default async () => {
  try {
    const file = Store.fileForCsvDownload

    if (file) {
      const properties = Store.properties

      let props: any = {}

      if (Store.selectedExpressIDs.length) {
        for (const expressId of Store.selectedExpressIDs) {
          props[expressId] = properties[expressId]
        }

        Store.setUserPlan('premium')
      } else if (Store.selectedIfcClasses.length) {
        const ids: number[] = []

        for (const item of Store.flatStructureItems) {
          if (item.hasLeaves) {
            for (const ifcClass in item.children) {
              if (Store.selectedIfcClasses.includes(ifcClass)) {
                ids.push(...item.children[ifcClass])
              }
            }
          }
        }

        for (const id of ids) {
          props[id] = properties[id]
        }

        Store.setUserPlan('premium')
      } else {
        props = null
        Store.setUserPlan('free')
      }

      const headers = csvDynamicHeaders(props ? props : properties)

      const fileStr: string = csvBody(props ? props : properties, headers)

      const fileText = new Blob([fileStr], {
        type: 'text/plain'
      })

      const csvUrl = URL.createObjectURL(fileText)

      const fileTitle = `I❤IFC_${file.name.replace('.ifc', '')}.csv`

      handleSubsets(
        props ? props : properties,
        file.name,
        csvUrl,
        fileTitle,
        properties
      )
    }
  } catch (error) {
    console.error('error' /*error*/)
    throw new Error(error)
  }
}
