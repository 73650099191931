export default `
ILoveIFC was born with one mission: to effortlessly operate with IFC files in your daily workflow. IFC is the primary way to share information in the AEC world
and its role and importance grow daily.</br></br>
The ILoveIFC team has been working with IFC long enough to know that IFC schema can be tricky or difficult to understand. Some basic operations require a
deep knowledge of what's behind the curtain. That's why many IFC utilities have such a high cost.</br></br>
We believe that working with IFC files can be easy and affordable. <strong>Wouldn't it be nice to have tools that make it possible and spend more time working on your
projects instead of dealing with IFC schema for basic operations like splitting, joining, or acquiring data?</strong></br></br>
We hope ILoveIFC solves all these IFC-related problems and becomes a useful tool for your work. That's why our small developers team works tirelessly. To offer 
you an enjoyable journey working with IFC files.
`
