
import { Component, Vue } from 'vue-property-decorator'
import ActionsViewer from '@/components/ActionsViewer/ActionsViewer.vue'
import Banner from '@/components/Home/Banner.vue'
import Store from '@/store/modules/Viewer'

@Component({
  components: { ActionsViewer, Banner }
})
export default class Home extends Vue {
  private get selectedImage(): string {
    return Store.selectedImage
  }

  beforeMount() {
    const userLearned = localStorage.getItem(
      process.env.VUE_APP_COOKIE_USER_LEARNED as string
    )

    if (!userLearned) {
      localStorage.setItem(
        process.env.VUE_APP_COOKIE_USER_LEARNED as string,
        'false'
      )

      Store.setUserLearned(false)
    } else {
      Store.setUserLearned(true)
    }
  }

  mounted() {
    if (this.$route.hash === '#verified') {
      this.$toasted.success('User verified successfully, please access')
      this.showLogin()
    } else if (this.$route.hash === '#error') {
      this.$toasted.info('Error at verify. Maybe you are already active')
      this.showLogin()
    }
  }

  private showLogin() {
    this.$router.replace({ hash: '' })
    this.$root.$emit('show-login')
  }
}
