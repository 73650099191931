export default (line: string): string => {
  return line
    .substring(line.indexOf('=') + 1, line.indexOf('('))
    .trim()
    .trimLeft()
    .trimRight()
    .trimEnd()
    .trimStart()
    .replace(' ', '')
}
