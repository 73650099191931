export default
`
               ####     ##              ifc-csv parser by ILoveIFC © 2022
           #######    ########          to have full access to all the features
        ########   ##############       please, purchase the premium plan at:
      ########   ##################
   ##########   #####################      ****************************
   ###########  ######################     *   https://iloveifc.com   *
     ##########    ##################      ****************************
       ###########   #############
    ##   ########      ########    ##
   #####          ####          ######
    #####################   ##########
      ##################   ########
        #############    ########
           ########   ########
           
`
