
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Tab from '@/components/shared/Tab.vue'
import Store from '@/store/modules/Viewer'
import AttributesItem from '@/components/ActionsViewer/AttributesTree/AttributesItem.vue'
import flatStructure from '@/controllers/flatStructure'
import { directive as onClickaway } from 'vue-clickaway'
import IFCClasses from '@/components/ActionsViewer/AttributesTree/IFCClasses.vue'
import checkValidityForSplit from '@/helpers/checkValidityForSplit'
import expendOneCredit from '@/helpers/expendOneCredit'
import checkValidSelectionFreeMode from '@/helpers/checkValidSelectionFreeMode'
import { Components } from 'openbim-components'

// @ts-ignore
@Component({
  components: { IFCClasses, Tab, AttributesItem },
  directives: { onClickaway }
})
export default class AttributesTree extends Vue {
  @Prop() files!: File[]
  @Prop() viewer!: Components
  @Prop({ default: false }) mapBox!: boolean

  private showStructureTree: boolean = false
  private showClassTree: boolean = true
  private firstItem: any = null
  private showCsvAsking: boolean = false
  private confirmExtract: boolean = false
  private showDownloadTypePopup: boolean = false

  private get structure(): any {
    return Store.spatialStructure
  }

  private get flatStructure(): any[] {
    return Store.flatStructureItems
  }

  public get activeAction(): string {
    return Store.activeAction
  }

  private get stylePanel(): boolean {
    return Store.stylePanel
  }

  mounted() {
    if (this.structure) this.createFlatStructure()
  }

  private async checkDownloadCsv() {
    if (Store.selectedIfcClasses.length > 2 || !checkValidSelectionFreeMode()) {
      await expendOneCredit(2, false, '')
    } else {
      await expendOneCredit(52, true, '')
    }

    this.downloadCsv()
  }

  private downloadCsv() {
    this.$root.$emit('download-csv')
    this.closePopup()
  }

  private async createFlatStructure() {
    if (this.structure.children !== undefined) {
      await flatStructure(this.structure, this.viewer)
    }
  }

  private structureTree(title: string) {
    if (title === 'Structure') {
      this.showStructureTree = true
      this.showClassTree = false
    } else {
      this.showClassTree = true
      this.showStructureTree = false
    }
  }

  private setFirstItem() {
    const firstItem = this.flatStructure[0]

    if (firstItem) {
      this.firstItem = {
        expressID: firstItem.expressID,
        children:
          firstItem.children !== undefined
            ? firstItem.children.map((ch: any) => ch.expressID)
            : []
      }
    }
  }

  private closePopup() {
    if (this.showCsvAsking) {
      this.showCsvAsking = false
      this.confirmExtract = false
    }
  }

  private get checkValidity(): boolean {
    if (this.activeAction === 'Split IFC') {
      return checkValidityForSplit()
    } else {
      return true
    }
  }

  private downloadXlsx() {
    this.$root.$emit('download-xlsx')
  }

  private handleSubmit() {
    if (
      this.activeAction === 'Get Attributes' ||
      this.activeAction === 'Relocate IFC'
    ) {
      this.showDownloadTypePopup = true
    } else if (this.activeAction === 'Split IFC') {
      this.$root.$emit('split-model')
    }
  }

  @Watch('structure', { deep: true })
  onStructureChange() {
    if (this.structure.children !== undefined && !this.mapBox) {
      this.createFlatStructure()
    }
  }

  @Watch('flatStructure')
  onFlatStructureChange() {
    if (this.flatStructure) this.setFirstItem()
  }

  @Watch('showStructureTree')
  onShowStructureTreeChange() {
    if (!this.showStructureTree) {
      Store.resetSelectedExpressID()
    }
  }

  @Watch('showClassTree')
  onShowClassTreeChange() {
    if (!this.showClassTree) {
      Store.resetSelectedIfcClass()
    }
  }
}

// todo: crear un enum con los posibles estados activos en la plataforma, referenciarlos para que el update afecte a todos
