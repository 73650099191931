
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Viewer'
import moment from 'moment'

@Component({
  directives: { onClickaway }
})
export default class ModalTicket extends Vue {
  private payments: any[] = []

  private get userName(): string {
    return Store.username
  }

  mounted() {
    this.getPayments()
    this.getUser()
  }

  private async getUser() {
    const {
      data: { username }
    } = await this.$apollo.query({
      query: require('@/graphql/queries/username.graphql')
    })

    Store.setUsername(username)
  }

  private async getPayments() {
    try {
      const {
        data: { getPayments }
      } = await this.$apollo.query({
        query: require('@/graphql/queries/get-payments.graphql')
      })

      this.payments = getPayments.sort((a: any, b: any) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    } catch (error) {
      console.log(error, 'error-get-payments')
    }
  }

  private checkPayAction(action: number): string {
    if (action === 1) {
      return 'Join IFC'
    } else if (action === 2) {
      return 'Get attributes(csv)'
    } else if (action === 3) {
      return 'Get attributes(xlsx)'
    } else if (action === 4) {
      return 'Split IFC'
    } else if (action === 5) {
      return 'Screenshot relocate'
    } else if (action === 6) {
      return 'Screenshot'
    } else if (action === 7) {
      return 'Screenshot renderAI'
    } else if (action === 51) {
      return 'Join IFC'
    } else if (action === 52) {
      return 'Get attributes(csv)'
    } else if (action === 53) {
      return 'Get attributes(xlsx)'
    } else if (action === 54) {
      return 'Split IFC'
    }
    return ''
  }

  private checkPayBackground(action: number): string {
    if (action === 1) {
      return 'bg-red-50'
    } else if (action === 2) {
      return 'bg-green-50'
    } else if (action === 3) {
      return 'bg-green-50'
    } else if (action === 4) {
      return 'bg-red-50'
    } else if (action === 5) {
      return 'bg-yellow-50'
    } else if (action === 6) {
      return 'bg-yellow-50'
    } else if (action === 7) {
      return 'bg-yellow-50'
    } else if (action === 51) {
      return 'bg-gray-50'
    } else if (action === 52) {
      return 'bg-gray-50'
    } else if (action === 53) {
      return 'bg-gray-50'
    } else if (action === 54) {
      return 'bg-gray-50'
    }
    return ''
  }

  private isFree(action: number): string {
    if (action === 51 || action === 52 || action === 53 || action === 54) {
      return ''
    } else {
      return '1 IFC ❤ expended'
    }
  }

  private setDatePay(createdAt: number): any {
    const date = moment(Number(createdAt)).format('DD MMM YYYY hh:mm a')

    return date
  }

  @Emit() close() {}
}
