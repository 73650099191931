import Store from '@/store/modules/Viewer'
import freeHeads from '@/config/freeHeads'
import getIfcClass from '@/helpers/getIfcClass'

export default (properties: any, headersStr: string) => {
  const headers = headersStr.split(';')

  const lines: string[] = []

  delete properties.coordinationMatrix
  delete properties.globalHeight

  for (const item in properties) {
    const innerLine: string[] = []

    for (const header of headers) {
      if (header === 'type') {
        innerLine.push(getIfcClass(properties[item][header]))
      } else {
        innerLine.push(
          properties[item][header] !== undefined &&
            properties[item][header] !== null
            ? typeof properties[item][header] === 'object'
              ? properties[item][header].value
              : properties[item][header]
            : ''
        )
      }
    }

    if (innerLine.length) {
      lines.push(innerLine.join(';'))
    }
  }

  const plan = Store.userPlan

  return `${plan === 'free' ? freeHeads : ''}${headersStr}\n${lines.join('\n')}`
}
