var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"viewer-upload-wrapper",class:_vm.stylePanel ? 'top-30' : 'top-12'},[_c('div',{staticClass:"viewer-upload-inner",class:{
      'overflow-y-auto':
        _vm.activeAction === 'Split IFC' ||
        ('Get Attributes' && _vm.filesList.length > 1)
    },attrs:{"title":_vm.handleUploadTitle},on:{"click":_vm.handleAddFile,"mouseover":_vm.cursorAdd,"mouseleave":_vm.cursorDefault}},[(_vm.activeAction === 'Join IFC')?_c('span',{staticClass:"viewer-upload-add",class:{ 'animate-bounce': _vm.filesList.length === 1 },domProps:{"textContent":_vm._s('+')}}):_vm._e(),_vm._l((_vm.filesList),function(file,index){return _c('div',{key:index,staticClass:"viewer-list"},[_c('div',{staticClass:"viewer-upload-container"},[_c('p',{staticClass:"viewer-files-title",attrs:{"title":file.name},domProps:{"textContent":_vm._s(`#${index + 1} ${file.name}`)},on:{"mouseover":function($event){_vm.showOptionsIndex = index}}})])])})],2),_c('div',{staticClass:"relative",attrs:{"title":_vm.userHasCredits && _vm.filesList.length > 2
        ? ''
        : 'Join more than 2 files with IFCHearts'},on:{"click":_vm.handleJoinIfc}},[(_vm.activeAction === 'Join IFC')?_c('div',{staticClass:"viewer-button-process",class:{
        'disabled':
          _vm.filesList.length < 2 || (_vm.filesList.length > 2 && !_vm.userHasCredits)
      },domProps:{"textContent":_vm._s('Join models')}}):_vm._e(),_c('div',{staticClass:"absolute right-0 inline-flex items-center justify-center w-10 h-10 font-bold text-white bg-red-500 border-2 border-white rounded-full -bottom-4 hover:bg-red-400",domProps:{"textContent":_vm._s(_vm.filesList.length)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }