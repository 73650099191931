import Store from '@/store/modules/Viewer'
import { defaultClient } from '@/vue-apollo'

export default async (
  action: number,
  isFree: boolean,
  observations: string
) => {
  try {
    const {
      data: { expendOneCredit }
    } = await defaultClient.mutate({
      variables: { action, isFree, observations },
      mutation: require('@/graphql/mutations/expend-one-credit.graphql')
    })

    Store.setUserCredits(expendOneCredit)
  } catch (error) {
    console.error('error', error)
  }
}
