
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

// @ts-ignore
@Component({
  directives: { onClickaway }
})
export default class MenuMobile extends Vue {
  @Prop() actions!: any[]

  private activeIndex: number = -1

  private handleAction(action: string) {
    this.actionActive(action)
  }

  @Emit() actionActive(action: string): string {
    return action
  }

  @Emit() close() {}
}
