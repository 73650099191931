import ifcElements from '@/config/IFC/ifcProducts'
import lineMethod from '@/helpers/lineMethod'
import lineReference from '@/helpers/lineReference'
import Store from '@/store/modules/Viewer'
import formatSplitIfcFile from '@/controllers/splitModel/formatSplitIfcFile'

export default (lines: string[], headers: string) => {
  const linesSelected: string[] = []

  for (const line of lines) {
    const method = lineMethod(line)

    if (ifcElements.includes(method)) {
      const reference = lineReference(line)

      if (Store.selectedExpressIDs.includes(reference)) {
        linesSelected.push(line)
      }
    } else {
      linesSelected.push(line)
    }
  }

  // todo: optimizar selectedItems
  return formatSplitIfcFile(headers, linesSelected.join(';'))
}
