
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store/modules/Viewer'

@Component({
  apollo: {
    userEmail: {
      query: require('@/graphql/queries/user-email.graphql')
    }
  }
})
export default class Account extends Vue {
  private avatar: string = ''
  private email: string = ''
  private imageSrc: string = ''
  private imageFile: File | null = null

  async created() {
    if (!Store.userId) {
      await this.$router.push({ name: 'Home' })
    }

    const {
      data: { userEmail }
    } = await this.$apollo.queries.userEmail.refetch()

    this.email = userEmail

    if (Store.avatar) this.imageSrc = Store.avatar
  }

  private openPrompt() {
    ;(document.getElementById('avatar') as HTMLInputElement).click()
  }

  private async handleImage(event: any | null) {
    const image = event.target.files[0]

    if (image) {
      this.imageFile = image
      const decoded = await image.arrayBuffer()
      const data = new Uint8Array(decoded)
      const blob = new Blob([data.buffer], { type: 'image/png' })
      this.imageSrc = URL.createObjectURL(blob)
      Store.setAvatar(this.imageSrc)
    }
  }

  private async updateUserInfo() {
    try {
      const image = this.imageFile

      const {
        data: { updateUserInfo }
      } = await this.$apollo.mutate({
        variables: { image },
        mutation: require('@/graphql/mutations/update-user-info.graphql')
      })

      if (updateUserInfo) {
        this.$toasted.success('User information updated')
      }
    } catch (error) {
      console.error('error', /*error*/)
      this.$toasted.error('Error updating user information')
    }
  }

  private deletePic() {
    this.imageSrc = ''
    Store.setAvatar('')
  }
}
