
import { Component, Vue, Watch } from 'vue-property-decorator'
import Logo from '../assets/icons/logo.svg'
import IconMenu from '@/assets/icons/menu.svg'
import LoginCard from '@/components/Home/LoginCard.vue'
import RegisterCard from '@/components/Home/RegisterCard.vue'
import Store from '@/store/modules/Viewer'
import Avatar from '@/components/shared/Avatar.vue'
import MenuMobile from '@/components/MenuMobile.vue'
import UserSettingsPopup from '@/components/UserSettingsPopup.vue'
import IconCredits from '@/assets/icons/money-bill-wave.svg'
import IconILove from '@/assets/icons/icon-ilove.svg'

@Component({
  components: {
    Avatar,
    RegisterCard,
    LoginCard,
    Logo,
    IconMenu,
    MenuMobile,
    UserSettingsPopup,
    IconCredits,
    IconILove
  }
})
export default class Navbar extends Vue {
  private activeIndex: string = ''
  private showLogin: boolean = false
  private showRegister: boolean = false
  private showMobileMenu: boolean = false
  private showModalUser: boolean = false
  private pingClass: boolean = false

  private get activeAction(): string {
    return Store.activeAction
  }

  private get credits(): number {
    return Store.userCredits
  }

  mounted() {
    this.$root.$on('show-login', () => {
      this.showLogin = true
      this.activeIndex = 'Access'
    })
  }

  private closeAndLogin() {
    this.activeIndex = 'Access'
    this.showRegister = false
    this.showLogin = true
  }

  private closeAndCreate() {
    this.activeIndex = 'Register'
    this.showRegister = true
    this.showLogin = false
  }

  private loginItems: string[] = ['Access', 'Register']

  private actionsNavbar: string[] = [
    'Join IFC',
    'Split IFC',
    'Get Attributes',
    'Relocate IFC'
  ]

  private handleMenu(action: string) {
    if (
      action === 'Join IFC' ||
      action === 'Split IFC' ||
      action === 'Get Attributes'
    ) {
      Store.setActiveAction(action)
      this.$root.$emit('action-navbar', action)
    }
    this.showMobileMenu = false
  }

  private handlePopup(item: string) {
    this.activeIndex = item

    if (this.activeIndex === 'Access') {
      this.showLogin = true
      this.showRegister = false
      this.showMobileMenu = false
    } else {
      this.showRegister = true
      this.showLogin = false
      this.showMobileMenu = false
    }
  }

  private handleAvatar() {
    this.showModalUser = !this.showModalUser
    this.showMobileMenu = false
  }

  private get userId(): string {
    return Store.userId
  }

  private handleRedirection() {
    if (this.$route.name !== 'Home') {
      this.$router.push({ name: 'Home' })
    }
  }

  @Watch('credits')
  onCreditsChange() {
    this.pingClass = true

    setTimeout(() => {
      this.pingClass = false
    }, 1000)
  }
}
