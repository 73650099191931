import Store from '@/store/modules/Viewer'
import ifcProducts from '@/config/IFC/ifcProducts'
import formatSplitIfcFile from '@/controllers/splitModel/formatSplitIfcFile'
import checkReferencedIfcProducts from '@/controllers/splitModel/checkReferencedIfcProducts'

export default (body: string, headers: string) => {
  let counter = 0

  let lines: any = {}

  let isReferencing: boolean = true
  let isParamming: boolean = false
  let isMethoding: boolean = false

  let reference = ''
  let param = ''
  let method = ''

  while (counter < body.length) {
    const char = body.charAt(counter)

    if (isReferencing && char !== '=' && char !== '\n' && char !== ' ') {
      reference += char
    } else if (isReferencing && char === '=') {
      lines[reference] = { reference }
      isReferencing = false
      isMethoding = true
    } else if (!isReferencing && isMethoding && char !== '(' && char !== ' ') {
      method += char
    } else if (isMethoding && char === '(') {
      isMethoding = false
    } else if (!isReferencing && char === '#') {
      isParamming = true
      param = char
    } else if (isParamming && char !== '#' && char !== ',' && char !== ')') {
      param += char
    } else if (isParamming && (char === ',' || char === ')')) {
      if (lines[reference].params === undefined) {
        lines[reference].params = [param]
      } else {
        lines[reference].params.push(param)
      }

      param = ''
      isParamming = false
    } else if (char === ';') {
      lines[reference] = {
        method,
        ifcProducts: [],
        sender: []
      }

      isReferencing = true
      reference = ''
      method = ''
    }

    counter++
  }

  for (const key in lines) {
    const method = lines[key].method

    if (ifcProducts.includes(method)) {
      const { params } = lines[key]

      if (params) {
        for (const param of params) {
          const parMethod = lines[param].method

          if (!ifcProducts.includes(parMethod)) {
            const item = lines[param]

            if (item.ifcProducts.length) {
              if (!item.ifcProducts.includes(method)) {
                item.ifcProducts.push(method)
              }
            } else {
              item.ifcProducts = [method]
            }

            if (item.sender.length) {
              if (!item.sender.includes(key)) {
                item.sender.push(key)
              }
            } else {
              item.sender = [key]
            }
          }
        }
      }
    }
  }

  let hasChanged = true

  do {
    hasChanged = checkReferencedIfcProducts(lines)
  } while (hasChanged)

  const toDeleteRefs = []

  for (const key in lines) {
    const item = lines[key]
    const { method } = item

    if (
      ifcProducts.includes(method) &&
      !Store.selectedIfcClasses.includes(method)
    ) {
      toDeleteRefs.push(key)
    } else if (item.ifcProducts && item.ifcProducts.length) {
      const hasNoSelectedClasses = Store.selectedIfcClasses.every(
        c => !item.ifcProducts.includes(c)
      )

      if (hasNoSelectedClasses) {
        toDeleteRefs.push(key)
      }
    }
  }

  counter = 0

  let mirror = ''

  let allow = false

  isReferencing = true

  reference = ''

  while (counter < body.length) {
    const char = body.charAt(counter)

    if (isReferencing && char !== '=' && char !== ' ' && char !== '\n') {
      reference += char
    } else if (isReferencing && char === '=') {
      isReferencing = false

      if (!toDeleteRefs.includes(reference)) {
        allow = true
        mirror += `${reference}=`
      }
    } else if (allow && char !== ';') {
      mirror += char
    } else if (allow && char === ';') {
      mirror += ';\n'
      allow = false
      isReferencing = true
      reference = ''
    } else if (!allow && char === ';') {
      allow = false
      isReferencing = true
      reference = ''
    }

    counter++
  }

  return formatSplitIfcFile(headers, mirror)
}
