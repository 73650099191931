
import { Component, Emit, Vue } from 'vue-property-decorator'
import IconGoogle from '@/assets/icons/google.svg'
import IconFacebook from '@/assets/icons/facebook.svg'
import IconMail from '@/assets/icons/mail.svg'
import IconUser from '@/assets/icons/user.svg'
import Input from '@/components/shared/Input.vue'
import { directive as onClickaway } from 'vue-clickaway'
import { onLogin } from '@/vue-apollo'
import Store from '@/store/modules/Viewer'
import jwtDecode from 'jwt-decode'
import ForgotPasswordCard from '@/components/Home/ForgotPasswordCard.vue'
import { User } from '@/models'
import isValidEmail from '@/helpers/isValidEmail'

@Component({
  components: {
    ForgotPasswordCard,
    IconGoogle,
    IconFacebook,
    Input,
    IconMail,
    IconUser
  },
  directives: { onClickaway }
})
export default class RegisterCard extends Vue {
  private tooltip: string = 'Coming soon'
  private showForgotPassword: boolean = false
  private errorLogin: boolean = false

  private user: User = {
    email: '',
    password: ''
  }

  mounted() {
    const input = document.getElementById('email')
    if (input) input.focus()
  }

  private async loginUser() {
    this.errorLogin = false

    try {
      const user = this.user

      const {
        data: { login }
      } = await this.$apollo.mutate({
        variables: { user },
        mutation: require('../../graphql/mutations/login.graphql')
      })

      if (login) {
        await onLogin(this.$apollo.getClient(), login)
        Store.setUser(jwtDecode(login))
        this.$toasted.success('Login succesfully')
        this.close()
      } else {
        this.$toasted.error('Error during login process')
        this.errorLogin = true
        this.user.password = ''
        ;(document.getElementById('password') as HTMLInputElement).focus()
      }
    } catch {
      this.$toasted.error('Error during login process')
      this.errorLogin = true
    }
  }

  private get isDisabled(): boolean {
    return (
      this.user.email === '' ||
      !isValidEmail(this.user.email) ||
      !this.user.password.length
    )
  }

  private createAccount() {
    this.$root.$emit('show-login')
    this.closeAndCreate()
  }

  private completeProcess() {
    this.$toasted.success('Password reset properly')
    this.showForgotPassword = false
    setTimeout(() => {
      ;(document.getElementById('email') as HTMLElement).focus()
    }, 500)
  }

  @Emit() close() {}
  @Emit() closeAndCreate() {}
}
