export default [
  'IFCACTUATOR',
  'IFCAIRTERMINAL',
  'IFCAIRTERMINALBOX',
  'IFCAIRTOAIRHEATRECOVERY',
  'IFCALARM',
  'IFCALIGNMENT',
  'IFCALIGNMENTCANT',
  'IFCALIGNMENTHORIZONTAL',
  'IFCALIGNMENTSEGMENT',
  'IFCALIGNMENTVERTICAL',
  'IFCAUDIOVISUALAPPLIANCE',
  'IFCBEAM',
  'IFCBEARING',
  'IFCBOILER',
  'IFCBOREHOLE',
  'IFCBRIDGE',
  'IFCBRIDGEPART',
  'IFCBUILDINGELEMENTPART',
  'IFCBUILDINGELEMENTPROXY',
  'IFCBUILTELEMENT',
  'IFCBURNER',
  'IFCCABLECARRIERFITTING',
  'IFCCABLECARRIERSEGMENT',
  'IFCCABLEFITTING',
  'IFCCABLESEGMENT',
  'IFCCAISSONFOUNDATION',
  'IFCCHILLER',
  'IFCCHIMNEY',
  'IFCCIVILELEMENT',
  'IFCCOIL',
  'IFCCOLUMN',
  'IFCCOMMUNICATIONSAPPLIANCE',
  'IFCCOMPRESSOR',
  'IFCCONDENSER',
  'IFCCONTROLLER',
  'IFCCONVEYORSEGMENT',
  'IFCCOOLEDBEAM',
  'IFCCOOLINGTOWER',
  'IFCCOURSE',
  'IFCCOVERING',
  'IFCCURTAINWALL',
  'IFCDAMPER',
  'IFCDEEPFOUNDATION',
  'IFCDISCRETEACCESSORY',
  'IFCDISTRIBUTIONBOARD',
  'IFCDISTRIBUTIONCHAMBERELEMENT',
  'IFCDISTRIBUTIONCONTROLELEMENT',
  'IFCDISTRIBUTIONELEMENT',
  'IFCDISTRIBUTIONFLOWELEMENT',
  'IFCDISTRIBUTIONPORT',
  'IFCDOOR',
  'IFCDUCTFITTING',
  'IFCDUCTSEGMENT',
  'IFCDUCTSILENCER',
  'IFCEARTHWORKSCUT',
  'IFCEARTHWORKSELEMENT',
  'IFCEARTHWORKSFILL',
  'IFCELECTRICAPPLIANCE',
  'IFCELECTRICDISTRIBUTIONBOARD',
  'IFCELECTRICFLOWSTORAGEDEVICE',
  'IFCELECTRICFLOWTREATMENTDEVICE',
  'IFCELECTRICGENERATOR',
  'IFCELECTRICMOTOR',
  'IFCELECTRICTIMECONTROL',
  'IFCELEMENT',
  'IFCELEMENTASSEMBLY',
  'IFCELEMENTCOMPONENT',
  'IFCENERGYCONVERSIONDEVICE',
  'IFCENGINE',
  'IFCEVAPORATIVECOOLER',
  'IFCEVAPORATOR',
  'IFCEXTERNALSPATIALELEMENT',
  'IFCEXTERNALSPATIALSTRUCTUREELEMENT',
  'IFCFACILITY',
  'IFCFACILITYPART',
  'IFCFACILITYPARTCOMMON',
  'IFCFAN',
  'IFCFASTENER',
  'IFCFEATUREELEMENT',
  'IFCFEATUREELEMENTADDITION',
  'IFCFEATUREELEMENTSUBTRACTION',
  'IFCFILTER',
  'IFCFIRESUPPRESSIONTERMINAL',
  'IFCFLOWCONTROLLER',
  'IFCFLOWFITTING',
  'IFCFLOWINSTRUMENT',
  'IFCFLOWMETER',
  'IFCFLOWMOVINGDEVICE',
  'IFCFLOWSEGMENT',
  'IFCFLOWSTORAGEDEVICE',
  'IFCFLOWTERMINAL',
  'IFCFLOWTREATMENTDEVICE',
  'IFCFOOTING',
  'IFCFURNISHINGELEMENT',
  'IFCFURNITURE',
  'IFCGEOGRAPHICELEMENT',
  'IFCGEOMODEL',
  'IFCGEOSLICE',
  'IFCGEOTECHNICALASSEMBLY',
  'IFCGEOTECHNICALELEMENT',
  'IFCGEOTECHNICALSTRATUM',
  'IFCGRID',
  'IFCHEATEXCHANGER',
  'IFCHUMIDIFIER',
  'IFCIMPACTPROTECTIONDEVICE',
  'IFCINTERCEPTOR',
  'IFCJUNCTIONBOX',
  'IFCKERB',
  'IFCLAMP',
  'IFCLIGHTFIXTURE',
  'IFCLINEARELEMENT',
  'IFCLINEARPOSITIONINGELEMENT',
  'IFCLIQUIDTERMINAL',
  'IFCMARINEFACILITY',
  'IFCMARINEPART',
  'IFCMECHANICALFASTENER',
  'IFCMEDICALDEVICE',
  'IFCMEMBER',
  'IFCMOBILETELECOMMUNICATIONSAPPLIANCE',
  'IFCMOORINGDEVICE',
  'IFCMOTORCONNECTION',
  'IFCNAVIGATIONELEMENT',
  'IFCOPENINGELEMENT',
  'IFCOUTLET',
  'IFCPAVEMENT',
  'IFCPILE',
  'IFCPIPEFITTING',
  'IFCPIPESEGMENT',
  'IFCPLATE',
  'IFCPORT',
  'IFCPOSITIONINGELEMENT',
  'IFCPROJECTIONELEMENT',
  'IFCPROTECTIVEDEVICE',
  'IFCPROTECTIVEDEVICETRIPPINGUNIT',
  'IFCPUMP',
  'IFCRAIL',
  'IFCRAILING',
  'IFCRAILWAY',
  'IFCRAILWAYPART',
  'IFCRAMP',
  'IFCRAMPFLIGHT',
  'IFCREFERENT',
  'IFCREINFORCEDSOIL',
  'IFCREINFORCINGBAR',
  'IFCREINFORCINGELEMENT',
  'IFCREINFORCINGMESH',
  'IFCROAD',
  'IFCROADPART',
  'IFCROOF',
  'IFCSANITARYTERMINAL',
  'IFCSENSOR',
  'IFCSHADINGDEVICE',
  'IFCSIGN',
  'IFCSIGNAL',
  'IFCSLAB',
  'IFCSOLARDEVICE',
  // 'IFCSPACE',
  'IFCSPACEHEATER',
  'IFCSPATIALELEMENT',
  'IFCSPATIALSTRUCTUREELEMENT',
  'IFCSPATIALZONE',
  'IFCSTACKTERMINAL',
  'IFCSTAIR',
  'IFCSTAIRFLIGHT',
  'IFCSTRUCTURALACTION',
  'IFCSTRUCTURALACTIVITY',
  'IFCSTRUCTURALCONNECTION',
  'IFCSTRUCTURALCURVEACTION',
  'IFCSTRUCTURALCURVECONNECTION',
  'IFCSTRUCTURALCURVEMEMBER',
  'IFCSTRUCTURALCURVEMEMBERVARYING',
  'IFCSTRUCTURALCURVEREACTION',
  'IFCSTRUCTURALITEM',
  'IFCSTRUCTURALLINEARACTION',
  'IFCSTRUCTURALMEMBER',
  'IFCSTRUCTURALPLANARACTION',
  'IFCSTRUCTURALPOINTACTION',
  'IFCSTRUCTURALPOINTCONNECTION',
  'IFCSTRUCTURALPOINTREACTION',
  'IFCSTRUCTURALREACTION',
  'IFCSTRUCTURALSURFACEACTION',
  'IFCSTRUCTURALSURFACECONNECTION',
  'IFCSTRUCTURALSURFACEMEMBER',
  'IFCSTRUCTURALSURFACEMEMBERVARYING',
  'IFCSTRUCTURALSURFACEREACTION',
  'IFCSURFACEFEATURE',
  'IFCSWITCHINGDEVICE',
  'IFCSYSTEMFURNITUREELEMENT',
  'IFCTANK',
  'IFCTENDON',
  'IFCTENDONANCHOR',
  'IFCTENDONCONDUIT',
  'IFCTRACKELEMENT',
  'IFCTRANSFORMER',
  'IFCTRANSPORTELEMENT',
  'IFCTRANSPORTATIONDEVICE',
  'IFCTUBEBUNDLE',
  'IFCUNITARYCONTROLELEMENT',
  'IFCUNITARYEQUIPMENT',
  'IFCVALVE',
  'IFCVEHICLE',
  'IFCVIBRATIONDAMPER',
  'IFCVIBRATIONISOLATOR',
  'IFCVIRTUALELEMENT',
  'IFCVOIDINGFEATURE',
  'IFCWALL',
  'IFCWALLSTANDARDCASE',
  'IFCWASTETERMINAL',
  'IFCWINDOW'
]
