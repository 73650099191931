export default (fileText: any) => {
  const contents = fileText.split('DATA;')
  let lines = contents[1].split(';')

  let newLines: any[] = []
  let ref = -1

  for (let line of lines) {
    line = line.replaceAll(' ', '').replaceAll('\r', '').replaceAll('\n', '')

    if (
      line.charAt(0) != '#' &&
      !line.includes('ENDSEC') &&
      !line.includes('END-ISO-10303-21')
    ) {
      newLines[ref] = newLines[ref] + ';' + line
    } else {
      newLines.push(line)
      ref++
    }
  }

  lines = newLines

  for (let i = 0; i < lines.length; i++) {
    const index = lines[i].indexOf('=')
    const id = lines[i].substr(0, index)
    const content = lines[i].substr(index + 1)

    let idClean = id.replaceAll('#', '')
    idClean = idClean.replaceAll('\r', '')
    idClean = idClean.replaceAll('\n', '')

    let dataList = [
      idClean,
      content.substr(0, content.indexOf('(')),
      ...content.substr(content.indexOf('(')).split(',')
    ]

    lines[i] = dataList
  }

  let maxNum = 0

  for (let i = 0; i < lines.length; i++) {
    let id = parseInt(lines[i][0])

    if (maxNum < id) {
      maxNum = id
    }
  }

  maxNum += 5

  return [lines, maxNum]
}
