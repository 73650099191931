
import { Vue, Component, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Viewer'
import Slider from '@/components/shared/Slider.vue'
import IconLoader from '@/assets/icons/loader.svg'
import IconSave from '@/assets/icons/save.svg'
import { directive as onClickaway } from 'vue-clickaway'
import expendOneCredit from '@/helpers/expendOneCredit'
import { Stats } from '@/models'
// @ts-ignore
import badWords from 'bad-words'
import { onLogout } from '@/vue-apollo'
import IconDownload from '@/assets/icons/file-download.svg'

const bw = new badWords()

@Component({
  directives: { onClickaway },
  components: { IconLoader, IconSave, Slider, IconDownload }
})
export default class SnapshotPopup extends Vue {
  private valueInput: string = ''
  private uploadedLinkTo: string = ''
  private linkFetch: string = ''
  private showImageDetail: boolean = false
  private firstClick: boolean = false
  private maxStats = [150, 35, 1]
  private steps = [10, 1, 0.05]
  private resultImages: string[] = []
  private scaledImages: string[] = []
  private fetchAttempts: number = 0
  private loadingText: string = 'Loading...'
  private isTimered: boolean = false
  private showPopup: boolean = false
  private badWordsCounter: number = 0
  private promptSent: string = ''
  private iaImage: any = null

  private stats: Stats = {
    steps: '20',
    guidance: '7',
    strength: '0.7'
  }

  private config: any = {
    prompt: '',
    stats: []
  }

  private toolTips = [
    'Enter the calculation of steps in the change',
    'Enter the degree of fidelity',
    'Enter the degree of change',
    'Enter a grade of seeds'
  ]

  private get selectedImage(): string {
    return Store.selectedImage
  }

  mounted() {
    this.$root.$on('link-processing-ai', (link: string) => {
      if (link !== '') {
        this.uploadedLinkTo = link
        this.loadingText = 'Processing IA core...'
        this.continueProcessing()
      }
    })

    this.$root.$on('link-reset', (link: string) => {
      this.uploadedLinkTo = link
    })

    this.$root.$on('fetch-result', (payload: any) => {
      const { link } = payload
      this.linkFetch = link
    })

    this.$root.$on('result-images', async (images: string[]) => {
      // this.resultImages = images

      this.scaledImages = images
      await expendOneCredit(7, false, this.promptSent)
    })

    this.$root.$on('restore-ia-submit', () => {
      this.firstClick = false
      this.uploadedLinkTo = ''
    })

    this.$root.$on('show-popup', (status: boolean) => {
      this.showPopup = status
    })

    this.$root.$on('set-ia-image', (image: any) => {
      this.iaImage = image
    })

    this.$root.$on('set-result-base64', (base64Image: string[]) => {
      base64Image.forEach(b => {
        this.scaledImages.push(`data:image/png;base64,${b}`)
      })

      expendOneCredit(7, false, this.promptSent)
      this.firstClick = false
    })
  }

  private async continueProcessing() {
    this.$root.$emit('loading-status', true)

    this.config = {
      prompt: this.valueInput,
      ...this.stats
    }

    try {
      const {
        data: { continueProcessingIa }
      } = await this.$apollo.mutate({
        variables: { link: this.uploadedLinkTo, config: this.config },
        mutation: require('@/graphql/mutations/continue-processing-ia.graphql')
      })

      const result = JSON.parse(continueProcessingIa)
      if (result.success !== undefined) {
        this.resultImages = result.success

        // await expendOneCredit(7, false)
      } else if (result.error !== undefined) {
        this.$toasted.error(
          'Problem finishing processing IA render. Try again in a while'
        )

        this.firstClick = false
        this.loadingText = 'Loading...'
        this.uploadedLinkTo = ''
      } else if (result.fetch_result !== undefined) {
        this.linkFetch = ''
        this.linkFetch = result.fetch_result

        setTimeout(async () => {
          this.loadingText = 'Fetch processing...'
          this.fetchAttempts++

          if (this.fetchAttempts < 3) {
            await this.fetchProcessing()
          }
        }, 30 * 1000)
      }
    } catch {
      this.linkFetch = ''
      this.firstClick = false
      this.loadingText = 'Loading...'
    } finally {
      this.$root.$emit('loading-status', false)
      this.loadingText = 'Loading...'
    }
  }

  private async fetchProcessing() {
    if (this.fetchAttempts <= 10) {
      try {
        const {
          data: { fetchProcessing }
        } = await this.$apollo.mutate({
          variables: { link: this.linkFetch },
          mutation: require('@/graphql/mutations/fetch-processing.graphql')
        })
        const result = JSON.parse(fetchProcessing)

        if (result.success !== undefined) {
          this.resultImages = result.success
          this.fetchAttempts = 0
          // this.uploadedLinkTo = ''
        } else if (result.error !== undefined) {
          this.$toasted.error('Error processing IA render. Resource not found')
          this.firstClick = false
          this.linkFetch = ''
          this.loadingText = 'Loading...'
        } else if (result.processing !== undefined) {
          this.$toasted.success(
            'Still processing IA render. Please try again in a while'
          )
          const previous = this.linkFetch
          this.linkFetch = ''
          this.linkFetch = previous
          this.fetchAttempts++

          if (this.fetchAttempts <= 10) {
            setTimeout(async () => {
              await this.fetchProcessing()
            }, 45 * 1000)
          }
        }
      } catch {
        this.$toasted.error('There was some problems during IA render')
        this.firstClick = false
        this.loadingText = 'Loading...'
      } finally {
        this.loadingText = 'Loading...'
        this.$root.$emit('loading-status', false)
      }
    } else {
      this.$toasted.error('Servers busy')
      this.firstClick = false
      this.loadingText = ''
    }
  }

  private imageDetail(image: string) {
    this.showImageDetail = true
    Store.setSelectedImage(image)
  }

  private closeImageDetail() {
    this.showImageDetail = false
    Store.resetSelectedImage()
  }

  private getMax(index: string) {
    const pos = Object.keys(this.stats).findIndex(s => s === index)
    return this.maxStats[pos]
  }

  private getStep(index: string) {
    const pos = Object.keys(this.stats).findIndex(s => s === index)
    return this.steps[pos]
  }

  private getTooltip(index: string) {
    const pos = Object.keys(this.stats).findIndex(s => s === index)
    return this.toolTips[pos]
  }

  private async scaleImages() {
    this.scaledImages = this.resultImages

    await expendOneCredit(7, false, this.promptSent)

    this.loadingText = ''
    this.firstClick = false

    // console.log('⭐???⭐')
    // debugger

    // let counter: number = 1
    // let resultImages: any[] = []
    //
    // setTimeout(async () => {
    //   console.log('⭐this.resultImages⭐', this.resultImages)
    //
    //   for (const image of this.resultImages) {
    //     const {
    //       data: { scaleImage }
    //     } = await this.$apollo.query({
    //       variables: { image },
    //       query: require('@/graphql/queries/scale-image.graphql')
    //     })
    //
    //     const scale = JSON.parse(scaleImage)
    //
    //     if (scale.success !== undefined) {
    //       setTimeout(async () => {
    //         resultImages.push(scale.success)
    //         this.loadingText = `Adding images...${resultImages.length}/3`
    //
    //         if (resultImages.length) {
    //           this.scaledImages = resultImages
    //
    //           // this.saveResults()
    //
    //           this.firstClick = false
    //           this.loadingText = 'Loading...'
    //           this.$toasted.success(
    //             'The process has been completed successfully'
    //           )
    //           await expendOneCredit(7, false, this.promptSent)
    //         }
    //       }, 100)
    //     } else {
    //       this.reScaleImage(image)
    //       this.$toasted.error('An image has not been scaled')
    //       this.loadingText = 'Loading...'
    //       this.firstClick = false
    //     }
    //
    //     counter++
    //   }
    // }, counter * 2 * 1000)
  }

  // private async reScaleImage(image: string) {
  //   const {
  //     data: { scaleImage }
  //   } = await this.$apollo.query({
  //     variables: { image },
  //     query: require('@/graphql/queries/scale-image.graphql')
  //   })
  //
  //   const scale = JSON.parse(scaleImage)
  //
  //   if (scale.success !== undefined) {
  //     this.scaledImages.push(scale.success)
  //   } else {
  //     this.$toasted.error(
  //       'Finally, this result will be at the original generated res'
  //     )
  //
  //     this.scaledImages.push(image)
  //   }
  //   //  else {
  //   //   this.reScaleImage(image)
  //   //   this.$toasted.error('RE-TRY-ELSE')
  //   // }
  // }

  // private async saveResults() {
  //   const results = this.scaledImages
  //
  //   const {
  //     data: { saveResults }
  //   } = await this.$apollo.query({
  //     variables: { results },
  //     query: require('@/graphql/queries/save-results.graphql')
  //   })
  // }

  private closeConfigAI() {
    this.showPopup = false
    this.$root.$emit('close-popup', false)
  }

  private async snapShotAI() {
    if (this.valueInput.length < 6) {
      this.$toasted.error('To continue, enter a Prompt')
    }

    this.firstClick = true
    this.promptSent = String(this.valueInput)

    this.$root.$emit(
      'set-store-snapshot',
      this.promptSent,
      parseFloat(this.stats.strength),
      parseInt(this.stats.steps),
      parseInt(this.stats.guidance)
    )
  }

  private async strikeUser() {
    await this.$apollo.mutate({
      mutation: require('@/graphql/mutations/strike-user.graphql')
    })
  }

  private downloadSelectedImage() {
    const img = document.getElementById('detail') as HTMLImageElement

    window.open(img.src, 'blank', 'popup')

    // const link = document.createElement('a')
    // link.download = 'I♥ifc_render-AI.png'
    // link.href = img.src

    // document.body.appendChild(link)

    // link.click()
  }

  @Watch('resultImages')
  onResultImagesChange() {
    this.scaleImages()
  }

  @Watch('linkFetch')
  onLinkFetchChange() {
    if (this.linkFetch) {
      this.isTimered = true

      setTimeout(() => {
        this.isTimered = false
      }, 5 * 1000)
    }
  }

  @Watch('uploadedLinkTo')
  onUploadedLinkToChange() {
    if (this.linkFetch) {
      this.isTimered = true

      setTimeout(() => {
        this.isTimered = false
      }, 5 * 1000)
    }
  }

  @Watch('valueInput')
  async onValueInputChange() {
    if (
      this.valueInput &&
      this.valueInput.charAt(this.valueInput.length - 1) === ' '
    ) {
      const words = this.valueInput.split(' ').map(w => w.toLowerCase())

      for (const word of words) {
        // @ts-ignore
        if (bw.list.map((l: string) => l.toLowerCase()).includes(word)) {
          this.$toasted.error(
            "Please, don't use explicit language or bad words"
          )

          this.badWordsCounter++

          words[words.length - 1] = ''
          words[words.length - 2] = '***'
          this.valueInput = words.join(' ')

          if (this.badWordsCounter >= 4) {
            this.$toasted.error(
              "You've been notified for your bad use so much times, so you'll receive a strike."
            )

            await this.strikeUser()

            Store.setUser({ id: '' })
            Store.setPresentDone(null)

            await onLogout(this.$apollo.getClient())

            setTimeout(() => {
              this.$router.go(0)
            }, 1000)
          }
        }
      }
    }
  }
}
