import { utils, write, writeFile } from 'sheetjs-style'

export default (dataForXlsx: any[], subsetBooksDataJoin: any) => {
  const book = utils.book_new()

  const sheet = utils.json_to_sheet(dataForXlsx, {
    cellStyles: true,
    skipHeader: true
  })

  utils.book_append_sheet(book, sheet, `Base`)

  for (const subsetBook of subsetBooksDataJoin) {
    const sheet = utils.json_to_sheet(subsetBook.rows)

    sheet['A1'].s = {
      fill: {
        bgColor: '#ea9999',
        fgColor: '#ea9999'
      },
      font: {
        color: '#fff'
      }
    }

    const title = formatTitle(subsetBook.title)
    utils.book_append_sheet(book, sheet, title)
  }

  write(book, {
    bookType: 'xlsx',
    type: 'buffer',
    cellStyles: true
  })

  // @ts-ignore
  const fileTitle = `I♥IFC_${''.replace('.ifc', '')}`

  writeFile(book, `${fileTitle}.xlsx`, { cellStyles: true })
}

const formatTitle = (title: string): string => {
  return title
    .replace('\\X2\\00E1\\X0\\', 'á')
    .replace('\\X2\\00E9\\X0\\', 'é')
    .replace('\\X2\\00ED\\X0\\', 'í')
    .replace('\\X2\\00F3\\X0\\', 'ó')
    .replace('\\X2\\00FA\\X0\\', 'ú')
    .replace(/\\/g, '_')
    .substr(0, 30)
}
