
import { Vue, Component } from 'vue-property-decorator'
import IconImage from '../../assets/icons/image.svg'
import IconCode from '../../assets/icons/code.svg'
import IconBox from '../../assets/icons/box.svg'
import IconSpeaker from '../../assets/icons/speaker.svg'

@Component({
  components: { IconImage, IconCode, IconBox, IconSpeaker }
})
export default class FreeCard extends Vue {}
