
import { Vue, Component, Emit } from 'vue-property-decorator'
import IconMail from '@/assets/icons/mail.svg'
import Input from '@/components/shared/Input.vue'
import isEmail from '@/helpers/isValidEmail'
import IconCode from '@/assets/icons/code.svg'

@Component({
  components: { Input, IconMail, IconCode }
})
export default class ForgotPasswordCard extends Vue {
  private email: string = ''
  private loading: boolean = false
  private step: number = 0
  private confirmationCode: string = ''
  private password: string = ''

  private instructions: string = 'Enter your email to recover your password'

  private instructionsCode: string =
    "Enter the code we've just send to your email"

  private instructionsPassword: string = 'Enter your new Password'

  private get isEmail(): boolean {
    return isEmail(this.email)
  }

  mounted() {
    ;(document.getElementById('email') as HTMLElement).focus()
  }

  private async recover() {
    try {
      this.loading = true
      const email = this.email

      const {
        data: { recoverPassword }
      } = await this.$apollo.mutate({
        variables: { email },
        mutation: require('@/graphql/mutations/recover-password.graphql')
      })

      if (recoverPassword) {
        this.$toasted.success('Check your email tray and insert the code')
        this.step++
        setTimeout(() => {
          ;(document.getElementById('code') as HTMLElement).focus()
        }, 500)
      }
    } catch (error) {
      console.error('error', /*error*/)
      this.$toasted.error('Error during email send')
    } finally {
      this.loading = false
    }
  }

  private async sendConfirmationCode() {
    try {
      this.loading = true
      const email = this.email
      const confirmationCode = this.confirmationCode

      const {
        data: { sendConfirmationCode }
      } = await this.$apollo.mutate({
        variables: { email, confirmationCode },
        mutation: require('@/graphql/mutations/send-confirmation-code.graphql')
      })

      if (sendConfirmationCode) {
        this.step++
        setTimeout(() => {
          ;(document.getElementById('password') as HTMLElement).focus()
        }, 500)
      } else if (sendConfirmationCode === false) {
        this.$toasted.error('Wrong confirmation code')
        this.confirmationCode = ''
      } else if (sendConfirmationCode === null) {
        this.$toasted.error(
          'You exceeded attempts amount. Please request again a code'
        )
        this.confirmationCode = ''
        this.step--
      }
    } catch (error) {
      console.error('error', /*error*/)
      this.$toasted.error('Error during confirmation code send')
    } finally {
      this.loading = false
    }
  }

  private async sendNewPassword() {
    try {
      this.loading = true
      const email = this.email
      const password = this.password

      const {
        data: { resetPassword }
      } = await this.$apollo.mutate({
        variables: { email, password },
        mutation: require('@/graphql/mutations/reset-password.graphql')
      })

      if (resetPassword) {
        this.step = 0
        this.completeProcess()
      }
    } catch (error) {
      console.error('error', /*error*/)
      this.$toasted.error('Error during new password send')
    } finally {
      this.loading = false
    }
  }

  @Emit() closeForgot() {}
  @Emit() completeProcess() {}
}
