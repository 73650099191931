import store from '../index'
import {
  getModule,
  Module,
  Mutation,
  VuexModule,
  Action
} from 'vuex-module-decorators'
import { CsvDownloadable } from '@/models'
import { ModelGrouped } from '@/types/interfaces'
import { Components } from 'openbim-components'

@Module({
  name: 'Viewer',
  namespaced: true,
  store,
  dynamic: true
})
class Viewer extends VuexModule {
  private _fileForCsvDownload: File | null = null
  private _csvProcessed: CsvDownloadable | null = null
  private _userPlan: 'free' | 'premium' = 'free'
  private _userId: string = ''
  private _fileForViewer: File | null | Blob = null
  private _flatStructureItems: any[] = []
  private _selectedItems: any[] = []
  private _spatialStructure: any = null
  private _activeAction: string = ''
  private _modelsList: ModelGrouped[] = []
  private _selectedExpressIDs: number[] = []
  private _selectedIfcClasses: string[] = []
  private _userLearned: boolean = false
  private _userCredits: number = 0
  private _presentDone: boolean | null = null
  private _avatar: string = ''
  private _properties: any = null
  private _counter: string = ''
  private _loader: string = ''
  private _totalToProcess: number = 0
  private _currentProgress: number = 0
  private _stopLoading: boolean = true
  private _stylePanel: boolean = false
  private _snapshot: any = ''
  private _selectedImage: string = ''
  private _username: string = ''
  private _firstLocation: boolean = true
  private _viewer!: Components
  private _fragments!: Components
  private _scene!: any
  private _highLightMat: any = null
  private _allTypes: { [p: number]: string } = {}
  private _edges = null
  private _culler = null

  @Mutation
  setFileForCsvDownload(fileForCsvDownload: any) {
    this._fileForCsvDownload = fileForCsvDownload
  }

  get fileForCsvDownload(): File | null {
    return this._fileForCsvDownload
  }

  @Mutation
  setCsvProcessed(csvProcessed: any) {
    this._csvProcessed = csvProcessed
  }

  get csvProcessed(): CsvDownloadable | null {
    return this._csvProcessed
  }

  get userPlan(): 'free' | 'premium' {
    return this._userPlan
  }

  @Mutation
  setUserPlan(userPlan: 'free' | 'premium') {
    this._userPlan = userPlan
  }

  @Mutation
  setUser({ id }: any) {
    this._userId = id
  }

  get userId(): string {
    return this._userId
  }

  @Mutation
  setFileForViewer(fileForViewer: File | Blob) {
    this._fileForViewer = fileForViewer
  }

  @Mutation
  removeFileForViewer() {
    this._fileForViewer = null
  }

  get fileForViewer(): File | null | Blob {
    return this._fileForViewer
  }

  @Mutation
  setFlatStructureItems(flatStructureItems: any[]) {
    this._flatStructureItems = flatStructureItems
  }

  @Mutation
  removeFlatStructureItem(id: any) {
    const item = this._flatStructureItems.find(it => it.expressID === id)
    this._flatStructureItems = this._flatStructureItems.filter(s => s !== item)
  }

  public get flatStructureItems(): any[] {
    return this._flatStructureItems
  }

  @Mutation
  resetSelectedItems() {
    this._selectedItems = []
  }

  public get selectedItems() {
    return this._selectedItems
  }

  @Mutation
  setSpatialStructure(spatialStructure: any) {
    this._spatialStructure = spatialStructure
  }

  public get spatialStructure() {
    return this._spatialStructure
  }

  @Mutation
  setActiveAction(action: string) {
    this._activeAction = action
  }

  public get activeAction(): string {
    return this._activeAction
  }

  @Mutation
  addModelToList(model: ModelGrouped) {
    this._modelsList.push(model)
  }

  @Mutation
  removeModelFromList(index: number) {
    this._modelsList = this._modelsList.filter((model, pos) => {
      if (index !== pos) return model
    })
  }

  public get modelsList(): ModelGrouped[] {
    return this._modelsList
  }

  @Mutation
  addSelectedExpressID(expressID: number) {
    this._selectedExpressIDs.push(expressID)
  }

  @Mutation
  addSelectedExpressIDs(expressIDs: any[]) {
    this._selectedExpressIDs.push(...expressIDs)
  }

  @Mutation
  removeSelectedExpressID(expressID: number) {
    this._selectedExpressIDs = this._selectedExpressIDs.filter(id => {
      if (id !== expressID) return id
    })
  }

  @Mutation
  removeSelectedExpressIDs(expressIds: number[]) {
    this._selectedExpressIDs = this._selectedExpressIDs.filter(
      s => !expressIds.includes(s)
    )
  }

  @Mutation
  resetSelectedExpressID() {
    this._selectedExpressIDs = []
  }

  public get selectedExpressIDs(): any[] {
    return this._selectedExpressIDs
  }

  @Mutation
  addSelectedIfcClass(ifcClass: string) {
    this._selectedIfcClasses.push(ifcClass)
  }

  @Mutation
  removeSelectedIfcClass(ifcClass: any) {
    this._selectedIfcClasses = this._selectedIfcClasses.filter(
      c => c !== ifcClass
    )
  }

  @Mutation
  resetSelectedIfcClass() {
    this._selectedIfcClasses = []
  }

  public get selectedIfcClasses(): string[] {
    return this._selectedIfcClasses
  }

  @Mutation
  setUserLearned(userLearned: boolean) {
    this._userLearned = userLearned
  }

  public get userLearned(): boolean {
    return this._userLearned
  }

  @Mutation
  setUserCredits(userCredits: any) {
    this._userCredits = userCredits
  }

  public get userCredits(): number {
    return this._userCredits
  }

  @Action
  setUserCreditsAction(userCredits: number) {
    this.context.commit('setUserCredits', userCredits)
  }

  @Mutation
  setPresentDone(presentDone: any) {
    this._presentDone = presentDone
  }

  public get presentDone(): boolean | null {
    return this._presentDone
  }

  @Mutation
  setAvatar(avatar: any) {
    this._avatar = avatar
  }

  public get avatar(): string {
    return this._avatar
  }

  @Mutation
  setProperties(properties: any) {
    this._properties = properties
  }

  public get properties(): any | null {
    return this._properties
  }

  @Action
  setCounterAction(counter: number) {
    this.context.commit('setCounter', counter)
  }

  @Mutation
  setCounter(counter: string) {
    this._counter = counter
  }

  public get counter(): string {
    return this._counter
  }

  @Mutation
  setLoader(loader: string) {
    this._loader = loader
  }

  public get loader(): string {
    return this._loader
  }

  @Mutation
  setTotalToProcess(totalToProcess: number) {
    this._totalToProcess = totalToProcess
  }

  public get totalToProcess(): number {
    return this._totalToProcess
  }

  @Mutation
  setCurrentProgress(currentProgress: number) {
    this._currentProgress = currentProgress
  }

  public get currentProgress(): number {
    return this._currentProgress
  }

  @Mutation
  setStopLoading(stopLoading: boolean) {
    this._stopLoading = stopLoading
  }

  public get stopLoading(): boolean {
    return this._stopLoading
  }

  @Mutation
  setStylePanel(stylePanel: boolean) {
    this._stylePanel = stylePanel
  }

  public get stylePanel(): boolean {
    return this._stylePanel
  }

  @Mutation
  setSnapshot(snapshot: string) {
    this._snapshot = snapshot
  }

  public get snapshot(): string {
    return this._snapshot
  }

  @Mutation
  setSelectedImage(image: string) {
    this._selectedImage = image
  }

  public get selectedImage(): string {
    return this._selectedImage
  }

  @Mutation
  resetSelectedImage() {
    this._selectedImage = ''
  }

  @Mutation
  setUsername(username: string) {
    this._username = username
  }

  public get username(): string {
    return this._username
  }

  @Mutation
  setFirstLocation(status: boolean) {
    this._firstLocation = status
  }

  public get firstLocation(): boolean {
    return this._firstLocation
  }

  @Mutation
  setViewer(viewer: Components) {
    this._viewer = viewer
  }

  public get viewer(): Components {
    return this._viewer
  }

  @Mutation
  setFragments(fragments: Components) {
    this._fragments = fragments
  }

  public get fragments(): Components {
    return this._fragments
  }

  @Mutation
  setScene(scene: any) {
    this._scene = scene
  }

  public get scene(): any {
    return this._scene
  }

  @Mutation
  setHighLightMat(highLightMat: any) {
    this._highLightMat = highLightMat
  }

  public get highLightMat(): any {
    return this._highLightMat
  }

  @Mutation
  setAllTypes(allTypes: { [p: number]: string }) {
    this._allTypes = allTypes
  }

  public get allTypes(): { [p: number]: string } {
    return this._allTypes
  }

  @Mutation
  setEdges(edges: any) {
    this._edges = edges
  }

  public get edges(): any {
    return this._edges
  }

  @Mutation
  setCuller(culler: any) {
    this._culler = culler
  }

  public get culler(): any {
    return this._culler
  }
}

export default getModule(Viewer)
