import Store from '@/store/modules/Viewer'

export default (): boolean => {
  const typesSet: Set<string> = new Set()

  for (const selection of Store.selectedExpressIDs) {
    typesSet.add(Store.properties[selection]['type'])
  }

  return Array.from(typesSet).length <= 1
}
