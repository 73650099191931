import { FragmentsGroup } from 'bim-fragment'
import Store from '@/store/modules/Viewer'
import {
  Components,
  FragmentClassifier,
  FragmentManager,
  FragmentTree
} from 'openbim-components'

export default (
  viewer: Components,
  fragments: FragmentManager,
  model: FragmentsGroup
) => {
  const classifier = new FragmentClassifier(fragments)

  classifier.byStorey(model)
  classifier.byEntity(model)

  const tree = new FragmentTree(viewer, classifier)

  tree.update(['storeys', 'entities'])

  let spatialStructure: any = {}

  // @ts-ignore
  const { storeys, entities } = tree._classifier._groupSystems

  for (const storey in storeys) {
    spatialStructure = { ...spatialStructure, [storey]: {} }

    for (const s in storeys[storey]) {
      for (const expressId of Array.from(storeys[storey][s])) {
        for (const e in entities) {
          if (Object.keys(entities[e]).includes(s)) {
            if (spatialStructure[storey][e] !== undefined) {
              spatialStructure[storey][e].push(expressId)
            } else {
              spatialStructure[storey] = {
                ...spatialStructure[storey],
                [e]: [expressId]
              }
            }
          }
        }
      }
    }
  }

  Store.setSpatialStructure(spatialStructure)
}
