
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import MapboxSearchTool from '@/components/ActionsViewer/MapBox/MapboxSearchTool.vue'
import Store from '@/store/modules/Viewer'

@Component({
  components: { MapboxSearchTool }
})
export default class AddressesPopup extends Vue {
  @Prop({ default: [] }) addresses!: string[]
  @Prop() map!: any

  private pickedAddress: string = ''

  private get firstLocation() {
    return Store.firstLocation
  }

  @Emit() close() {}
}
