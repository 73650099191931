import initViewer from './initViewer'
import setScene from './setScene'
import {
  CubeMap,
  ScreenCuller,
  FragmentIfcLoader,
  FragmentManager
} from 'openbim-components'

/** Returns instances of viewer, which is mounted at DOM and
 * fragments, necessary to render and parse the .ifc model */
export default async () => {
  const wrapper = document.getElementById('viewer') as HTMLElement
  const { viewer, renderer } = initViewer(wrapper)
  const fragments = new FragmentManager(viewer)

  const fragmentIfcLoader = new FragmentIfcLoader(viewer, fragments)

  const cubeMap = new CubeMap(viewer)
  cubeMap.setPosition('top-right')
  viewer.tools.add('navCube', cubeMap)

  fragmentIfcLoader.settings.optionalCategories = []

  fragmentIfcLoader.settings.webIfc = {
    COORDINATE_TO_ORIGIN: true,
    USE_FAST_BOOLS: true,
    // @ts-ignore
    OPTIMIZE_PROFILES: true
  }

  const scene = await setScene(viewer)

  const culler = new ScreenCuller(viewer)

  window.onmouseup = () => (culler.needsUpdate = true)
  window.onwheel = () => (culler.needsUpdate = true)

  return {
    viewer,
    fragments,
    scene,
    renderer,
    fragmentIfcLoader,
    culler
  }
}
