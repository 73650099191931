
import { Vue, Component, Emit } from 'vue-property-decorator'
import IconGoogle from '@/assets/icons/google.svg'
import IconFacebook from '@/assets/icons/facebook.svg'
import IconMail from '@/assets/icons/mail.svg'
import IconUser from '@/assets/icons/user.svg'
import Input from '@/components/shared/Input.vue'
import { directive as onClickaway } from 'vue-clickaway'
import { UserIn } from '@/models'
import agreement from '@/config/agreement'
import registerUser from '@/helpers/registerUser'
import isValidEmail from '@/helpers/isValidEmail'
import isValidPassword from '@/helpers/isValidPassword'
import ReCaptcha from '@/components/Home/ReCaptcha.vue'

@Component({
  components: {
    IconGoogle,
    IconFacebook,
    Input,
    IconMail,
    IconUser,
    ReCaptcha
  },
  directives: { onClickaway }
})
export default class RegisterCard extends Vue {
  private user: UserIn = {
    email: '',
    password: ''
  }
  private errorRegister: boolean = false
  private condition: string = agreement
  private conditionPassword: string =
    'The password must contain 10 characters and at least uppercase, lowercase, number and a symbol'
  private tooltip: string = 'Coming soon'
  private checkRegister: boolean = false

  mounted() {
    const input = document.getElementById('email')
    if (input) input.focus()
  }

  private async registerUser() {
    this.errorRegister = false
    const user = this.user
    const register = await registerUser(user, this.$toasted)

    if (register && isValidEmail(user.email)) {
      this.checkRegister = true
    } else {
      this.errorRegister = false
      const input = document.getElementById('email') as HTMLInputElement
      input.focus()
      this.user.email = ''
      this.user.password = ''
    }
  }

  private get isValidPassword(): boolean {
    return isValidPassword(this.user.password)
  }

  private get allowSubmit(): boolean {
    return isValidEmail(this.user.email) && isValidPassword(this.user.password)
  }

  private loginAccount() {
    this.$root.$emit('show-login')
    this.closeAndLogin()
  }

  private get errorPassword(): boolean {
    return !this.isValidPassword && this.user.password !== ''
  }

  @Emit() close() {
    this.checkRegister = false
  }

  @Emit() closeAndLogin() {}
}
