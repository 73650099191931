import Store from '@/store/modules/Viewer'
import splitByIfcClass from '@/controllers/splitModel/splitByIfcClass'
import splitBySelectedItems from '@/controllers/splitModel/splitBySelectedItems'

export default (fileText: string) => {
  try {
    const parts = fileText.split('DATA;')

    const headers: string = parts[0]
    const body = parts[1]

    if (Store.selectedIfcClasses.length) {
      return splitByIfcClass(body, headers)
    } else if (Store.selectedExpressIDs) {
      return splitBySelectedItems(
        body.split(';').map(l => {
          return l.replaceAll('\n', '').replaceAll('\r', '')
        }),
        headers
      )
    }
  } catch (error) {
    console.error('error', /*error*/)
  }
}
