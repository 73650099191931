
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class LegalCard extends Vue {
  @Prop() title!: string
  @Prop() description!: string

  private get descriptionText(): string {
    return `${this.description.slice(0, this.description.indexOf(' ', 90))}...`
  }

  @Emit() learnMore() {}
}
