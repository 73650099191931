
import { Vue, Component } from 'vue-property-decorator'
import IconILove from '@/assets/icons/icon-ilove.svg'

@Component({
  components: { IconILove }
})
export default class Footer extends Vue {
  private showDataPrivacyModal: boolean = false

  private texts = {
    a: "<span>I❤️IFC is a visualization and analysis tool for building information modeling (BIM) data that allows users to explore and review IFC (Industry Foundation Classes) model data <strong>without having to load files onto an external server</strong>. Instead, I❤️IFC utilizes the capability of modern web browsers to process and visualize IFC models directly on the client's computer, without the need to send files to a remote server.</span>",
    b: "<span>This means that <strong>the client's IFC files never leave their computers</strong> and are not stored on any external server. The model data is loaded and processed in the client's browser memory, <strong>ensuring the privacy and security of the model data</strong>. Additionally, I❤️IFC only collects minimal user data, such as the user's name and email address, which are used solely for authentication and communication purposes.\n</span>",
    c: "<span>Furthermore, I❤️IFC <strong>does not require users to install any specialized software or browser add-ons</strong> to work with IFC models. In summary, I❤️IFC is a BIM data visualization and analysis tool that utilizes client data <strong>without loading files onto an external server</strong>, and the client's IFC files never leave their computer, ensuring the privacy and security of the model data, while collecting only minimal user data for authentication and communication purposes.\n</span>"
  }

  private legalRedirect() {
    this.$router.push({ name: 'Legal' })
  }

  private aboutRedirect() {
    this.$router.push({ name: 'About' })
  }
}
