import Store from '@/store/modules/Viewer'

export default (): boolean => {
  if (Store.userCredits > 0) {
    return (
      Store.selectedExpressIDs.length > 0 ||
      Store.selectedIfcClasses.length > 0
    )
  } else {
    return (
      Store.selectedExpressIDs.length > 0 ||
      Store.selectedIfcClasses.length === 1
    )
  }
}
