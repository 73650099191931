export default (fileStr: string): string => {
  const fileArr = fileStr.split(';')

  let defFileText = ''

  for (const line of fileArr) {
    if (!line.startsWith('#=')) {
      defFileText += line
    }
  }

  return defFileText
}
