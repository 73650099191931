import setFlatStructure from '@/controllers/flatStructure/setFlatStructure'
import { Components } from 'openbim-components'

export default async (structure: any, viewer: Components) => {
  // const propsFiles = await viewer. properties.serializeAllProperties(
  //   viewer.context.items.ifcModels[0],
  //   1000000,
  //   () => {}
  // )
  //
  setFlatStructure(structure)
}
