
import { Vue, Component } from 'vue-property-decorator'
import Navbar from '@/components/Navbar.vue'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  components: { Navbar },
  directives: { onClickaway }
})
export default class App extends Vue {}
