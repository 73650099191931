import Store from '@/store/modules/Viewer'

export default (structure: {
  expressID: string | number
  type: any
  children: { expressID: any }[]
}) => {
  const items: any[] = []

  items.push({
    expressID: structure.expressID,
    type: structure.type,
    children: structure.children.map(
      (child: { expressID: any }) => child.expressID
    )
  })

  for (const child of structure.children) {
    const properties = ''
    addChildToItems(child, items, properties)
  }

  Store.setFlatStructureItems(items)
}

const addChildToItems = (child: any, items: any[], properties: any) => {
  if (
    child.children &&
    child.children.every((ch: any) => !ch.children.length)
  ) {
    if (child.children.length) {
      let classObj: { [p: string]: string[] } = {}

      for (const ch of child.children) {
        if (classObj[ch.type] === undefined) {
          classObj = { ...classObj, [ch.type]: [ch.expressID] }
        } else {
          classObj[ch.type].push(ch.expressID)
        }
      }

      items.push({
        expressID: child.expressID,
        type: child.type,
        children: classObj,
        hasLeaves: true
      })
    } else {
      items.push({
        expressID: child.expressID,
        type: child.type,
        children: [],
        hasLeaves: true
      })
    }
  } else {
    items.push({
      expressID: child.expressID,
      type: child.type,
      children: child.children.map((ch: { expressID: any }) => ch.expressID)
    })

    if (child.children) {
      for (child of child.children) {
        const properties = ''
        addChildToItems(child, items, properties)
      }
    }
  }
}
