
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class Slider extends Vue {
  @Prop() title!: string
  @Prop() max!: number
  @Prop() step!: number
  @Prop() value!: string
  @Prop() toolTip!: string

  private pseudoValue: string = ''

  mounted() {
    this.pseudoValue = this.value
  }

  @Emit()
  sendStats() {
    return this.pseudoValue
  }
}
