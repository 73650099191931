
import { Component, Prop, Vue } from 'vue-property-decorator'
import Store from '@/store/modules/Viewer'
import IFCLoader from '@/assets/icons/IFC.svg'

@Component({
  components: { IFCLoader }
})
export default class LoadingBar extends Vue {
  @Prop() total!: number
  @Prop() current!: number

  private get loader(): string {
    return Store.loader
  }

  private get rate(): number {
    return Math.round((this.current / this.total) * 100)
  }
}
