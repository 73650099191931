
import { Vue, Component } from 'vue-property-decorator'
import IconInfo from '../../assets/icons/info.svg'
import FreeCard from '../Home/FreeCard.vue'
import SuscriptionCard from '../Home/SuscriptionCard.vue'
import PayCard from '../Home/PayCard.vue'

@Component({
  components: { IconInfo, FreeCard, SuscriptionCard, PayCard }
})
export default class GetImages extends Vue {}
