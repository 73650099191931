
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import IconDropBox from '@/assets/icons/dropbox.svg'
import IconDrive from '@/assets/icons/drive.svg'
import IconDownload from '@/assets/icons/download.svg'

@Component({
  components: { IconDropBox, IconDrive, IconDownload }
})
export default class LoadFile extends Vue {
  @Prop() isLoad!: boolean
  @Prop() dragClass!: string

  private choose: string = 'Choose IFC files'
  private clickHere: string =
    'Click or drag here and send your files for processing'

  @Emit() clickLoad() {}
}
