
import { Vue, Component } from 'vue-property-decorator'
import Toggle from '@/components/shared/Toggle.vue'

@Component({
  components: { Toggle }
})
export default class SuscriptionCard extends Vue {
  private clickToggle: boolean = false

  private listSuscription: {
    order: number
    numberCredits: number
    monthly: number
    priceImage: string
  }[] = [
    {
      order: 1,
      numberCredits: 40,
      monthly: 9,
      priceImage: '0,23'
    },
    {
      order: 2,
      numberCredits: 200,
      monthly: 39,
      priceImage: '0,20'
    },
    {
      order: 3,
      numberCredits: 500,
      monthly: 89,
      priceImage: '0,18'
    },
    {
      order: 4,
      numberCredits: 1200,
      monthly: 189,
      priceImage: '0,16'
    },
    {
      order: 5,
      numberCredits: 2800,
      monthly: 389,
      priceImage: '0,14'
    }
  ]
}
