import startFile from './startFile'
import generateUUID from './generateUUID'
import changeRefs from '@/controllers/joinIFC/changeRefs'
import convertToStepLine from '@/controllers/joinIFC/convertToStepLine'
import emptyLinesCheck from '@/controllers/joinIFC/emptyLinesCheck'

export default async (textFiles: any[]) => {
  const filesText = textFiles

  let headers = textFiles[0].split('DATA;')[0]
  headers.replaceAll('\n').replaceAll('\r')
  headers += 'DATA;\n'

  let newFile = ''

  let ifcBuildingIndex,
    ifcsiteIndex: number,
    ifcProjectIndex,
    ifcownerIndex: number = -1

  let buildingFound,
    siteFound,
    addedToSite,
    ownerHistory,
    projectFound: boolean = false

  let maxIndex = 0
  let dataStore = []

  for (const file of filesText) {
    const result: any = startFile(file)

    dataStore.push(result[0])

    if (filesText.indexOf(file) === 0) {
      maxIndex = result[1]
    } else {
      let data = dataStore[filesText.indexOf(file)]

      for (let item of data) {
        item[0] = `${parseInt(item[0]) + maxIndex}`

        for (let i = 2; i < item.length; i++) {
          item[i] = changeRefs(item[i], maxIndex)
        }
      }

      for (const item of data) {
        if (
          item.length > 1 &&
          item[1].includes('IFCBUILDING') &&
          !item[1].includes('IFCBUILDINGELEMENTPROXY') &&
          !item[1].includes('IFCBUILDINGSTOREY')
        ) {
          ifcBuildingIndex = item[0]
          buildingFound = true
        }
      }
    }
  }

  const originalFile = dataStore[0]

  for (const line of originalFile) {
    if (line.length > 1) {
      if (line[1].includes('IFCPROJECT')) {
        projectFound = true
        ifcProjectIndex = line[0]
      } else if (line[1].includes('IFCOWNERHISTORY')) {
        ownerHistory = true
        ifcownerIndex = line[0]
      } else if (line[1].includes('IFCSITE')) {
        siteFound = true
        ifcsiteIndex = line[0]
      }
    }
  }

  for (const line of originalFile) {
    if (line.length > 7 && line[1].includes('IFCRELAGGREGATES')) {
      const vl1 = String(line[6])
      // @ts-ignore
      const vl2 = String('#' + ifcsiteIndex)

      if (vl1.includes(vl2)) {
        addedToSite = true

        line[line.length - 1] = `${line[line.length - 1].substr(
          0,
          line[line.length - 1].length - 2
        )}, #${ifcBuildingIndex}))`
      }
    }
  }

  for (const line of originalFile) {
    if (line.length > 2) {
      const add = convertToStepLine(line)

      if (add.charAt(add.length - 3) === ')') {
        newFile += add
      }
    }
  }

  for (const file of dataStore) {
    if (dataStore.indexOf(file) > 0) {
      for (const line of file) {
        if (Array.isArray(line)) {
          if (line.length > 2) {
            if (typeof line[1] === 'string') {
              if (!line[1].includes('IFCSITE') && line[0] !== 'NaN') {
                const dat = convertToStepLine(line)
                newFile += dat
              }
            }
          }
        }
      }
    }
  }

  if (siteFound && buildingFound && addedToSite) {
    return `${headers}${newFile}\nENDSEC;\nEND-ISO-10303-21;`
  } else {
    if (!siteFound && buildingFound && ownerHistory && projectFound) {
      let startLine = 0

      for (const line of originalFile) {
        if (line.length > 2 && startLine < parseInt(line[0])) {
          startLine = parseInt(line[0])
        }
      }

      let maxLine = 0

      for (const file of dataStore) {
        if (dataStore.indexOf(file) > 0) {
          for (const line of file) {
            if (line.length > 2 && !line.startsWith('END')) {
              if (maxLine < parseInt(line[0]) + startLine) {
                maxLine = parseInt(line[0]) + startLine
              }
            }
          }

          maxLine++
          const newIfcSite: any = []

          newIfcSite.push(
            maxLine,
            'IFCSITE',
            `'${generateUUID()}'`,
            `#${ifcownerIndex}`,
            '$',
            '$',
            '$',
            '$',
            '$',
            '$',
            '.ELEMENT.',
            '$',
            '$',
            '$',
            '$',
            '$'
          )

          maxLine++

          const newRelProject: any = []

          newRelProject.push(
            maxLine,
            'IFCRELAGGREGATES',
            `'${generateUUID()}'`,
            `#${ifcownerIndex}`,
            '$',
            '$',
            `#${ifcProjectIndex}`,
            `#${maxLine - 1}`
          )

          maxLine++
          const newRelBuilding: any = []

          newRelBuilding.push(
            maxLine,
            'IFCRELAGGREGATES',
            `'${generateUUID()}'`,
            `#${ifcownerIndex}`,
            '$',
            '$',
            `#${maxLine - 2}`,
            `#${ifcBuildingIndex}`
          )

          newFile += convertToStepLine(newIfcSite)
          newFile += convertToStepLine(newRelProject)
          newFile += convertToStepLine(newRelBuilding)
        }
      }

      newFile += 'ENDSEC;\n'
      newFile += 'END-ISO-10303-21;'

      return emptyLinesCheck(newFile)
    }
  }
}
