
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconILove from '@/assets/icons/icon-ilove.svg'
import { UserIn } from '@/models'
import agreement from '@/config/agreement'
import registerUser from '@/helpers/registerUser'

@Component({
  components: { IconILove },
  directives: { onClickaway }
})
export default class ModalRegister extends Vue {
  private condition: string = agreement
  private accessHere: string = ''

  private user: UserIn = {
    email: '',
    password: ''
  }

  private get isDisabled(): boolean {
    return this.user.email.length < 5
  }

  mounted() {
    const input = document.getElementById('email')
    if (input) input.focus()
  }

  private async register() {
    const user = this.user
    const register = await registerUser(user, this.$toasted)
    if (register) this.close()
  }

  private openAccess() {
    this.$root.$emit('show-login')
    this.close()
  }

  @Emit() close() {}
}
