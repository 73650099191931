
import { Vue, Component, Emit, Watch } from 'vue-property-decorator'
import Action from '@/components/ActionsViewer/Action.vue'
import actionsMenu from '@/config/actionsMenu'
import Store from '@/store/modules/Viewer'
import IconCam from '@/assets/icons/cam.svg'
import IconCamAI from '@/assets/icons/camAI.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Slider from '@/components/shared/Slider.vue'
import { Stats } from '@/models'
import IconDownload from '@/assets/icons/file-download.svg'
import expendOneCredit from '@/helpers/expendOneCredit'
import IconLoader from '@/assets/icons/loader.svg'
import IconSave from '@/assets/icons/save.svg'

@Component({
  directives: { onClickaway },
  components: {
    Action,
    IconCam,
    IconCamAI,
    IconDownload,
    Input,
    IconLoader,
    IconSave,
    Slider
  }
})
export default class Actions extends Vue {
  private menu = actionsMenu
  private clickShot: boolean = false
  private showPopup: boolean = false

  // private resultImages: string[] = [
  //   'https://d1okzptojspljx.cloudfront.net/generations/811ab743-4cd9-4113-95b2-28c32597faed-0.png',
  //   'https://d1okzptojspljx.cloudfront.net/generations/811ab743-4cd9-4113-95b2-28c32597faed-1.png',
  //   'https://d1okzptojspljx.cloudfront.net/generations/811ab743-4cd9-4113-95b2-28c32597faed-2.png'
  // ]

  private get activeAction(): string {
    return Store.activeAction
  }

  private get stylePanel(): boolean {
    return Store.stylePanel
  }

  private get fileForViewer() {
    return Store.fileForViewer
  }

  mounted() {
    this.$root.$on('show-panel-left', (status: boolean) => {
      Store.setStylePanel(status)
    })

    this.$root.$on('close-popup', (status: boolean) => {
      this.showPopup = status
    })
  }

  private async showConfigAI() {
    if (this.fileForViewer !== null) {
      this.showPopup = true
      this.$root.$emit('show-popup', true)
    } else {
      this.$toasted.error(
        'To set up Snapshot AI, you need to load a model first'
      )
    }
  }

  private sendClickShot() {
    this.clickShot = true

    if (this.activeAction === 'Relocate IFC') {
      this.$root.$emit('screenshotRelocate')
    } else {
      this.$root.$emit('sendClickShot')
    }

    setTimeout(() => {
      this.clickShot = false
    }, 500)
  }

  @Watch('stylePanel')
  onStylePanelChange() {
    this.$root.$emit('panel-left-open', this.stylePanel)
  }

  @Emit()
  setActiveAction(action: string): string | null {
    return action
  }
}

// todo: integrar i18n para tener versiones ES-EN
