
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Viewer'

@Component
export default class Avatar extends Vue {
  @Prop({ default: 'sm' }) size!: 'sm' | 'lg'
  @Prop({ default: false }) uploadable!: boolean

  private firstChar: string = ''
  private url: string = ''
  private hovering: boolean = false

  mounted() {
    if (this.avatar === '') {
      setTimeout(async () => {
        const {
          data: {
            avatar: { url, firstChar }
          }
        } = await this.$apollo.query({
          query: require('@/graphql/queries/avatar.graphql')
        })
        if (!url) {
          this.firstChar = firstChar
        } else {
          this.url = `${process.env.VUE_APP_CLOUDINARY_IMG_PREFIX}${url}`
          Store.setAvatar(this.url)
        }
      }, 100)
    } else {
      this.url = this.avatar
    }
  }

  private get avatar(): string {
    return Store.avatar
  }

  @Emit()
  openPrompt() {}

  @Watch('avatar')
  onAvatarChange() {
    if (this.avatar) {
      this.url = this.avatar
    }
  }
}
