
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import selectItems from '@/controllers/hierarchyStructure/selectItems'
import Store from '@/store/modules/Viewer'

@Component
export default class AttributesAllSelected extends Vue {
  @Prop() item!: any
  @Prop() items!: any[]
  @Prop({ default: false }) isForClasses!: boolean

  private handleSelect() {
    if (!this.isForClasses) {
      let ids: any[] = []

      if (typeof this.item === 'string') {
        if (this.item === 'MODEL') {
          for (const s in Store.spatialStructure) {
            for (const e in Store.spatialStructure[s]) {
              ids.push(...Store.spatialStructure[s][e])
            }
          }
        } else {
          for (const e in Store.spatialStructure[this.item]) {
            ids.push(...Store.spatialStructure[this.item][e])
          }
        }
      } else {
        // @ts-ignore
        ids = Object.values(this.item)[0]
      }

      Store.addSelectedExpressIDs(ids)

      // selectItems(this.item, this.items, true)
    } else {
      this.classClick()
    }
  }

  @Emit() classClick() {
    return true
  }
}
