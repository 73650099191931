export default [
  {
    order: 1,
    numberCredits: 1,
    monthly: 9,
    priceImage: '1,99'
  },
  {
    order: 2,
    numberCredits: 10,
    monthly: 9,
    priceImage: '0,90'
  },
  {
    order: 3,
    numberCredits: 75,
    monthly: 49,
    priceImage: '0,65'
  },
  {
    order: 4,
    numberCredits: 200,
    monthly: 99,
    priceImage: '0,50'
  },
  {
    order: 5,
    numberCredits: 500,
    monthly: 99,
    priceImage: '0,40'
  }
]
