import {
  Components,
  FragmentIfcLoader,
  FragmentManager,
  ScreenCuller
} from 'openbim-components'
import defineMeshes from '@/controllers/parseIfcFile/defineMeshes'
import Store from '@/store/modules/Viewer'
import setStructureTree from '@/controllers/parseIfcFile/setStructureTree'

export default async (
  file: any,
  viewer: Components,
  loader: FragmentIfcLoader,
  fragments: FragmentManager,
  culler: ScreenCuller,
  isFromMapbox: boolean = false
) => {
  // @ts-ignore
  const model = await loader.load(file)

  viewer.scene.get().add(model)

  defineMeshes(model.items, viewer, culler)

  Store.setProperties(model.properties)

  // @ts-ignore
  setStructureTree(viewer, fragments, model)

  return model
}
