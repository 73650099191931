
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: 'input' }) inputId!: string
  @Prop() placeholder!: string
  @Prop({ default: 'text' }) type!: string
  @Prop({ default: false }) readonly!: boolean
  @Prop({ default: 1 }) step!: number
  @Prop({ default: '' }) max!: any
  @Prop({ default: '' }) min!: any
  @Prop({ default: 0 }) valueNum!: number
  @Prop({ default: false }) inputNumber!: boolean

  @Emit()
  private onInput(value: string): string {
    return value
  }

  @Emit()
  private onInputNum(value: string): number {
    return Number(value)
  }
}
