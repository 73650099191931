
import { Vue, Component } from 'vue-property-decorator'
import about from '@/config/about'
import IconILove from '@/assets/icons/icon-ilove.svg'

@Component({
  components: { IconILove }
})
export default class About extends Vue {
  private about: string = about
}
