export default {
  '5716631': 'IFCTANKTYPE',
  '15328376': 'IFCCOMPOSITECURVEONSURFACE',
  '24185140': 'IFCFACILITY',
  '25142252': 'IFCCONTROLLER',
  '32344328': 'IFCBOILER',
  '32440307': 'IFCDIRECTION',
  '39481116': 'IFCBUILDINGELEMENTPARTTYPE',
  '45288368': 'IFCCONNECTIONPOINTECCENTRICITY',
  '53199957': 'IFCALIGNMENT2DVERTICAL',
  '59481748': 'IFCCARTESIANTRANSFORMATIONOPERATOR',
  '76236018': 'IFCLAMP',
  '90941305': 'IFCPUMP',
  '101040310': 'IFCPERSONANDORGANIZATION',
  '103090709': 'IFCPROJECT',
  '110355661': 'IFCPROPERTYTABLEVALUE',
  '125510826': 'IFCLIGHTSOURCEAMBIENT',
  '130549933': 'IFCAPPROVAL',
  '132023988': 'IFCENGINETYPE',
  '144952367': 'IFCOUTERBOUNDARYCURVE',
  '148013059': 'IFCSUBCONTRACTRESOURCE',
  '148025276': 'IFCPROPERTYDEPENDENCYRELATIONSHIP',
  '160246688': 'IFCRELAGGREGATES',
  '164193824': 'IFCMATERIALPROFILESET',
  '167062518': 'IFCBSPLINESURFACEWITHKNOTS',
  '177149247': 'IFCAIRTERMINALBOX',
  '178086475': 'IFCGRIDPLACEMENT',
  '178912537': 'IFCINDEXEDPOLYGONALFACE',
  '180925521': 'IFCUNITASSIGNMENT',
  '182646315': 'IFCFLOWINSTRUMENT',
  '205026976': 'IFCRELASSIGNSTORESOURCE',
  '211053100': 'IFCEVENTTIME',
  '214636428': 'IFCSTRUCTURALCURVEMEMBER',
  '219451334': 'IFCOBJECTDEFINITION',
  '220341763': 'IFCPLANE',
  '230924584': 'IFCSWEPTSURFACE',
  '231477066': 'IFCBOILERTYPE',
  '248100487': 'IFCMATERIALLAYER',
  '263784265': 'IFCFURNISHINGELEMENT',
  '264262732': 'IFCELECTRICGENERATOR',
  '277319702': 'IFCAUDIOVISUALAPPLIANCE',
  '279856033': 'IFCRELFLOWCONTROLELEMENTS',
  '280115917': 'IFCTEXTURECOORDINATE',
  '297599258': 'IFCEXTENDEDPROPERTIES',
  '300633059': 'IFCCOLUMNTYPE',
  '307848117': 'IFCRELDEFINESBYTEMPLATE',
  '310824031': 'IFCPIPEFITTING',
  '315944413': 'IFCFILLAREASTYLETILES',
  '325726236': 'IFCALIGNMENT',
  '331165859': 'IFCSTAIR',
  '335055490': 'IFCCOOLEDBEAMTYPE',
  '336235671': 'IFCWINDOWLININGPROPERTIES',
  '338393293': 'IFCSTAIRTYPE',
  '339256511': 'IFCELEMENTTYPE',
  '342316401': 'IFCDUCTFITTING',
  '346874300': 'IFCFANTYPE',
  '366585022': 'IFCRELSERVICESBUILDINGS',
  '370225590': 'IFCCONNECTEDFACESET',
  '374418227': 'IFCFILLAREASTYLEHATCHING',
  '377706215': 'IFCMECHANICALFASTENER',
  '385403989': 'IFCSTRUCTURALLOADCASE',
  '388784114': 'IFCLINEARPLACEMENT',
  '395041908': 'IFCCABLECARRIERFITTINGTYPE',
  '395920057': 'IFCDOOR',
  '400855858': 'IFCCOMMUNICATIONSAPPLIANCETYPE',
  '402227799': 'IFCELECTRICMOTOR',
  '411424972': 'IFCAPPLIEDVALUE',
  '413509423': 'IFCSYSTEMFURNITUREELEMENT',
  '427810014': 'IFCUSHAPEPROFILEDEF',
  '427948657': 'IFCRELINTERFERESELEMENTS',
  '428585644': 'IFCLABORRESOURCETYPE',
  '445594917': 'IFCDRAUGHTINGPREDEFINEDCOLOUR',
  '448429030': 'IFCSIUNIT',
  '451544542': 'IFCSPHERE',
  '463610769': 'IFCSPATIALZONE',
  '476780140': 'IFCEDGECURVE',
  '477187591': 'IFCEXTRUDEDAREASOLID',
  '478536968': 'IFCRELATIONSHIP',
  '484807127': 'IFCEVAPORATOR',
  '486154966': 'IFCWINDOWSTANDARDCASE',
  '488727124': 'IFCCONSTRUCTIONPRODUCTRESOURCE',
  '492091185': 'IFCPROPERTYSETTEMPLATE',
  '504942748': 'IFCRELCONNECTSWITHECCENTRICITY',
  '512836454': 'IFCWINDOWPANELPROPERTIES',
  '526551008': 'IFCDOORSTYLE',
  '530289379': 'IFCSTRUCTURALMEMBER',
  '531007025': 'IFCTABLEROW',
  '539742890': 'IFCCURRENCYRELATIONSHIP',
  '552965576': 'IFCMATERIALPROFILEWITHOFFSETS',
  '562808652': 'IFCDISTRIBUTIONCIRCUIT',
  '569719735': 'IFCPROCEDURETYPE',
  '572779678': 'IFCLSHAPEPROFILEDEF',
  '574549367': 'IFCCARTESIANPOINTLIST',
  '578613899': 'IFCCONTROLLERTYPE',
  '581633288': 'IFCTIMESERIESVALUE',
  '590820931': 'IFCOFFSETCURVE',
  '597895409': 'IFCPIXELTEXTURE',
  '602808272': 'IFCCOSTVALUE',
  '603570806': 'IFCPLANARBOX',
  '603775116': 'IFCSTRUCTURALSURFACEREACTION',
  '609421318': 'IFCSTRUCTURALLOADORRESULT',
  '616511568': 'IFCBLOBTEXTURE',
  '618182010': 'IFCADDRESS',
  '626085974': 'IFCSURFACETEXTURE',
  '629592764': 'IFCLIGHTFIXTURE',
  '630975310': 'IFCUNITARYCONTROLELEMENT',
  '635142910': 'IFCCABLECARRIERFITTING',
  '639361253': 'IFCCOIL',
  '639542469': 'IFCAPPLICATION',
  '643959842': 'IFCORIENTATIONEXPRESSION',
  '644574406': 'IFCBRIDGE',
  '647756555': 'IFCFASTENER',
  '647927063': 'IFCCLASSIFICATIONREFERENCE',
  '653396225': 'IFCPROJECTLIBRARY',
  '655969474': 'IFCPROTECTIVEDEVICETRIPPINGUNITTYPE',
  '663422040': 'IFCELECTRICAPPLIANCETYPE',
  '669184980': 'IFCANNOTATIONFILLAREA',
  '673634403': 'IFCPRODUCTDEFINITIONSHAPE',
  '677532197': 'IFCPRESENTATIONITEM',
  '682877961': 'IFCSTRUCTURALACTION',
  '683857671': 'IFCRATIONALBSPLINESURFACEWITHKNOTS',
  '693640335': 'IFCRELDEFINES',
  '699246055': 'IFCSURFACECURVE',
  '707683696': 'IFCFLOWSTORAGEDEVICE',
  '710998568': 'IFCSPATIALELEMENTTYPE',
  '712377611': 'IFCELECTRICTIMECONTROLTYPE',
  '723233188': 'IFCSOLIDMODEL',
  '728799441': 'IFCVALVETYPE',
  '734778138': 'IFCSTRUCTURALPOINTCONNECTION',
  '738039164': 'IFCPROTECTIVEDEVICE',
  '738692330': 'IFCFILLAREASTYLE',
  '747523909': 'IFCCLASSIFICATION',
  '749761778': 'IFCALIGNMENT2DHORIZONTAL',
  '750771296': 'IFCRELPROJECTSELEMENT',
  '753842376': 'IFCBEAM',
  '759155922': 'IFCPREDEFINEDCOLOUR',
  '760658860': 'IFCMATERIALDEFINITION',
  '770865208': 'IFCDOCUMENTINFORMATIONRELATIONSHIP',
  '775493141': 'IFCCONNECTIONVOLUMEGEOMETRY',
  '776857604': 'IFCCOLOURRGB',
  '781010003': 'IFCRELDEFINESBYTYPE',
  '803316827': 'IFCFACEOUTERBOUND',
  '804291784': 'IFCPIPEFITTINGTYPE',
  '807026263': 'IFCFACETEDBREP',
  '812098782': 'IFCHALFSPACESOLID',
  '812556717': 'IFCTANK',
  '816062949': 'IFCREPARAMETRISEDCOMPOSITECURVESEGMENT',
  '819412036': 'IFCFILTER',
  '819618141': 'IFCBEAMTYPE',
  '825690147': 'IFCQUANTITYWEIGHT',
  '826625072': 'IFCRELCONNECTS',
  '843113511': 'IFCCOLUMN',
  '846575682': 'IFCSURFACESTYLESHADING',
  '852622518': 'IFCGRIDAXIS',
  '853536259': 'IFCMATERIALRELATIONSHIP',
  '862014818': 'IFCELECTRICDISTRIBUTIONBOARD',
  '867548509': 'IFCSHAPEASPECT',
  '869906466': 'IFCDUCTFITTINGTYPE',
  '871118103': 'IFCPROPERTYBOUNDEDVALUE',
  '886880790': 'IFCRELCOVERSBLDGELEMENTS',
  '891718957': 'IFCVIRTUALGRIDINTERSECTION',
  '900683007': 'IFCFOOTING',
  '901063453': 'IFCTESSELLATEDITEM',
  '905975707': 'IFCCOLUMNSTANDARDCASE',
  '912023232': 'IFCTELECOMADDRESS',
  '919958153': 'IFCRELASSOCIATESCLASSIFICATION',
  '926996030': 'IFCVOIDINGFEATURE',
  '931644368': 'IFCQUANTITYLENGTH',
  '941946838': 'IFCPROPERTYREFERENCEVALUE',
  '963979645': 'IFCBRIDGEPART',
  '964333572': 'IFCREINFORCINGELEMENTTYPE',
  '977012517': 'IFCMOTORCONNECTIONTYPE',
  '979691226': 'IFCREINFORCINGBAR',
  '982818633': 'IFCRELASSOCIATESDOCUMENT',
  '985171141': 'IFCTABLE',
  '986844984': 'IFCPROPERTYABSTRACTION',
  '987401354': 'IFCFLOWSEGMENT',
  '987898635': 'IFCGEOMETRICCURVESET',
  '1003880860': 'IFCELECTRICTIMECONTROL',
  '1004757350': 'IFCSTRUCTURALCURVEACTION',
  '1008929658': 'IFCLOOP',
  '1027710054': 'IFCRELASSIGNSTOGROUPBYFACTOR',
  '1028945134': 'IFCWORKCONTROL',
  '1029017970': 'IFCORIENTEDEDGE',
  '1033361043': 'IFCZONE',
  '1039846685': 'IFCSTAIRFLIGHTTYPE',
  '1040185647': 'IFCEXTERNALLYDEFINEDSURFACESTYLE',
  '1042787934': 'IFCRESOURCETIME',
  '1045800335': 'IFCDERIVEDUNITELEMENT',
  '1051575348': 'IFCLAMPTYPE',
  '1051757585': 'IFCCABLEFITTING',
  '1052013943': 'IFCDISTRIBUTIONCHAMBERELEMENT',
  '1054537805': 'IFCSCHEDULINGTIME',
  '1060000209': 'IFCCONSTRUCTIONMATERIALRESOURCE',
  '1062206242': 'IFCCIRCULARARCSEGMENT2D',
  '1062813311': 'IFCDISTRIBUTIONCONTROLELEMENT',
  '1072016465': 'IFCSOLARDEVICETYPE',
  '1073191201': 'IFCMEMBER',
  '1076942058': 'IFCREPRESENTATION',
  '1095909175': 'IFCBUILDINGELEMENTPROXY',
  '1096409881': 'IFCSWEPTDISKSOLIDPOLYGONAL',
  '1105321065': 'IFCCURVESTYLEFONT',
  '1114901282': 'IFCMEDICALDEVICETYPE',
  '1123145078': 'IFCCARTESIANPOINT',
  '1133259667': 'IFCWASTETERMINALTYPE',
  '1136057603': 'IFCBOUNDARYCURVE',
  '1154170062': 'IFCDOCUMENTINFORMATION',
  '1154579445': 'IFCLINEARPOSITIONINGELEMENT',
  '1156407060': 'IFCPLATESTANDARDCASE',
  '1158309216': 'IFCPILETYPE',
  '1161773419': 'IFCLIGHTFIXTURETYPE',
  '1162798199': 'IFCSWITCHINGDEVICE',
  '1177604601': 'IFCBUILDINGSYSTEM',
  '1179482911': 'IFCSTRUCTURALCONNECTION',
  '1186437898': 'IFCCURVESEGMENT2D',
  '1190533807': 'IFCSTRUCTURALLOADSINGLEFORCEWARPING',
  '1199560280': 'IFCTIMEPERIOD',
  '1204542856': 'IFCRELCONNECTSELEMENTS',
  '1207048766': 'IFCOWNERHISTORY',
  '1209101575': 'IFCEXTERNALSPATIALELEMENT',
  '1210645708': 'IFCTEXTUREVERTEX',
  '1213902940': 'IFCCYLINDRICALSURFACE',
  '1217240411': 'IFCELECTRICMOTORTYPE',
  '1229763772': 'IFCTRIANGULATEDIRREGULARNETWORK',
  '1232101972': 'IFCRATIONALBSPLINECURVEWITHKNOTS',
  '1235345126': 'IFCSTRUCTURALPOINTREACTION',
  '1236880293': 'IFCWORKTIME',
  '1245217292': 'IFCRELREFERENCEDINSPATIALSTRUCTURE',
  '1251058090': 'IFCHEATEXCHANGERTYPE',
  '1252848954': 'IFCSTRUCTURALLOADGROUP',
  '1260505505': 'IFCBOUNDEDCURVE',
  '1260650574': 'IFCSWEPTDISKSOLID',
  '1268542332': 'IFCFURNITURETYPE',
  '1281925730': 'IFCLINE',
  '1285652485': 'IFCCABLESEGMENTTYPE',
  '1287392070': 'IFCFEATUREELEMENTSUBTRACTION',
  '1290935644': 'IFCSECTIONEDSOLIDHORIZONTAL',
  '1299126871': 'IFCWINDOWSTYLE',
  '1300840506': 'IFCSURFACESTYLE',
  '1303795690': 'IFCMATERIALLAYERSETUSAGE',
  '1304840413': 'IFCPRESENTATIONLAYERWITHSTYLE',
  '1305183839': 'IFCSPACEHEATERTYPE',
  '1306400036': 'IFCDEEPFOUNDATIONTYPE',
  '1307041759': 'IFCRELASSIGNSTOGROUP',
  '1310608509': 'IFCARBITRARYOPENPROFILEDEF',
  '1310830890': 'IFCFACILITYPART',
  '1329646415': 'IFCSHADINGDEVICE',
  '1334484129': 'IFCBLOCK',
  '1335981549': 'IFCDISCRETEACCESSORY',
  '1339347760': 'IFCFLOWSTORAGEDEVICETYPE',
  '1351298697': 'IFCSURFACESTYLEWITHTEXTURES',
  '1360408905': 'IFCDUCTSILENCER',
  '1377556343': 'IFCTOPOLOGICALREPRESENTATIONITEM',
  '1383045692': 'IFCCIRCLEPROFILEDEF',
  '1387855156': 'IFCBOUNDARYNODECONDITION',
  '1401173127': 'IFCRELVOIDSELEMENT',
  '1402838566': 'IFCLIGHTSOURCE',
  '1404847402': 'IFCSTACKTERMINAL',
  '1411181986': 'IFCORGANIZATIONRELATIONSHIP',
  '1411407467': 'IFCAIRTERMINALBOXTYPE',
  '1412071761': 'IFCSPATIALELEMENT',
  '1416205885': 'IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM',
  '1417489154': 'IFCVECTOR',
  '1419761937': 'IFCCOSTSCHEDULE',
  '1423911732': 'IFCPOINTONSURFACE',
  '1425443689': 'IFCMANIFOLDSOLIDBREP',
  '1426591983': 'IFCFIRESUPPRESSIONTERMINAL',
  '1437502449': 'IFCMEDICALDEVICE',
  '1437805879': 'IFCEXTERNALREFERENCERELATIONSHIP',
  '1437953363': 'IFCINDEXEDTEXTUREMAP',
  '1441486842': 'IFCRELPOSITIONS',
  '1447204868': 'IFCTEXTSTYLE',
  '1451395588': 'IFCPROPERTYSET',
  '1457835157': 'IFCCURTAINWALLTYPE',
  '1462361463': 'IFCRELDEFINESBYOBJECT',
  '1466758467': 'IFCCOORDINATEREFERENCESYSTEM',
  '1469900589': 'IFCRAMPTYPE',
  '1472233963': 'IFCEDGELOOP',
  '1482703590': 'IFCPROPERTYTEMPLATEDEFINITION',
  '1482959167': 'IFCFLOWMOVINGDEVICETYPE',
  '1484403080': 'IFCISHAPEPROFILEDEF',
  '1485152156': 'IFCCOMPOSITEPROFILEDEF',
  '1507914824': 'IFCMATERIALUSAGEDEFINITION',
  '1509187699': 'IFCSECTIONEDSPINE',
  '1509553395': 'IFCFURNITURE',
  '1520743889': 'IFCLIGHTSOURCEPOSITIONAL',
  '1521410863': 'IFCRELSPACEBOUNDARY2NDLEVEL',
  '1525564444': 'IFCCONSTRUCTIONPRODUCTRESOURCETYPE',
  '1529196076': 'IFCSLAB',
  '1530820697': 'IFCVIBRATIONDAMPER',
  '1532957894': 'IFCAUDIOVISUALAPPLIANCETYPE',
  '1534661035': 'IFCELECTRICGENERATORTYPE',
  '1549132990': 'IFCTASKTIME',
  '1560379544': 'IFCBOUNDARYEDGECONDITION',
  '1566485204': 'IFCLIGHTINTENSITYDISTRIBUTION',
  '1580146022': 'IFCREINFORCEMENTBARPROPERTIES',
  '1580310250': 'IFCSYSTEMFURNITUREELEMENTTYPE',
  '1585845231': 'IFCLAGTIME',
  '1595516126': 'IFCSTRUCTURALLOADLINEARFORCE',
  '1597423693': 'IFCSTRUCTURALLOADSINGLEFORCE',
  '1599208980': 'IFCDISTRIBUTIONCHAMBERELEMENTTYPE',
  '1600972822': 'IFCTUBEBUNDLETYPE',
  '1607154358': 'IFCSURFACESTYLEREFRACTION',
  '1608871552': 'IFCRESOURCECONSTRAINTRELATIONSHIP',
  '1620046519': 'IFCTRANSPORTELEMENT',
  '1621171031': 'IFCSTRUCTURALPLANARACTION',
  '1623761950': 'IFCELEMENTCOMPONENT',
  '1628702193': 'IFCTYPEOBJECT',
  '1634111441': 'IFCAIRTERMINAL',
  '1635779807': 'IFCADVANCEDBREP',
  '1638771189': 'IFCRELCONNECTSSTRUCTURALMEMBER',
  '1640371178': 'IFCTEXTSTYLETEXTMODEL',
  '1658829314': 'IFCENERGYCONVERSIONDEVICE',
  '1660063152': 'IFCREPRESENTATIONMAP',
  '1663979128': 'IFCPLANAREXTENT',
  '1674181508': 'IFCANNOTATION',
  '1675464909': 'IFCCARTESIANPOINTLIST2D',
  '1677625105': 'IFCCIVILELEMENT',
  '1680319473': 'IFCPROPERTYDEFINITION',
  '1682466193': 'IFCPCURVE',
  '1683148259': 'IFCRELASSIGNSTOACTOR',
  '1687234759': 'IFCPILE',
  '1692211062': 'IFCTRANSFORMERTYPE',
  '1704287377': 'IFCELLIPSE',
  '1714330368': 'IFCDOORPANELPROPERTIES',
  '1735638870': 'IFCTOPOLOGYREPRESENTATION',
  '1742049831': 'IFCTEXTURECOORDINATEGENERATOR',
  '1758889154': 'IFCELEMENT',
  '1765591967': 'IFCDERIVEDUNIT',
  '1768891740': 'IFCSANITARYTERMINALTYPE',
  '1775413392': 'IFCPREDEFINEDTEXTFONT',
  '1783015770': 'IFCSENSORTYPE',
  '1785450214': 'IFCCOORDINATEOPERATION',
  '1806887404': 'IFCHUMIDIFIERTYPE',
  '1807405624': 'IFCSTRUCTURALLINEARACTION',
  '1809719519': 'IFCFACEBOUND',
  '1810631287': 'IFCFILTERTYPE',
  '1815067380': 'IFCCREWRESOURCETYPE',
  '1834744321': 'IFCFLOWSEGMENTTYPE',
  '1838606355': 'IFCMATERIAL',
  '1842657554': 'IFCPROTECTIVEDEVICETYPE',
  '1847130766': 'IFCMATERIALCLASSIFICATIONRELATIONSHIP',
  '1847252529': 'IFCMATERIALLAYERWITHOFFSETS',
  '1856042241': 'IFCREVOLVEDAREASOLID',
  '1862484736': 'IFCSECTIONEDSOLID',
  '1865459582': 'IFCRELASSOCIATES',
  '1871374353': 'IFCAIRTOAIRHEATRECOVERYTYPE',
  '1878645084': 'IFCSURFACESTYLERENDERING',
  '1883228015': 'IFCELEMENTQUANTITY',
  '1893162501': 'IFCFOOTINGTYPE',
  '1898987631': 'IFCWALLTYPE',
  '1904799276': 'IFCELECTRICAPPLIANCE',
  '1907098498': 'IFCVERTEXPOINT',
  '1909888760': 'IFCBUILDINGELEMENTPROXYTYPE',
  '1911125066': 'IFCUNITARYEQUIPMENTTYPE',
  '1911478936': 'IFCMEMBERSTANDARDCASE',
  '1916426348': 'IFCCOVERINGTYPE',
  '1918398963': 'IFCNAMEDUNIT',
  '1935646853': 'IFCTOROIDALSURFACE',
  '1945004755': 'IFCDISTRIBUTIONELEMENT',
  '1945343521': 'IFCDISTANCEEXPRESSION',
  '1946335990': 'IFCPOSITIONINGELEMENT',
  '1950629157': 'IFCBUILDINGELEMENTTYPE',
  '1959218052': 'IFCCONSTRAINT',
  '1967976161': 'IFCBSPLINECURVE',
  '1973038258': 'IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION',
  '1973544240': 'IFCCOVERING',
  '1975003073': 'IFCSTRUCTURALSURFACECONNECTION',
  '1981873012': 'IFCCONNECTIONCURVEGEOMETRY',
  '1983826977': 'IFCTEXTSTYLEFONTMODEL',
  '1999602285': 'IFCSPACEHEATER',
  '2004835150': 'IFCPLACEMENT',
  '2016517767': 'IFCROOF',
  '2022407955': 'IFCMATERIALDEFINITIONREPRESENTATION',
  '2022622350': 'IFCPRESENTATIONLAYERASSIGNMENT',
  '2028607225': 'IFCSURFACECURVESWEPTAREASOLID',
  '2029264950': 'IFCALIGNMENT2DVERTICALSEGMENT',
  '2030761528': 'IFCDUCTSILENCERTYPE',
  '2042790032': 'IFCSECTIONPROPERTIES',
  '2043862942': 'IFCTABLECOLUMN',
  '2044713172': 'IFCQUANTITYAREA',
  '2047409740': 'IFCFACEBASEDSURFACEMODEL',
  '2056796094': 'IFCAIRTOAIRHEATRECOVERY',
  '2058353004': 'IFCFLOWCONTROLLER',
  '2059837836': 'IFCCARTESIANPOINTLIST3D',
  '2063403501': 'IFCDISTRIBUTIONCONTROLELEMENTTYPE',
  '2067069095': 'IFCPOINT',
  '2068733104': 'IFCHUMIDIFIER',
  '2069777674': 'IFCBOUNDARYNODECONDITIONWARPING',
  '2077209135': 'IFCPERSON',
  '2082059205': 'IFCSTRUCTURALPOINTACTION',
  '2090586900': 'IFCQUANTITYSET',
  '2093928680': 'IFCQUANTITYCOUNT',
  '2095639259': 'IFCPRODUCTREPRESENTATION',
  '2097647324': 'IFCTRANSPORTELEMENTTYPE',
  '2107101300': 'IFCENERGYCONVERSIONDEVICETYPE',
  '2108223431': 'IFCMECHANICALFASTENERTYPE',
  '2127690289': 'IFCRELCONNECTSSTRUCTURALACTIVITY',
  '2133299955': 'IFCINDEXEDTRIANGLETEXTUREMAP',
  '2143335405': 'IFCFEATUREELEMENTADDITION',
  '2147822146': 'IFCCSGSOLID',
  '2157484638': 'IFCSEAMCURVE',
  '2162789131': 'IFCSTRUCTURALLOAD',
  '2176052936': 'IFCJUNCTIONBOX',
  '2185764099': 'IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE',
  '2188021234': 'IFCFLOWMETER',
  '2188180465': 'IFCBURNERTYPE',
  '2197970202': 'IFCCHIMNEYTYPE',
  '2199411900': 'IFCMATERIALLIST',
  '2205249479': 'IFCCLOSEDSHELL',
  '2218152070': 'IFCSTRUCTURALSURFACEMEMBERVARYING',
  '2223149337': 'IFCFLOWTERMINAL',
  '2226359599': 'IFCPHYSICALSIMPLEQUANTITY',
  '2233826070': 'IFCSUBEDGE',
  '2235152071': 'IFCMATERIALPROFILE',
  '2242383968': 'IFCEXTERNALLYDEFINEDHATCHSTYLE',
  '2247615214': 'IFCSWEPTAREASOLID',
  '2250791053': 'IFCPUMPTYPE',
  '2251480897': 'IFCOBJECTIVE',
  '2254336722': 'IFCSYSTEM',
  '2262370178': 'IFCRAILING',
  '2272882330': 'IFCCONDENSER',
  '2273995522': 'IFCSTRUCTURALCONNECTIONCONDITION',
  '2281632017': 'IFCTENDONCONDUITTYPE',
  '2294589976': 'IFCINDEXEDPOLYGONALFACEWITHVOIDS',
  '2295281155': 'IFCPROTECTIVEDEVICETRIPPINGUNIT',
  '2296667514': 'IFCACTOR',
  '2297155007': 'IFCFLOWTERMINALTYPE',
  '2301859152': 'IFCCOILTYPE',
  '2310774935': 'IFCREINFORCINGMESHTYPE',
  '2315554128': 'IFCSWITCHINGDEVICETYPE',
  '2320036040': 'IFCREINFORCINGMESH',
  '2323601079': 'IFCDOORTYPE',
  '2324767716': 'IFCRAMPFLIGHTTYPE',
  '2341007311': 'IFCROOT',
  '2347385850': 'IFCMAPPEDITEM',
  '2347447852': 'IFCTENDONANCHOR',
  '2347495698': 'IFCTYPEPRODUCT',
  '2367409068': 'IFCCURVESTYLEFONTANDSCALING',
  '2382730787': 'IFCPERFORMANCEHISTORY',
  '2387106220': 'IFCTESSELLATEDFACESET',
  '2391368822': 'IFCINVENTORY',
  '2391383451': 'IFCVIBRATIONISOLATOR',
  '2391406946': 'IFCWALL',
  '2397081782': 'IFCELEMENTASSEMBLYTYPE',
  '2405470396': 'IFCQUANTITYVOLUME',
  '2415094496': 'IFCTENDONTYPE',
  '2417008758': 'IFCELECTRICDISTRIBUTIONBOARDTYPE',
  '2417041796': 'IFCPRESENTATIONSTYLEASSIGNMENT',
  '2433181523': 'IFCREFERENCE',
  '2439245199': 'IFCRESOURCELEVELRELATIONSHIP',
  '2445595289': 'IFCSTRUCTURALCURVEMEMBERVARYING',
  '2453401579': 'IFCGEOMETRICREPRESENTATIONITEM',
  '2461110595': 'IFCBSPLINECURVEWITHKNOTS',
  '2473145415': 'IFCSTRUCTURALLOADSINGLEDISPLACEMENT',
  '2474470126': 'IFCMOTORCONNECTION',
  '2481509218': 'IFCSPATIALZONETYPE',
  '2483315170': 'IFCPHYSICALQUANTITY',
  '2483840362': 'IFCALIGNMENT2DSEGMENT',
  '2485617015': 'IFCCOMPOSITECURVESEGMENT',
  '2485787929': 'IFCOFFSETCURVEBYDISTANCES',
  '2489546625': 'IFCFASTENERTYPE',
  '2495723537': 'IFCRELASSIGNSTOCONTROL',
  '2506170314': 'IFCCSGPRIMITIVE3D',
  '2510884976': 'IFCCONIC',
  '2513912981': 'IFCSURFACE',
  '2515109513': 'IFCSTRUCTURALANALYSISMODEL',
  '2519244187': 'IFCPATH',
  '2525727697': 'IFCSTRUCTURALLOADSTATIC',
  '2529465313': 'IFCPARAMETERIZEDPROFILEDEF',
  '2533589738': 'IFCSLABTYPE',
  '2542286263': 'IFCCOMPLEXPROPERTY',
  '2543172580': 'IFCZSHAPEPROFILEDEF',
  '2551354335': 'IFCRELDECOMPOSES',
  '2552916305': 'IFCTEXTUREMAP',
  '2556980723': 'IFCFACE',
  '2559016684': 'IFCPREDEFINEDCURVEFONT',
  '2559216714': 'IFCCONSTRUCTIONRESOURCE',
  '2565941209': 'IFCRELDECLARES',
  '2571569899': 'IFCINDEXEDPOLYCURVE',
  '2572171363': 'IFCREINFORCINGBARTYPE',
  '2574617495': 'IFCCONSTRUCTIONRESOURCETYPE',
  '2581212453': 'IFCBOUNDINGBOX',
  '2590856083': 'IFCELEMENTCOMPONENTTYPE',
  '2595432518': 'IFCTRANSITIONCURVESEGMENT2D',
  '2597039031': 'IFCMEASUREWITHUNIT',
  '2598011224': 'IFCPROPERTY',
  '2601014836': 'IFCCURVE',
  '2603310189': 'IFCADVANCEDBREPWITHVOIDS',
  '2604431987': 'IFCLIGHTSOURCEDIRECTIONAL',
  '2609359061': 'IFCSLIPPAGECONNECTIONCONDITION',
  '2611217952': 'IFCCIRCLE',
  '2614616156': 'IFCCONNECTIONPOINTGEOMETRY',
  '2624227202': 'IFCLOCALPLACEMENT',
  '2629017746': 'IFCCURVEBOUNDEDSURFACE',
  '2635815018': 'IFCDISCRETEACCESSORYTYPE',
  '2636378356': 'IFCTEXTSTYLEFORDEFINEDFONT',
  '2652556860': 'IFCFIXEDREFERENCESWEPTAREASOLID',
  '2655187982': 'IFCLIBRARYINFORMATION',
  '2655215786': 'IFCRELASSOCIATESMATERIAL',
  '2665983363': 'IFCOPENSHELL',
  '2668620305': 'IFCSTRUCTURALLOADPLANARFORCE',
  '2674252688': 'IFCCABLEFITTINGTYPE',
  '2705031697': 'IFCARBITRARYPROFILEDEFWITHVOIDS',
  '2706460486': 'IFCGROUP',
  '2706606064': 'IFCSPATIALSTRUCTUREELEMENT',
  '2706619895': 'IFCMONETARYUNIT',
  '2713105998': 'IFCBOXEDHALFSPACE',
  '2713554722': 'IFCCONVERSIONBASEDUNITWITHOFFSET',
  '2715220739': 'IFCTRAPEZIUMPROFILEDEF',
  '2728634034': 'IFCRELASSOCIATESCONSTRAINT',
  '2732653382': 'IFCCONNECTIONSURFACEGEOMETRY',
  '2736907675': 'IFCBOOLEANRESULT',
  '2740243338': 'IFCAXIS2PLACEMENT3D',
  '2744685151': 'IFCPROCEDURE',
  '2752243245': 'IFCPROPERTYLISTVALUE',
  '2757150158': 'IFCSTRUCTURALCURVEREACTION',
  '2759199220': 'IFCVERTEXLOOP',
  '2769231204': 'IFCVIRTUALELEMENT',
  '2770003689': 'IFCRECTANGLEHOLLOWPROFILEDEF',
  '2771591690': 'IFCTASKTIMERECURRING',
  '2775532180': 'IFCPOLYGONALBOUNDEDHALFSPACE',
  '2777663545': 'IFCELEMENTARYSURFACE',
  '2778083089': 'IFCROUNDEDRECTANGLEPROFILEDEF',
  '2781568857': 'IFCROOFTYPE',
  '2798486643': 'IFCRECTANGULARPYRAMID',
  '2799835756': 'IFCVERTEX',
  '2802773753': 'IFCRELCOVERSSPACES',
  '2802850158': 'IFCPROFILEPROPERTIES',
  '2804161546': 'IFCEXTRUDEDAREASOLIDTAPERED',
  '2809605785': 'IFCSURFACEOFLINEAREXTRUSION',
  '2814081492': 'IFCENGINE',
  '2816379211': 'IFCCONDENSERTYPE',
  '2827207264': 'IFCFEATUREELEMENT',
  '2827736869': 'IFCCURVEBOUNDEDPLANE',
  '2830218821': 'IFCSTYLEMODEL',
  '2835456948': 'IFCELLIPSEPROFILEDEF',
  '2837617999': 'IFCOUTLETTYPE',
  '2839578677': 'IFCPOLYGONALFACESET',
  '2852063980': 'IFCMATERIALCONSTITUENTSET',
  '2853485674': 'IFCEXTERNALSPATIALSTRUCTUREELEMENT',
  '2857406711': 'IFCRELASSIGNSTOPRODUCT',
  '2859738748': 'IFCCONNECTIONGEOMETRY',
  '2874132201': 'IFCACTUATORTYPE',
  '2887950389': 'IFCBSPLINESURFACE',
  '2889183280': 'IFCCONVERSIONBASEDUNIT',
  '2893384427': 'IFCRAILINGTYPE',
  '2898889636': 'IFCCSHAPEPROFILEDEF',
  '2904328755': 'IFCPROJECTORDER',
  '2906023776': 'IFCBEAMSTANDARDCASE',
  '2914609552': 'IFCRESOURCE',
  '2916149573': 'IFCTRIANGULATEDFACESET',
  '2924175390': 'IFCPOLYLOOP',
  '2934153892': 'IFCSURFACEREINFORCEMENTAREA',
  '2937912522': 'IFCCIRCLEHOLLOWPROFILEDEF',
  '2938176219': 'IFCBURNER',
  '2943643501': 'IFCRESOURCEAPPROVALRELATIONSHIP',
  '2945172077': 'IFCPROCESS',
  '2949456006': 'IFCDIMENSIONALEXPONENTS',
  '2951183804': 'IFCCHILLERTYPE',
  '2954562838': 'IFCCOOLINGTOWERTYPE',
  '2963535650': 'IFCDOORLININGPROPERTIES',
  '2979338954': 'IFCBUILDINGELEMENTPART',
  '2986769608': 'IFCSTRUCTURALRESULTGROUP',
  '2998442950': 'IFCMIRROREDPROFILEDEF',
  '3001207471': 'IFCALARMTYPE',
  '3008276851': 'IFCFACESURFACE',
  '3008791417': 'IFCREPRESENTATIONITEM',
  '3009204131': 'IFCGRID',
  '3009222698': 'IFCFLOWTREATMENTDEVICETYPE',
  '3020489413': 'IFCIRREGULARTIMESERIESVALUE',
  '3021840470': 'IFCPHYSICALCOMPLEXQUANTITY',
  '3024970846': 'IFCRAMP',
  '3026737570': 'IFCTUBEBUNDLE',
  '3027567501': 'IFCREINFORCINGELEMENT',
  '3027962421': 'IFCSLABSTANDARDCASE',
  '3040386961': 'IFCDISTRIBUTIONFLOWELEMENT',
  '3041715199': 'IFCDISTRIBUTIONPORT',
  '3049322572': 'IFCSTYLEDREPRESENTATION',
  '3050246964': 'IFCCONTEXTDEPENDENTUNIT',
  '3053780830': 'IFCSANITARYTERMINAL',
  '3057273783': 'IFCMAPCONVERSION',
  '3071757647': 'IFCTSHAPEPROFILEDEF',
  '3079605661': 'IFCMATERIALPROFILESETUSAGE',
  '3079942009': 'IFCOPENINGSTANDARDCASE',
  '3081323446': 'IFCTENDONANCHORTYPE',
  '3087945054': 'IFCALARM',
  '3092502836': 'IFCLINESEGMENT2D',
  '3101149627': 'IFCTIMESERIES',
  '3101698114': 'IFCSURFACEFEATURE',
  '3112655638': 'IFCSTACKTERMINALTYPE',
  '3113134337': 'IFCINTERSECTIONCURVE',
  '3119450353': 'IFCPRESENTATIONSTYLE',
  '3124254112': 'IFCBUILDINGSTOREY',
  '3124975700': 'IFCTEXTLITERALWITHEXTENT',
  '3125803723': 'IFCAXIS2PLACEMENT2D',
  '3127900445': 'IFCSLABELEMENTEDCASE',
  '3132237377': 'IFCFLOWMOVINGDEVICE',
  '3136571912': 'IFCSTRUCTURALITEM',
  '3150382593': 'IFCCENTERLINEPROFILEDEF',
  '3171933400': 'IFCPLATE',
  '3174744832': 'IFCEVAPORATIVECOOLERTYPE',
  '3179687236': 'IFCUNITARYCONTROLELEMENTTYPE',
  '3181161470': 'IFCMEMBERTYPE',
  '3190031847': 'IFCRELCONNECTSPORTS',
  '3198132628': 'IFCFLOWFITTINGTYPE',
  '3199563722': 'IFCALIGNMENT2DHORIZONTALSEGMENT',
  '3200245327': 'IFCEXTERNALREFERENCE',
  '3203706013': 'IFCCAISSONFOUNDATIONTYPE',
  '3205830791': 'IFCDISTRIBUTIONSYSTEM',
  '3206491090': 'IFCTASKTYPE',
  '3207858831': 'IFCASYMMETRICISHAPEPROFILEDEF',
  '3219374653': 'IFCPROXY',
  '3221913625': 'IFCCOMMUNICATIONSAPPLIANCE',
  '3239324667': 'IFCALIGNMENT2DVERSEGLINE',
  '3242481149': 'IFCDOORSTANDARDCASE',
  '3242617779': 'IFCRELCONTAINEDINSPATIALSTRUCTURE',
  '3243963512': 'IFCREVOLVEDAREASOLIDTAPERED',
  '3252649465': 'IFCQUANTITYTIME',
  '3256556792': 'IFCDISTRIBUTIONELEMENTTYPE',
  '3264961684': 'IFCCOLOURSPECIFICATION',
  '3265635763': 'IFCMATERIALPROPERTIES',
  '3268803585': 'IFCRELNESTS',
  '3277789161': 'IFCELECTRICFLOWSTORAGEDEVICETYPE',
  '3283111854': 'IFCRAMPFLIGHT',
  '3285139300': 'IFCCOLOURRGBLIST',
  '3293443760': 'IFCCONTROL',
  '3293546465': 'IFCCABLECARRIERSEGMENTTYPE',
  '3295246426': 'IFCCREWRESOURCE',
  '3296154744': 'IFCCHIMNEY',
  '3299480353': 'IFCBUILDINGELEMENT',
  '3303107099': 'IFCSURFACESTYLELIGHTING',
  '3303938423': 'IFCMATERIALLAYERSET',
  '3304561284': 'IFCWINDOW',
  '3310460725': 'IFCELECTRICFLOWSTORAGEDEVICE',
  '3313531582': 'IFCVIBRATIONISOLATORTYPE',
  '3319311131': 'IFCHEATEXCHANGER',
  '3327091369': 'IFCPERMIT',
  '3331915920': 'IFCCARTESIANTRANSFORMATIONOPERATOR3D',
  '3342526732': 'IFCWORKSCHEDULE',
  '3352864051': 'IFCAIRTERMINALTYPE',
  '3355820592': 'IFCPOSTALADDRESS',
  '3357820518': 'IFCPROPERTYSETDEFINITION',
  '3367102660': 'IFCBOUNDARYFACECONDITION',
  '3368373690': 'IFCMETRIC',
  '3377609919': 'IFCREPRESENTATIONCONTEXT',
  '3379348081': 'IFCALIGNMENT2DVERSEGCIRCULARARC',
  '3388369263': 'IFCOFFSETCURVE2D',
  '3390157468': 'IFCEVAPORATORTYPE',
  '3404854881': 'IFCMATERIALPROFILESETUSAGETAPERING',
  '3406155212': 'IFCADVANCEDFACE',
  '3408363356': 'IFCSTRUCTURALLOADTEMPERATURE',
  '3413951693': 'IFCREGULARTIMESERIES',
  '3415622556': 'IFCFAN',
  '3419103109': 'IFCCONTEXT',
  '3420628829': 'IFCSOLARDEVICE',
  '3422422726': 'IFCLIGHTSOURCESPOT',
  '3426335179': 'IFCDEEPFOUNDATION',
  '3448662350': 'IFCGEOMETRICREPRESENTATIONCONTEXT',
  '3451746338': 'IFCRELSPACEBOUNDARY',
  '3452421091': 'IFCLIBRARYREFERENCE',
  '3454111270': 'IFCRECTANGULARTRIMMEDSURFACE',
  '3460190687': 'IFCASSET',
  '3473067441': 'IFCTASK',
  '3478079324': 'IFCSTRUCTURALLOADCONFIGURATION',
  '3486308946': 'IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM',
  '3493046030': 'IFCGEOGRAPHICELEMENT',
  '3495092785': 'IFCCURTAINWALL',
  '3505215534': 'IFCOFFSETCURVE3D',
  '3508470533': 'IFCFLOWTREATMENTDEVICE',
  '3510044353': 'IFCCURVESTYLEFONTPATTERN',
  '3512223829': 'IFCWALLSTANDARDCASE',
  '3512275521': 'IFCALIGNMENTCURVE',
  '3518393246': 'IFCDUCTSEGMENT',
  '3521284610': 'IFCPROPERTYTEMPLATE',
  '3523091289': 'IFCRELSPACEBOUNDARY1STLEVEL',
  '3544373492': 'IFCSTRUCTURALACTIVITY',
  '3548104201': 'IFCEXTERNALLYDEFINEDTEXTFONT',
  '3566463478': 'IFCPERMEABLECOVERINGPROPERTIES',
  '3570813810': 'IFCINDEXEDCOLOURMAP',
  '3571504051': 'IFCCOMPRESSOR',
  '3588315303': 'IFCOPENINGELEMENT',
  '3590301190': 'IFCGEOMETRICSET',
  '3593883385': 'IFCTRIMMEDCURVE',
  '3611470254': 'IFCTEXTUREVERTEXLIST',
  '3612865200': 'IFCPIPESEGMENT',
  '3615266464': 'IFCRECTANGLEPROFILEDEF',
  '3626867408': 'IFCRIGHTCIRCULARCYLINDER',
  '3630933823': 'IFCACTORROLE',
  '3632507154': 'IFCDERIVEDPROFILEDEF',
  '3640358203': 'IFCCOOLINGTOWER',
  '3649129432': 'IFCBOOLEANCLIPPINGRESULT',
  '3649138523': 'IFCBEARINGTYPE',
  '3650150729': 'IFCPROPERTYSINGLEVALUE',
  '3651124850': 'IFCPROJECTIONELEMENT',
  '3657597509': 'IFCSTRUCTURALSURFACEACTION',
  '3663046924': 'IFCTENDONCONDUIT',
  '3663146110': 'IFCSIMPLEPROPERTYTEMPLATE',
  '3678494232': 'IFCRELCONNECTSWITHREALIZINGELEMENTS',
  '3689010777': 'IFCSTRUCTURALREACTION',
  '3692461612': 'IFCSIMPLEPROPERTY',
  '3694346114': 'IFCOUTLET',
  '3698973494': 'IFCTYPERESOURCE',
  '3701648758': 'IFCOBJECTPLACEMENT',
  '3708119000': 'IFCMATERIALCONSTITUENT',
  '3710013099': 'IFCPROPERTYENUMERATION',
  '3724593414': 'IFCPOLYLINE',
  '3727388367': 'IFCPREDEFINEDITEM',
  '3732053477': 'IFCDOCUMENTREFERENCE',
  '3732776249': 'IFCCOMPOSITECURVE',
  '3736923433': 'IFCTYPEPROCESS',
  '3737207727': 'IFCFACETEDBREPWITHVOIDS',
  '3740093272': 'IFCPORT',
  '3741457305': 'IFCIRREGULARTIMESERIES',
  '3747195512': 'IFCEVAPORATIVECOOLER',
  '3749851601': 'IFCCARTESIANTRANSFORMATIONOPERATOR2D',
  '3758799889': 'IFCCABLECARRIERSEGMENT',
  '3760055223': 'IFCDUCTSEGMENTTYPE',
  '3765753017': 'IFCREINFORCEMENTDEFINITIONPROPERTIES',
  '3778827333': 'IFCPREDEFINEDPROPERTIES',
  '3798115385': 'IFCARBITRARYCLOSEDPROFILEDEF',
  '3800577675': 'IFCCURVESTYLE',
  '3812236995': 'IFCSPACETYPE',
  '3815607619': 'IFCFLOWMETERTYPE',
  '3821786052': 'IFCACTIONREQUEST',
  '3824725483': 'IFCTENDON',
  '3825984169': 'IFCTRANSFORMER',
  '3827777499': 'IFCLABORRESOURCE',
  '3840914261': 'IFCRELASSOCIATESLIBRARY',
  '3843373140': 'IFCPROJECTEDCRS',
  '3849074793': 'IFCDISTRIBUTIONFLOWELEMENTTYPE',
  '3850581409': 'IFCCOMPRESSORTYPE',
  '3856911033': 'IFCSPACE',
  '3869604511': 'IFCAPPROVALRELATIONSHIP',
  '3875453745': 'IFCCOMPLEXPROPERTYTEMPLATE',
  '3888040117': 'IFCOBJECT',
  '3893378262': 'IFCSPATIALSTRUCTUREELEMENTTYPE',
  '3893394355': 'IFCCIVILELEMENTTYPE',
  '3895139033': 'IFCCOSTITEM',
  '3898045240': 'IFCCONSTRUCTIONEQUIPMENTRESOURCE',
  '3900360178': 'IFCEDGE',
  '3902619387': 'IFCCHILLER',
  '3905492369': 'IFCIMAGETEXTURE',
  '3907093117': 'IFCFLOWCONTROLLERTYPE',
  '3915482550': 'IFCRECURRENCEPATTERN',
  '3939117080': 'IFCRELASSIGNS',
  '3940055652': 'IFCRELFILLSELEMENT',
  '3945020480': 'IFCRELCONNECTSPATHELEMENTS',
  '3946677679': 'IFCINTERCEPTORTYPE',
  '3956297820': 'IFCVIBRATIONDAMPERTYPE',
  '3958052878': 'IFCSTYLEDITEM',
  '3958567839': 'IFCPROFILEDEF',
  '3961806047': 'IFCDAMPERTYPE',
  '3967405729': 'IFCPREDEFINEDPROPERTYSET',
  '3979015343': 'IFCSTRUCTURALSURFACEMEMBER',
  '3982875396': 'IFCSHAPEMODEL',
  '3999819293': 'IFCCAISSONFOUNDATION',
  '4006246654': 'IFCDRAUGHTINGPREDEFINEDCURVEFONT',
  '4009809668': 'IFCWINDOWTYPE',
  '4015995234': 'IFCSPHERICALSURFACE',
  '4017108033': 'IFCPLATETYPE',
  '4021432810': 'IFCREFERENT',
  '4022376103': 'IFCPOINTONCURVE',
  '4024345920': 'IFCEVENTTYPE',
  '4031249490': 'IFCBUILDING',
  '4037036970': 'IFCBOUNDARYCONDITION',
  '4037862832': 'IFCFLOWINSTRUMENTTYPE',
  '4074379575': 'IFCDAMPER',
  '4074543187': 'IFCSHADINGDEVICETYPE',
  '4086658281': 'IFCSENSOR',
  '4088093105': 'IFCWORKCALENDAR',
  '4095422895': 'IFCGEOGRAPHICELEMENTTYPE',
  '4095574036': 'IFCRELASSOCIATESAPPROVAL',
  '4095615324': 'IFCSUBCONTRACTRESOURCETYPE',
  '4097777520': 'IFCSITE',
  '4105962743': 'IFCCONSTRUCTIONMATERIALRESOURCETYPE',
  '4122056220': 'IFCRELSEQUENCE',
  '4123344466': 'IFCELEMENTASSEMBLY',
  '4124623270': 'IFCSHELLBASEDSURFACEMODEL',
  '4124788165': 'IFCSURFACEOFREVOLUTION',
  '4136498852': 'IFCCOOLEDBEAM',
  '4142052618': 'IFCGEOMETRICREPRESENTATIONSUBCONTEXT',
  '4143007308': 'IFCOCCUPANT',
  '4148101412': 'IFCEVENT',
  '4156078855': 'IFCWALLELEMENTEDCASE',
  '4158566097': 'IFCRIGHTCIRCULARCONE',
  '4162380809': 'IFCLIGHTDISTRIBUTIONDATA',
  '4165799628': 'IFCSECTIONREINFORCEMENTPROPERTIES',
  '4166981789': 'IFCPROPERTYENUMERATEDVALUE',
  '4175244083': 'IFCINTERCEPTOR',
  '4182860854': 'IFCBOUNDEDSURFACE',
  '4186316022': 'IFCRELDEFINESBYPROPERTIES',
  '4196446775': 'IFCBEARING',
  '4201705270': 'IFCRELCONNECTSPORTTOELEMENT',
  '4207607924': 'IFCVALVE',
  '4208778838': 'IFCPRODUCT',
  '4217484030': 'IFCCABLESEGMENT',
  '4218914973': 'IFCWORKPLAN',
  '4219587988': 'IFCFAILURECONNECTIONCONDITION',
  '4222183408': 'IFCFIRESUPPRESSIONTERMINALTYPE',
  '4231323485': 'IFCPIPESEGMENTTYPE',
  '4237592921': 'IFCWASTETERMINAL',
  '4238390223': 'IFCFURNISHINGELEMENTTYPE',
  '4240577450': 'IFCSHAPEREPRESENTATION',
  '4243806635': 'IFCSTRUCTURALCURVECONNECTION',
  '4251960020': 'IFCORGANIZATION',
  '4252922144': 'IFCSTAIRFLIGHT',
  '4261334040': 'IFCAXIS1PLACEMENT',
  '4263986512': 'IFCALIGNMENT2DVERSEGPARABOLICARC',
  '4266656042': 'IFCLIGHTSOURCEGONIOMETRIC',
  '4278684876': 'IFCRELASSIGNSTOPROCESS',
  '4278956645': 'IFCFLOWFITTING',
  '4282788508': 'IFCTEXTLITERAL',
  '4288193352': 'IFCACTUATOR',
  '4288270099': 'IFCJUNCTIONBOXTYPE',
  '4292641817': 'IFCUNITARYEQUIPMENT',
  '4294318154': 'IFCEXTERNALINFORMATION'
}
