
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import Input from '@/components/shared/Input.vue'
import axios from 'axios'
import { debounce } from '@mapbox/search-js-core'
import IconRoad from '@/assets/icons/road.svg'
import { directive as onClickaway } from 'vue-clickaway'

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: debounce(target[prop], delay)
    }
  }
}

@Component({
  components: { Input, IconRoad },
  directives: { onClickaway }
})
export default class MapboxSearchTool extends Vue {
  @Prop() map!: any
  @Prop() pickedAddress!: string

  private address: string | null = null

  private debounce: any = null
  private showResults: boolean = false

  private results: {
    place_name_main: string
    place_name_sub: string
    center: [number, number]
  }[] = []

  private debounceSearch(search: string) {
    clearTimeout(this.debounce)

    this.debounce = setTimeout(() => {
      this.address = search.replaceAll(' ', '%20') // todo: cambiarlo a safe-url-encoded
      if (this.address != null && this.address.length > 3)
        this.submitGeolocation(search)
    }, 600)

    this.showResults = true
  }

  private async submitGeolocation(search: string) {
    const {
      data: { features }
    } = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
    )

    this.results = features.map((f: { place_name: any; center: any }) => {
      const { place_name, center } = f

      const place_name_main = place_name.substr(0, place_name.indexOf(','))
      const place_name_sub = place_name.substr(place_name.indexOf(',') + 1)

      return { place_name_main, place_name_sub, center }
    })
  }

  private changeLocation(result: {
    place_name_main: string | null
    place_name_sub: string | null
    center: any
  }) {
    this.address = result.place_name_main
    this.$root.$emit(
      'change-location',
      result.center,
      `${result.place_name_main} ${result.place_name_sub}`
    )

    this.closeResults()
  }

  private confirmChoice() {
    this.$root.$emit('confirm-location')
    this.closeSearch()
  }

  private closeResults() {
    this.showResults = false
  }

  @Emit()
  closeSearch() {}

  @Watch('pickedAddress')
  onPickedAddressChange() {
    if (this.pickedAddress) {
      this.submitGeolocation(this.pickedAddress)
    }
  }
}
