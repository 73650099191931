import IconJoin from '@/assets/icons/join.svg'
import IconSplit from '@/assets/icons/split.svg'
import IconAttributes from '@/assets/icons/attributes.svg'
import IconRelocation from '@/assets/icons/relocation.svg'

export default [
  {
    title: 'Join IFC',
    subtitle:
      'Join IFC and obtain an all-in-one model with them. Quick & easy!',
    icon: IconJoin
  },
  {
    title: 'Split IFC',
    subtitle: 'Split your IFC and obtain a model with only the needed data',
    icon: IconSplit
  },
  {
    title: 'Get Attributes',
    subtitle: 'Extract all data from your IFC with just one click',
    icon: IconAttributes
  },
  {
    title: 'Relocate IFC',
    subtitle: 'Correct your IFC location through a no-pain easy way',
    icon: IconRelocation
  }
]
