import { DoubleSide } from 'three'
import Store from '@/store/modules/Viewer'
import { Components, ScreenCuller, FragmentEdges } from 'openbim-components'

export default (
  items: any,
  viewer: Components,
  culler: ScreenCuller
  // fragments: FragmentManager,
  // isFromMapbox: boolean = false
) => {
  const check = localStorage.getItem('I♥IFC-lowViewer')

  const edges = new FragmentEdges(viewer, culler)

  for (const item of items) {
    viewer.meshes.push(item.mesh)
    culler.add(item.mesh)

    if (check) {
      edges.add(item)
      item.mesh.castShadow = true
      item.mesh.receiveShadow = true
    }
  }

  culler.needsUpdate = true
  Store.setCuller(culler)
}

export const formatMeshes = (viewer: Components, items: any[]) => {
  // const edges = new FragmentOutliner(
  //   viewer,
  //   fragments,
  //   viewer.renderer as PostproductionRenderer
  // )

  // const edges = new FragmentEdges(viewer)

  for (const item of items) {
    item.mesh.castShadow = true
    item.mesh.receiveShadow = true

    for (const mat of item.mesh.material) {
      mat.side = DoubleSide
    }
  }

  // Store.setEdges(edges)
}
