
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconCaret from '@/assets/icons/caret.svg'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import Store from '@/store/modules/Viewer'
import AttributesNoneSelected from '@/components/ActionsViewer/AttributesTree/AttributesNoneSelected.vue'
import AttributesPartiallySelected from '@/components/ActionsViewer/AttributesTree/AttributesPartiallySelected.vue'
import AttributesAllSelected from '@/components/ActionsViewer/AttributesTree/AttributesAllSelected.vue'

@Component({
  components: { IconCaret, IconCaretDown }
})
export default class AttributesItem extends Vue {
  @Prop() item!: any
  @Prop() isStart!: boolean

  private component: Vue.Component = AttributesItem
  private expand: boolean = false
  private items: any[] = []
  private itemType: string = ''
  private checkboxItem: string = ''

  private noneSelected: Vue.Component = AttributesNoneSelected
  private partiallySelected: Vue.Component = AttributesPartiallySelected
  private allSelected: Vue.Component = AttributesAllSelected

  private get isExpanded(): Vue.Component {
    return this.expand ? IconCaretDown : IconCaret
  }

  private get flatHasChildren(): boolean {
    return typeof this.item === 'string'
  }

  mounted() {
    this.setItem()
  }

  private get selectStatus(): Vue.Component {
    if (typeof this.item === 'string') {
      if (this.item === 'MODEL') {
        if (Store.selectedExpressIDs.length) {
          const ids = []

          for (const s in Store.spatialStructure) {
            for (const e in Store.spatialStructure[s]) {
              ids.push(...Store.spatialStructure[s][e])
            }
          }

          if (ids.every(i => Store.selectedExpressIDs.includes(i))) {
            return this.allSelected
          } else {
            return this.partiallySelected
          }
        } else {
          return this.noneSelected
        }
      } else {
        if (!Store.selectedExpressIDs.length) {
          return this.noneSelected
        } else {
          const expressIds = []

          for (const i in Store.spatialStructure[this.item]) {
            expressIds.push(
              ...Object.values(Store.spatialStructure[this.item][i])
            )
          }

          if (expressIds.every(e => Store.selectedExpressIDs.includes(e))) {
            return this.allSelected
          } else if (
            expressIds.every(e => !Store.selectedExpressIDs.includes(e))
          ) {
            return this.noneSelected
          } else {
            return this.partiallySelected
          }
        }
      }
    } else {
      // @ts-ignore
      const expressIds: any[] = Object.values(this.item)[0]

      if (expressIds.every(e => Store.selectedExpressIDs.includes(e))) {
        return this.allSelected
      } else {
        return this.noneSelected
      }
    }
  }

  private setItem() {
    const items = []

    if (this.item === 'MODEL') {
      for (const s in Store.spatialStructure) {
        items.push(s)
      }
    } else {
      for (const s in Store.spatialStructure[this.item]) {
        items.push({ [s]: Store.spatialStructure[this.item][s] })
      }
    }

    this.items = items
  }

  private extendProcess() {
    if (typeof this.item === 'string') {
      this.expand = !this.expand
    }
  }
}
