
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

import {
  DepthTexture,
  DirectionalLight,
  DoubleSide,
  MathUtils,
  Matrix4,
  MeshBasicMaterial,
  PCFSoftShadowMap,
  Scene,
  Vector3,
  WebGLRenderTarget
} from 'three'

import Store from '@/store/modules/Viewer'
import processCsv from '@/controllers/processCsv'
import IFCLoader from '@/assets/icons/IFC.svg'
import BetaTesterPopup from '@/components/ActionsViewer/BetaTesterPopup.vue'
import LoadFile from '@/components/shared/LoadFile.vue'
import AttributesTree from '@/components/ActionsViewer/AttributesTree/AttributesTree.vue'
import IconPlus from '@/assets/icons/plus.svg'
import Files from '@/components/ActionsViewer/Files.vue'
import joinIFC from '@/controllers/joinIFC'
import downloadFile from '@/helpers/downloadFile'
import expendOneCredit from '@/helpers/expendOneCredit'
import IconFullScreen from '@/assets/icons/expand.svg'
import IconMinimize from '@/assets/icons/compress.svg'
import IconCenter from '@/assets/icons/magnifying-glass-minus.svg'
import IconAO from '@/assets/icons/AO.svg'
import IconSun from '@/assets/icons/sun.svg'
import IconAzimuth from '@/assets/icons/horizontal.svg'
import IconElevation from '@/assets/icons/vertical.svg'
// @ts-ignore
import Worker from 'worker-loader!../../workers/getAttributes'
import createBook from '@/controllers/xlsxExport/createBook'
import LoadingBar from '@/components/ActionsViewer/LoadingBar.vue'
import { Sky } from 'three/examples/jsm/objects/Sky'
import mountViewer from '@/controllers/mountViewer'
import {
  Components,
  FragmentHighlighter,
  SimpleCamera
} from 'openbim-components'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import parseIfcFile from '@/controllers/parseIfcFile'
import allTypes from '@/config/allTypes'

const worker = new Worker()

let composer: any
let basePass: any
let saoPass: any
let outlinePass: any
let fxaaPass: any

// @ts-ignore
@Component({
  components: {
    LoadingBar,
    Files,
    AttributesTree,
    BetaTesterPopup,
    IFCLoader,
    LoadFile,
    IconAO,
    IconAzimuth,
    IconElevation,
    IconPlus,
    IconFullScreen,
    IconMinimize,
    IconSun,
    IconCenter
  },
  directives: { onClickaway }
})
export default class Viewer extends Vue {
  @Prop() filesList!: File[]
  @Prop() isLoadFile!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop() showBetaTesterModal!: boolean
  @Prop() showTrees!: { spatialTree: boolean; attributesTree: boolean }

  private viewer: Components | any = []
  // private spatialStructure: any = null
  private textFiles: string[] = []
  private loaderIn: boolean = false
  private showDropMessage: boolean = false
  private dragClass: string = 'bg-red-500'
  private timedText: string = ''
  private greeting: string = `Welcome to I❤IFC. Generate your AI render!`
  private counter: number = 0
  private showLastChar: boolean = false
  private showIndication: boolean = true
  private isFullScreen: boolean = false
  private measures: { width: number; height: number } = { width: 0, height: 0 }
  private counterText: string = ''
  private defCounterText: string = ''
  private isShowingPanel: boolean = false
  private totalToProcess: number = 0
  private current: number = 0
  private isLoading: boolean = false
  private inputAzimuth: number = 0
  private inputElevation: number = 10
  private sky!: Sky
  private light!: DirectionalLight
  private sun!: Vector3
  private defaultLightsUuids: string[] = []
  private camera!: SimpleCamera
  private scene!: Scene
  private fragments: any = null
  private renderer: any = null
  private loadedModel: boolean = false
  private loader: any = null
  private transparentized: boolean = true
  private highlighter: any = null
  private culler: any = null
  private coordinationBase: any = null
  private coordinationSuccessive: any = null

  private mode: any = {
    postproduction: false,
    light: false,
    floor: false,
    horizon: false,
    lightHelper: false,
    gridHelper: false
  }

  private uuids: any = {
    light: { id: '', done: false },
    floor: { id: '', done: false },
    horizon: { id: '', done: false },
    lightHelper: { id: '', done: false },
    gridHelper: { id: '', done: false }
  }

  private get toCsvFile(): File | null {
    return Store.fileForCsvDownload
  }

  private get userId(): string {
    return Store.userId
  }

  private get userLearned(): boolean {
    return Store.userLearned
  }

  private get activeAction(): string {
    return Store.activeAction
  }

  mounted() {
    this.setViewer()
    if (this.userId) this.setTimedText()

    this.$root.$on('loading-status', (status: boolean) => {
      this.loaderIn = status
    })

    this.$root.$on('close-indication', () => {
      this.showIndication = false
    })

    this.$root.$on(
      'set-store-snapshot',
      (
        prompt: string,
        imageStrength: number,
        steps: number,
        guidance: number
      ) => {
        this.loaderIn = true
        // this.sendSnapshotToAI(prompt, imageStrength, steps, guidance)

        this.sendSnapshotToAI()
      }
    )

    this.$root.$on('download-xlsx', async () => {
      this.isLoading = true

      setTimeout(async () => {
        await this.downloadXlsxWorkerWrite()
      }, 500)
    })

    this.$root.$on('center-model', () => {
      this.centerModel()
    })

    const tag = document.getElementById('load-status')

    if (tag) {
      this.$root.$on('split-complete', (counterText: string) => {
        tag.innerText = counterText
        tag.innerHTML = counterText
      })
    }

    worker.onmessage = ({ data }: any) => {
      const { dataProcessed, totalToProcess, currentProgress } = data

      if (totalToProcess) {
        this.totalToProcess = totalToProcess
      } else if (currentProgress) {
        this.current = currentProgress
      } else if (dataProcessed) {
        const { genericXlsxRows, psetsForXlsx } = dataProcessed

        Store.setLoader('Writing Xlsx Books')
        this.totalToProcess = 0
        this.current = 0

        setTimeout(async () => {
          createBook(genericXlsxRows, psetsForXlsx)
          this.$toasted.success('Xlsx exported successfully')
          if (Store.selectedIfcClasses.length > 2) {
            await expendOneCredit(3, false, '')
          } else {
            await expendOneCredit(53, true, '')
          }

          this.isLoading = false
        }, 100)
      }
    }

    this.$root.$on('sendClickShot', () => {
      this.screenshot()
    })
  }

  private async sendSnapshotToAI() { // guidance: number // steps: number, // imageStrength: number, // prompt: string,
    try {
      this.applyAoScene()

      this.$toasted.success('Processing Snapshot IA Render')

      const image = this.viewer.renderer._renderer.domElement.toDataURL()

      // const { height, width } = this.viewer.renderer._renderer.domElement

      this.disableComposer()

      const {
        data: { configShot }
      } = await this.$apollo.mutate({
        variables: {
          image
          // prompt,
          // height,
          // width,
          // imageStrength,
          // steps,
          // guidance
        },
        mutation: require('@/graphql/mutations/config-shot.graphql')
      })

      if (configShot !== '') {
        // this.$root.$emit('set-result-base64', configShot)

        setTimeout(() => {
          this.$root.$emit('link-processing-ai', configShot)
        }, 10 * 1000)
      } else {
        this.$root.$emit('link-reset', configShot)
        this.$toasted.error('There was some problem generating your AI-Render')
        this.loaderIn = false
      }
    } catch {
      this.$toasted.error('There was some errors during IA render')
      this.$root.$emit('restore-ia-submit')
      this.loaderIn = false
    } finally {
      this.loaderIn = false
    }
  }

  private setTimedText() {
    const sign = '|'

    this.timedText = this.timedText.replace(sign, '')

    this.timedText +=
      this.greeting[this.counter] === '.'
        ? '.\n\r'
        : this.greeting[this.counter] + sign

    if (this.counter++ < this.greeting.length - 1) {
      setTimeout(this.setTimedText, 75)
    } else {
      this.timedText = this.timedText.replace(sign, '')

      this.finishTimedText()
    }
  }

  private finishTimedText() {
    setInterval(() => {
      this.showLastChar = !this.showLastChar
    }, 500)
  }

  private get fileForViewer(): File | null | Blob {
    return Store.fileForViewer
  }

  private checkCoordinates(coordinationMatrix: any[]) {
    if (this.filesList.length === 1) {
      this.coordinationBase = coordinationMatrix
    } else {
      this.coordinationSuccessive = coordinationMatrix
    }
  }

  private applyCoordinates(model: any) {
    if (this.filesList.length > 1) {
      // @ts-ignore
      const aMatrix = new Matrix4().fromArray(this.coordinationBase)
      const bMatrix = new Matrix4()
        // @ts-ignore
        .fromArray(this.coordinationSuccessive)
        .invert()

      model.items.forEach((m: any) => {
        m.mesh.applyMatrix4(bMatrix)
        m.mesh.applyMatrix4(aMatrix)
      })

      const check = localStorage.getItem('I♥IFC-lowViewer')

      if (check === 'false') {
        for (const m in Store.edges._list) {
          Store.edges._list[m].applyMatrix4(bMatrix)
          Store.edges._list[m].applyMatrix4(aMatrix)
        }
      }

      // Store.culler.meshes.forEach((value: any) => {
      //   value.applyMatrix4(bMatrix)
      //   value.applyMatrix4(aMatrix)
      // })

      // Store.culler.needsUpdate = true
    }
  }

  // private async associateModelsIds() {
  //   if (this.viewer) {
  //     // @ts-ignore
  //     const models = Array.from(this.viewer.context.items.ifcModels)
  //
  //     const actualModels = models.splice(this.lastUploadedModelIndex)
  //
  //     const associatedIds = []
  //
  //     for (const model of actualModels) {
  //       associatedIds.push(model.modelID)
  //       this.lastUploadedModelIndex++
  //     }
  //
  //     Store.addModelToList({
  //       title: '',
  //       associatedIds
  //     })
  //
  //     this.modelWasAdded = ''
  //   }
  // }

  private async joinModels() {
    this.loaderIn = true

    await this.join()
  }

  private join() {
    return new Promise(async _ => {
      if (this.activeAction === 'Join IFC') {
        for (const file in this.filesList) {
          if (typeof this.filesList[file] === 'object') {
            this.textFiles.push(await this.filesList[file].text())
          }
        }

        const newFile = await joinIFC(this.textFiles)

        if (newFile) {
          if (this.filesList.length > 2 && Store.userCredits > 0) {
            this.downloadFile(newFile)

            this.$toasted.success('JoinIFC successfully')

            await expendOneCredit(1, false, '')
          } else if (this.filesList.length === 2) {
            this.downloadFile(newFile)

            this.$toasted.success('JoinIFC successfully')

            await expendOneCredit(51, true, '')
          } else if (this.filesList.length > 2 && Store.userCredits === 0) {
            this.$toasted.error('You need IfcHearts to join more than 2 models')
          }

          this.loaderIn = false
          this.coordinationBase = null
          this.coordinationSuccessive = null
        }
      }
    })
  }

  private downloadFile(file: string) {
    /*const newFile = */ downloadFile(file)
    this.finishDownload()
    // this.assignFilesList(new File([newFile], 'I♥IFC_join.ifc'))
  }

  private dropFile(event: DragEvent) {
    if (Store.userId) {
      const files = []

      // @ts-ignore
      if (event.dataTransfer.items) {
        // @ts-ignore
        for (const item of event.dataTransfer.items) {
          if (item.kind === 'file') {
            files.push(item.getAsFile())
          }
        }
      } else {
        // @ts-ignore
        for (const file of event.dataTransfer.files) {
          files.push(file)
        }
      }

      this.$root.$emit('drop-files', files)
      this.removeDrag(event)
      this.showDropMessage = false
      this.dragClass = 'bg-red-500'
    }
  }

  private removeDrag(event: any) {
    if (event.dataTransfer.items) {
      event.dataTransfer.items.clear()
    } else {
      event.dataTransfer.clearData()
    }
  }

  private get selectedExpressIDs(): number[] {
    return Store.selectedExpressIDs
  }

  private selectGeometries() {
    if (this.transparentized) this.transparentized = false

    let groups: any = {}

    for (const expressId of this.selectedExpressIDs) {
      const group = this.fragments.groups[0].items

      for (const g in group) {
        if (group[g].items.includes(expressId)) {
          const meshUuid = group[g].mesh.uuid

          if (groups[meshUuid] !== undefined) {
            groups[meshUuid].push(expressId)
          } else {
            groups = { ...groups, [meshUuid]: [expressId] }
          }
        }
      }
    }

    this.highlighter.highlightByID('highlight', groups, true)
    this.highlighter.update()
  }

  private get selectedIfcClasses(): string[] {
    return Store.selectedIfcClasses
  }

  private async selectGeometriesPerClasses() {
    const allTypesArr: any[] = []

    for (const cl of this.selectedIfcClasses) {
      for (const c in allTypes) {
        // @ts-ignore
        if (allTypes[c] === cl) {
          allTypesArr.push(c)
          break
        }
      }
    }

    const expressIds = []

    for (const p in Store.properties) {
      if (p !== 'coordinationMatrix') {
        if (allTypesArr.includes(Store.properties[p].type.toString())) {
          expressIds.push(Store.properties[p].expressID.toString())
        }
      }
    }

    let groups: { [p: string]: string[] } = {}

    for (const id of expressIds) {
      for (const fragment of this.fragments.groups[0].items) {
        if (fragment.items.includes(id)) {
          if (!groups[fragment.mesh.uuid]) {
            groups = { ...groups, [fragment.mesh.uuid]: [id] }
          } else {
            groups[fragment.mesh.uuid].push(id)
          }
        }
      }
    }

    if (this.transparentized) this.transparentized = false

    setTimeout(() => {
      this.highlighter.highlightByID('highlight', groups, true)
      this.highlighter.update()
    }, 50)
  }

  private handleDrop() {
    if (this.userId) {
      this.dragClass = 'bg-tahiti-500 bg-opacity-50'
      this.showDropMessage = true
    } else {
      this.$toasted.error('Only available for user-testers')
    }
  }

  private async downloadXlsxWorkerWrite() {
    worker.postMessage({
      xlsxBookEntireModel: { properties: Store.properties },
      allTypes: Store.allTypes,
      selected: Store.selectedIfcClasses.length
        ? Store.selectedIfcClasses
        : Store.selectedExpressIDs.length
        ? Store.selectedExpressIDs
        : []
    })
  }

  private centerModel() {
    this.viewer.camera.fit()
  }

  private async screenshot() {
    const link = document.createElement('a')

    this.applyAoScene()

    link.href = this.viewer.renderer._renderer.domElement.toDataURL()
    link.download = 'I♥IFC_screenshot.png'
    link.click()

    this.disableComposer()

    await expendOneCredit(6, false, '')
  }

  private disableComposer() {
    composer.removePass(basePass)
    composer.removePass(saoPass)
    composer.removePass(outlinePass)
    composer.removePass(fxaaPass)

    this.viewer.renderer._renderer.resetState()
  }

  private applyAoScene() {
    const renderer = this.viewer.renderer.get()

    basePass = new RenderPass(
      this.viewer.scene.get(),
      this.viewer.camera.activeCamera
    )

    const depthTexture = new DepthTexture(window.innerWidth, window.innerHeight)

    const renderTarget = new WebGLRenderTarget(
      window.innerWidth,
      window.innerHeight,
      {
        depthTexture,
        depthBuffer: true
      }
    )

    composer = new EffectComposer(renderer)
    composer.setSize(window.innerWidth, window.innerHeight)

    const rendererTargetClone = renderTarget.clone()

    for (const pass of composer.passes) {
      pass.render(renderer, renderTarget, rendererTargetClone, 1, false)
    }

    composer.render()

    this.viewer.update()
  }

  private horizon() {
    for (const child of this.scene.children) {
      if (this.defaultLightsUuids.includes(child.uuid)) {
        child.visible = false
      }
    }

    this.sky = new Sky()
    this.sky.scale.setScalar(450000)

    this.uuids.horizon.id = this.sky.uuid

    this.scene.add(this.sky)
    this.uuids.horizon.done = true

    this.sun = new Vector3()

    const effectController = {
      turbidity: this.inputElevation > 0 ? 10 / this.inputElevation : 10, // 10
      rayleigh: this.inputElevation > 0 ? 75 / this.inputElevation / 7 : 0.15, // 3 valores bajos para modo noche
      mieCoefficient: 0.005, // 0.005
      mieDirectionalG: this.inputElevation > 0 ? this.inputElevation : 0.5, //
      elevation: this.inputElevation,
      azimuth: this.inputAzimuth,
      exposure: 0.5 // this.viewer.context.renderer.renderer.toneMappingExposure
    }

    const uniforms = this.sky.material.uniforms
    uniforms['turbidity'].value = effectController.turbidity
    uniforms['rayleigh'].value = effectController.rayleigh
    uniforms['mieCoefficient'].value = effectController.mieCoefficient
    uniforms['mieDirectionalG'].value = effectController.mieDirectionalG

    const phi = MathUtils.degToRad(90 - effectController.elevation)
    const theta = MathUtils.degToRad(effectController.azimuth)

    this.sun.setFromSphericalCoords(10, phi, theta)

    uniforms['sunPosition'].value.copy(this.sun)

    this.light = new DirectionalLight('#fffcf2')
    this.light.intensity = 0.75
    this.light.castShadow = true

    this.uuids.light.id = this.light.uuid
    this.uuids.light.done = true

    this.light.position.setFromSphericalCoords(200, phi, theta)

    this.light.shadow.bias = -0.001
    this.light.shadow.normalBias = -0.001
    this.light.shadow.blurSamples = 512
    // this.light.shadow.radius = 0.001

    this.light.shadow.mapSize.width = 100000
    this.light.shadow.mapSize.height = 100000
    this.light.shadow.camera.near = 0.1
    this.light.shadow.camera.far = 500.0
    this.light.shadow.camera.near = 0.5
    this.light.shadow.camera.far = 500.0
    this.light.shadow.camera.left = 250
    this.light.shadow.camera.right = -250
    this.light.shadow.camera.top = 250
    this.light.shadow.camera.bottom = -250

    this.viewer.renderer._renderer.shadowMap.enabled = true
    this.viewer.renderer._renderer.shadowMap.type = PCFSoftShadowMap

    this.scene.add(this.light)

    this.viewer.renderer._renderer.toneMappingExposure =
      effectController.exposure
  }

  private setFullScreen() {
    Store.setStylePanel(true)
    // this.renderer.postproduction.active = false
    this.setMeasures()
    this.isFullScreen = true

    // setTimeout(() => {
    //   this.renderer.postproduction.active = true
    // }, 200)
  }

  private closeFullScreen() {
    Store.setStylePanel(false)
    // this.renderer.postproduction.active = false

    this.setMeasures()

    this.isFullScreen = false

    // setTimeout(() => {
    //   this.renderer.postproduction.active = true
    // }, 200)
  }

  private setSunlightConfig() {
    const { uniforms } = this.sky.material

    const phi = MathUtils.degToRad(90 - this.inputElevation)
    const theta = MathUtils.degToRad(this.inputAzimuth)

    this.sun.setFromSphericalCoords(10, phi, theta)

    uniforms['sunPosition'].value.copy(this.sun)

    this.light.position.setFromSphericalCoords(200, phi, theta)

    this.uuids.light.id = this.light.uuid
    this.uuids.light.done = true
  }

  private setMeasures() {
    const container = document.getElementById('viewer') as HTMLElement

    this.measures = {
      height: container.clientHeight,
      width: container.clientWidth
    }
  }

  private async setViewer() {
    const { viewer, fragments, scene, renderer, fragmentIfcLoader, culler } =
      await mountViewer()

    this.viewer = viewer
    this.fragments = fragments
    this.loader = fragmentIfcLoader
    this.culler = culler

    this.scene = scene
    this.renderer = renderer
    this.camera = this.viewer.camera.activeCamera

    const { width, height } = this.viewer.renderer._renderer.domElement

    this.viewer.renderer._renderer.setSize(width, height)

    this.viewer.camera.activeCamera.aspect = width / height
    this.viewer.camera.activeCamera.updateProjectionMatrix()

    this.viewer.renderer._renderer.preserveDrawingBuffer = true

    Store.setScene(this.scene)
    Store.setFragments(this.fragments)
    Store.setViewer(this.viewer)

    const checkLowViewer = localStorage.getItem('I♥IFC-lowViewer')

    if (checkLowViewer === 'false') {
      this.mode.horizon = false
      this.checkLowViewer()
    } else {
      this.mode.horizon = true
      this.checkLowViewer()
    }

    this.highlighter = new FragmentHighlighter(this.viewer, this.fragments)

    this.highlighter.add('highlight', [
      new MeshBasicMaterial({
        transparent: true,
        opacity: 1,
        color: '#f00',
        side: DoubleSide,
        depthTest: false
      })
    ])

    this.highlighter.add('translucent', [
      new MeshBasicMaterial({
        transparent: true,
        opacity: 0.75,
        color: '#ccc',
        depthTest: true
      })
    ])

    this.setMeasures()
  }

  private async loadFileAtRender() {
    const reader = new FileReader()

    reader.onload = async () => {
      const arrayBuffer = reader.result
      const uint8Array = new Uint8Array(arrayBuffer as ArrayBufferLike)

      const model = await parseIfcFile(
        uint8Array,
        this.viewer,
        this.loader,
        this.fragments,
        this.culler,
        false
      )

      this.checkCoordinates(Store.properties.coordinationMatrix)

      this.applyCoordinates(model)

      this.loaderIn = false
      this.loadedModel = true

      this.centerModel()
    }

    reader.readAsArrayBuffer(this.fileForViewer as Blob)
  }

  private get _totalToProcess(): number {
    return Store.totalToProcess
  }

  private get currentProgress(): number {
    return Store.currentProgress
  }

  private get stopLoading(): boolean {
    return Store.stopLoading
  }

  private checkLowViewer() {
    this.mode.horizon = !this.mode.horizon

    for (const child of this.viewer.scene.get().children) {
      if (child.type === 'AmbientLight') {
        if (!this.mode.horizon) child.intensity = 0.75
        else child.intensity = 0.5
      }
    }
    if (this.mode.horizon) {
      this.horizon()
      localStorage.setItem('I♥IFC-lowViewer', 'false')
    } else {
      this.scene.remove(this.sky)
      this.scene.remove(this.light)
      localStorage.setItem('I♥IFC-lowViewer', 'true')
    }
  }

  @Watch('_totalToProcess')
  on_totalToProcessChange() {
    this.totalToProcess = this._totalToProcess
  }

  @Watch('currentProgress')
  onCurrentProgressChange() {
    this.current = this.currentProgress
  }

  @Watch('stopLoading')
  onStopLoadingChange() {
    if (!this.stopLoading) {
      this.isLoading = this.stopLoading
    }

    Store.setStopLoading(true)
  }

  @Watch('toCsvFile')
  onToCsvFileChange() {
    if (this.toCsvFile !== null) {
      this.isLoading = true
      processCsv()
    }
  }

  @Watch('fileForViewer')
  onFileForViewerChange() {
    if (this.fileForViewer) {
      this.loadFileAtRender()
      this.loaderIn = true
    } else {
      this.viewer.dispose()
    }
  }

  @Watch('userId')
  onUserIdChange() {
    if (this.userId) this.setTimedText()
  }

  @Watch('selectedExpressIDs')
  onSelectedExpressIDsChange() {
    if (this.selectedExpressIDs.length) {
      this.selectGeometries()
    } else {
      this.highlighter.clear()
      // this..materials.reset()
    }
  }

  @Watch('selectedIfcClasses')
  onSelectedIfcClasses() {
    if (this.selectedIfcClasses.length) {
      this.selectGeometriesPerClasses()
    } else {
      this.highlighter.clear('translucent')
      this.highlighter.clear('highlight')
      this.highlighter.update()

      this.transparentized = true
    }
  }

  @Watch('isFullScreen')
  onIsFullScreenChange() {
    this.renderer._renderer.setSize(window.innerWidth, window.innerHeight)
    this.viewer.update()
  }

  @Watch('counterText')
  onCounterTextChange() {
    this.defCounterText = this.counterText
  }

  @Watch('inputAzimuth')
  onInputAzimuthChange() {
    this.setSunlightConfig()
  }

  @Watch('inputElevation')
  onInputElevationChange() {
    this.setSunlightConfig()
  }

  @Watch('transparentized')
  onTransparentizedChange() {
    if (!this.transparentized) {
      this.transparentized = true

      let groups = {}

      for (const l in this.fragments.list) {
        groups = { ...groups, [l]: this.fragments.list[l].items }
      }

      this.highlighter.highlightByID('translucent', groups, true)
      this.highlighter.update()
    }
  }

  @Emit() openPrompt() {}
  @Emit() finishDownload() {}
  @Emit() resetActions() {}
  @Emit() resetFilesList() {}

  @Emit()
  setNewFilesList() {}

  @Emit()
  assignFilesList(file: File) {
    return file
  }
}
