// import Store from '@/store/modules/Viewer'

export default (fileStr: string) => {
  const link = document.createElement('a')
  document.body.appendChild(link)

  link.download = 'I♥IFC_join.ifc'

  const file = new Blob([fileStr], {
    type: 'text/plain'
  })

  link.href = URL.createObjectURL(file)

  link.click()

  // Store.setFileForViewer(file)

  return file
}
