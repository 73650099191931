// @ts-ignore
import Worker from 'worker-loader!../../workers/getAttributes'
import Store from '@/store/modules/Viewer'

const worker = new Worker()

export default (
  csvProps: any,
  title: string,
  csvUrl: string,
  fileTitle: string,
  properties: any
) => {
  Store.setLoader('Processing csv data')

  Store.setTotalToProcess(Object.keys(csvProps).length)

  worker.postMessage({
    csvProps,
    csvPsets: true,
    allTypes: Store.allTypes,
    properties
  })

  worker.onmessage = ({ data }: any) => {
    const { currentProgress, csvPsetsFileStr } = data

    if (currentProgress) {
      Store.setCurrentProgress(currentProgress)
    } else if (csvPsetsFileStr) {
      // download file-1 properties
      Store.setCsvProcessed({
        csvUrl,
        fileTitle
      })

      const { fileStr } = csvPsetsFileStr

      const fileText = new Blob([fileStr], {
        type: 'text/plain'
      })

      const csvUrl2 = URL.createObjectURL(fileText)

      const fileTitle2 = `I❤IFC_${title.replace('.ifc', '')}_psets.csv`

      const link = document.createElement('a')

      link.href = csvUrl2
      link.download = fileTitle2

      link.click()

      link.remove()

      Store.setLoader('')
      Store.setCurrentProgress(0)
      Store.setTotalToProcess(0)
      Store.setStopLoading(false)
    }
  }
}
