import termsAndConditions from '@/config/legalItems/termsAndConditions'
import privacyPolicy from '@/config/legalItems/privacyPolicy'
import cookiePolicy from '@/config/legalItems/cookiePolicy'
import legalNotice from '@/config/legalItems/legalNotice'

export default [
  {
    title: 'Legal notice',
    description: legalNotice
  },
  {
    title: 'Privacy',
    description: privacyPolicy
  },
  {
    title: 'Terms',
    description: termsAndConditions
  },
  {
    title: 'Cookies',
    description: cookiePolicy
  }
]
