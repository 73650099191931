import { defaultClient, onLogin } from '@/vue-apollo'
import Store from '@/store/modules/Viewer'

export default async (token: string) => {
  try {
    const {
      errors,
      data: { verifyJwt }
    } = await defaultClient.query({
      variables: { token },
      query: require('@/graphql/queries/verify-jwt.graphql')
    })

    if (!errors && verifyJwt) {
      await onLogin(defaultClient, token)
      Store.setUser(verifyJwt)
    } else if (errors) {
      console.error('error' /*error*/)
    }
  } catch (error) {
    localStorage.clear()
    console.error('error' /*error*/)
  }
}
