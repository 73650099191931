
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconLogout from '@/assets/icons/power-off.svg'
import { onLogout } from '@/vue-apollo'
import Store from '@/store/modules/Viewer'
import Avatar from '@/components/shared/Avatar.vue'
import { User } from '@/models'
import IconMail from '@/assets/icons/mail.svg'
import IconILove from '@/assets/icons/icon-ilove.svg'
import IconAdd from '@/assets/icons/add.svg'
import Input from '@/components/shared/Input.vue'
import ModalPayment from '@/components/Home/ModalPayment.vue'
import IconArrow from '@/assets/icons/arrow-circle.svg'
import IconTicket from '@/assets/icons/ticket.svg'
import ModalTicket from '@/components/Home/ModalTicket.vue'

// @ts-ignore
@Component({
  apollo: {
    userEmail: {
      query: require('@/graphql/queries/user-email.graphql')
    }
  },
  components: {
    Avatar,
    IconArrow,
    IconLogout,
    IconMail,
    IconILove,
    IconAdd,
    IconTicket,
    Input,
    ModalPayment,
    ModalTicket
  },
  directives: { onClickaway }
})
export default class UserSettingsPopup extends Vue {
  private mockEmail: string = ''
  private showModalPayment: boolean = false
  private showInputUsername: boolean = false
  private username: string = ''
  private showModalTicket: boolean = false

  private user: User = {
    email: '',
    password: ''
  }

  private get userCredits(): number {
    return Store.userCredits
  }

  private get usernameStore(): string {
    return Store.username
  }

  async mounted() {
    const {
      data: { userEmail }
    } = await this.$apollo.queries.userEmail.refetch()

    this.mockEmail = `${userEmail.slice(0, 4)}******@****`

    if (this.usernameStore) {
      this.username = this.usernameStore
    } else {
      const {
        data: { username }
      } = await this.$apollo.query({
        query: require('@/graphql/queries/username.graphql')
      })
      this.username = username
    }

    this.$root.$on('logout', () => {
      this.logout()
    })
  }

  private logout() {
    Store.setUser({ id: '' })
    Store.setPresentDone(null)

    onLogout(this.$apollo.getClient())
    this.close()

    if (this.$route.name !== 'Home') {
      this.$router.push({ name: 'Home' })
    }
  }

  private showPayment() {
    this.showModalPayment = !this.showModalPayment
  }

  private async selectPayment(plan: string) {
    try {
      const {
        data: { paymentLink }
      } = await this.$apollo.query({
        variables: { plan },
        query: require('../graphql/queries/payment-link.graphql')
      })
      window.location.href = paymentLink
    } catch (error) {
      // console.log(error)
    }
  }

  private async handleUploadAvatar(event: any | null) {
    const image = event.target.files[0]

    this.updateAvatarImage(image)
  }

  private async updateAvatarImage(image: any) {
    try {
      const {
        data: { updateUserInfo }
      } = await this.$apollo.mutate({
        variables: { image },
        mutation: require('@/graphql/mutations/update-user-info.graphql')
      })

      if (updateUserInfo) {
        const avatar = `${process.env.VUE_APP_CLOUDINARY_IMG_PREFIX}${updateUserInfo}`

        Store.setAvatar(avatar)
        this.close()
        this.$toasted.success('User information updated')
      }
    } catch (error) {
      console.error('error' /*error*/)
      this.$toasted.error('Error updating user information')
    }
  }

  private openPrompt() {
    const input = document.getElementById('avatar') as HTMLElement
    input.click()
  }

  private async sendUsername() {
    const username = this.username
    Store.setUsername(username)

    if (username) {
      try {
        const {
          data: { updateUserInfo }
        } = await this.$apollo.mutate({
          variables: { username },
          mutation: require('@/graphql/mutations/update-username.graphql')
        })

        if (updateUserInfo) {
          this.$toasted.success('User information updated')
        }
      } catch (error) {
        console.error('error' /*error*/)
        this.$toasted.error('Error updating user information')
      }
    }

    this.showInputUsername = false
  }

  private setInputUsername() {
    this.showInputUsername = !this.showInputUsername

    setTimeout(() => {
      const input = document.getElementById('username')
      if (input) {
        input.focus()
      }
    }, 100)
  }

  private closeInput() {
    this.showInputUsername = false
  }

  private showTicket() {
    this.showModalTicket = !this.showModalTicket
  }

  @Emit() close() {
    this.username = ''
  }
}
