import {
  Components,
  // MiniMap,
  OrthoPerspectiveCamera,
  // PostproductionRenderer,
  SimpleRaycaster,
  SimpleRenderer,
  SimpleScene
} from 'openbim-components'

/** Initial configs for viewer
 * @param wrapper: the container where the viewer will be placed */
export default (wrapper: HTMLElement) => {
  const viewer = new Components()
  viewer.scene = new SimpleScene(viewer)

  const renderer = new SimpleRenderer(viewer, wrapper)

  // const renderer = new PostproductionRenderer(viewer, wrapper)

  renderer.resize()

  wrapper.addEventListener('resize', () => {
    renderer.resize()
  })

  viewer.renderer = renderer

  const camera = new OrthoPerspectiveCamera(viewer)

  camera.controls.setPosition(100, 25, 100, false).then()

  viewer.camera = camera

  viewer.raycaster = new SimpleRaycaster(viewer)
  viewer.init()

  // renderer.postproduction.enabled = true
  // renderer.postproduction.customEffects.enabled = true
  // renderer.postproduction.customEffects.outlineEnabled = true

  // const minimap = new MiniMap(viewer)
  // viewer.tools.add('minimap', minimap)
  // viewer.ui.add(minimap.uiElement)

  // console.log('⭐viewer.tools⭐', viewer.tools)

  return { viewer, renderer }
}
