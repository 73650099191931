import {
  AmbientLight,
  AxesHelper,
  BoxGeometry,
  Color,
  DirectionalLight,
  GridHelper,
  Mesh,
  MeshStandardMaterial
} from 'three'

import settings from '@/config/scene'
import { Components } from 'openbim-components'

/** Configs for the scene at the viewer
 * @param viewer: instance where the geometries will be rendered */
export default async (viewer: Components) => {
  const scene = await viewer.scene.get()

  const directionalLight = new DirectionalLight()
  directionalLight.position.set(5, 10, 3)
  directionalLight.intensity = settings.global.directionalLightIntensity
  scene.add(directionalLight)

  scene.background = new Color(0xf9fafb)

  const ambientLight = new AmbientLight()
  ambientLight.intensity = settings.global.ambientLightIntensity
  scene.add(ambientLight)

  if (settings.axes.show) {
    const {
      axes: { size, xAxisColor, yAxisColor, zAxisColor }
    } = settings

    const axes = new AxesHelper(size)

    // @ts-ignore
    axes.setColors(xAxisColor, yAxisColor, zAxisColor)
    scene.add(axes)
  }

  if (settings.floor.show) {
    const {
      floor: { width, depth, color, opacity, yPosition }
    } = settings

    const floor = new Mesh(
      new BoxGeometry(width, -1, depth),
      new MeshStandardMaterial({
        color,
        opacity,
        transparent: true
      })
    )

    floor.position.set(0, yPosition, 0)

    scene.add(floor)
  }

  if (settings.grid.show) {
    const {
      grid: { divisions, size, color1, color2 }
    } = settings

    const grid = new GridHelper(size, divisions, color1, color2)

    scene.add(grid)
  }

  return scene
}
