
import { Vue, Component, Emit } from 'vue-property-decorator'
import PaymentCard from './PaymentCard.vue'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  directives: { onClickaway },
  components: { PaymentCard }
})
export default class ModalPayment extends Vue {
  private optionActive: boolean = false

  private options: {
    plan: string
    name: string
    description: string
    ifcHearts: number
    price: number
  }[] = [
    {
      plan: '1',
      name: 'XXXXXXXXX', // 'Starter Pack',
      description: 'XXXXXXXXXXXXX', // 'The best choice to get you started:',
      ifcHearts: 0, // 15,
      price: 0 // 10
    },
    {
      plan: '2',
      name: 'XXXXXX', // 'IFC Lover'
      description: 'XXXXXXXXXXXXX', // 'Out best seller!',
      ifcHearts: 0, // 35,
      price: 0 // 20
    },
    {
      plan: '3',
      name: 'XXXXXX', // 'Intesive User',
      description: 'XXXXXXXXXXXXX', // 'Say you need a lot of operations on a daily basis',
      ifcHearts: 0, // 80,
      price: 0 // 50
    }
  ]

  @Emit() selectOptionPayment(plan: string) {
    return plan
  }

  @Emit() close() {}
}
