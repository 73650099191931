
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconILove from '@/assets/icons/IFCxs.svg'

@Component({
  components: { IconILove }
})
export default class PaymentCard extends Vue {
  @Prop() option!: {
    plan: string
    name: string
    description: string
    ifcHearts: number
    price: number
  }

  @Emit() selectPlan() {}
}
