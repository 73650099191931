
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconPlus from '@/assets/icons/plus.svg'
import IconMinus from '@/assets/icons/minus.svg'
import IconDropBox from '@/assets/icons/dropbox.svg'
import IconDrive from '@/assets/icons/drive.svg'
import IconDownload from '@/assets/icons/download.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Viewer'

@Component({
  components: {
    IconDownload,
    IconDropBox,
    IconDrive,
    IconMinus,
    IconPlus
  },
  directives: { onClickaway }
})
export default class Files extends Vue {
  @Prop() filesList!: File[]

  private showOptionsIndex: number | null = null

  private get activeAction(): string {
    return Store.activeAction
  }

  private get stylePanel(): boolean {
    return Store.stylePanel
  }

  private handleAddFile() {
    if (this.activeAction === 'Join IFC') this.openPrompt()
  }

  private get userHasCredits() {
    return Store.userCredits > 0
  }

  private handleJoinIfc() {
    if (this.filesList.length === 2) {
      this.joinModels()
    } else if (this.userHasCredits && this.filesList.length > 2) {
      this.joinModels()
    }
  }

  private cursorAdd() {
    if (this.activeAction === 'Join IFC') document.body.style.cursor = 'copy'
  }

  private cursorDefault() {
    if (this.activeAction === 'Join IFC') document.body.style.cursor = 'default'
  }

  private get handleUploadTitle(): string {
    return this.activeAction === 'Join IFC'
      ? Store.userCredits > 0 ||
        (Store.userCredits === 0 && this.filesList.length < 2)
        ? 'Click to add more'
        : Store.userCredits === 0 && this.filesList.length > 2
        ? 'Join more than two models with IfcHearts'
        : 'Free-mode allows only two models join'
      : ''
  }

  @Emit() joinModels() {}

  @Emit() openPrompt() {}
}
