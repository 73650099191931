
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import selectItems from '@/controllers/hierarchyStructure/selectItems'
import Store from '@/store/modules/Viewer'

@Component
export default class AttributesAllSelected extends Vue {
  @Prop() item!: any
  @Prop() items!: any[]
  @Prop({ default: false }) isForClasses!: boolean

  private handleSelect() {
    if (!this.isForClasses) {
      if (typeof this.item === 'string') {
        if (this.item === 'MODEL') {
          Store.resetSelectedExpressID()
        } else {
          const ids = []

          for (const i in Store.spatialStructure[this.item]) {
            ids.push(...Store.spatialStructure[this.item][i])
          }

          Store.removeSelectedExpressIDs(ids)
        }
      } else {
        const ids = Object.values(this.item)[0]

        // @ts-ignore
        Store.removeSelectedExpressIDs(ids)
      }

      // selectItems(this.item, this.items, false)
    } else {
      this.classClick()
    }
  }

  @Emit() classClick() {
    return false
  }
}
