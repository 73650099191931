export default (refText: any, value: any) => {
  const regex = new RegExp(/#\d+/, 'g')
  const regex2 = new RegExp(/\d/)

  const found = regex.exec(refText)

  if (found) {
    let defText = ''
    let defValue: number = parseInt(found[0].replace('#', '')) + value

    for (const char of found.input) {
      if (char !== '#' && !regex2.test(char)) {
        defText += char
      } else if (char === '#') {
        defText += `#${defValue}`
      }
    }

    return defText
  } else {
    return refText
  }
}
