
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Tab extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: false }) active!: boolean
  @Prop({ default: false }) textAttributes!: boolean

  @Emit()
  checkTab(title: string) {
    return title
  }
}
