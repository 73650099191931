export default (properties: any) => {
  const headers = new Set(['Name', 'type', 'expressID', 'Description'])

  for (const prop in properties) {
    for (const p in properties[prop]) {
      headers.add(p)
    }
  }

  return Array.from(headers).join(';')
}
