import { UserIn } from '@/models'
import { defaultClient } from '@/vue-apollo'

export default async (user: UserIn, toasted: any) => {
  try {
    const {
      data: { register }
    } = await defaultClient.mutate({
      variables: { user },
      mutation: require('@/graphql/mutations/register.graphql')
    })

    if (register) {
      toasted.success('User registered successfully. Check your email tray')
      return true
    } else {
      toasted.error('There was a problem during register process')
    }
  } catch {
    toasted.error('There was a problem during register process')
  }
}
