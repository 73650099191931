
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import LoadFile from '@/components/shared/LoadFile.vue'
import Store from '@/store/modules/Viewer'
import IFCLoader from '@/assets/icons/IFC.svg'
import AddressesPopup from '@/components/ActionsViewer/MapBox/AddressesPopup.vue'
import mapboxgl from 'mapbox-gl'
import IconMove from '@/assets/icons/arrows-up-down-left-right.svg'
import IconSave from '@/assets/icons/floppy-disk-pen.svg'
import expendOneCredit from '@/helpers/expendOneCredit'
import IconRotate from '@/assets/icons/rotate.svg'
import IconTarget from '@/assets/icons/bullseye.svg'
import axios from 'axios'
import Input from '@/components/shared/Input.vue'
import IconMap from '@/assets/icons/map-location-dot.svg'
import { directive as onClickaway } from 'vue-clickaway'
import IconLock from '@/assets/icons/lock.svg'
import IconUnlock from '@/assets/icons/lock-open.svg'
import IconWorld from '@/assets/icons/earth-americas.svg'
import IconFullScreen from '@/assets/icons/expand.svg'
import IconMinimize from '@/assets/icons/compress.svg'
import IconCamera from '@/assets/icons/camera-viewfinder.svg'
import AttributesTree from '@/components/ActionsViewer/AttributesTree/AttributesTree.vue'
import IconList from '@/assets/icons/list.svg'
import translucentMat from '@/helpers/translucentMat'
import mountViewer from '@/controllers/mountViewer'

import {
  AmbientLight,
  cloneUniformsGroups,
  DirectionalLight,
  DoubleSide,
  MathUtils,
  MeshBasicMaterial,
  PCFSoftShadowMap,
  Vector3
} from 'three'

import {
  Components,
  FragmentHighlighter,
  IfcJsonExporter
} from 'openbim-components'
import { MapboxRenderer } from 'openbim-components/integrations/mapbox/src/mapbox-renderer'

import * as WEBIFC from 'web-ifc'
import parseIfcFile from '@/controllers/parseIfcFile'
import allTypes from '@/config/allTypes'

let map: mapboxgl.Map

let components: Components

let exagerationRatio = 1.5

@Component({
  components: {
    AttributesTree,
    Input,
    AddressesPopup,
    LoadFile,
    IFCLoader,
    IconRotate,
    IconMove,
    IconSave,
    IconTarget,
    IconMap,
    IconLock,
    IconList,
    IconUnlock,
    IconWorld,
    IconFullScreen,
    IconMinimize,
    IconCamera
  },
  directives: { onClickaway }
})
export default class MapBox extends Vue {
  @Prop() isLoad!: boolean
  @Prop() mapboxFile!: File
  @Prop() filesList!: File[]

  private loading: boolean = false
  private addresses: string[] = []
  private showAddressesPopup: boolean = false
  private center: [number, number] = [-8.5457, 42.885]
  private showModelActions: boolean = false
  private modelRotate: boolean = false
  private previousScrollValue: number = 0
  private increase: number = 0
  private modelMove: boolean = false
  private addressForOutputFile: string = ''
  private marker = null
  private coordinates: any[] = [null, null]
  private altitude: number = 0
  private markerLock: boolean = true
  private zRotation: number = 0
  private xRotation: number = 0
  private yRotation: number = 0
  private isFullScreen: boolean = false
  private showAttributesTree: boolean = false
  private model: any = null
  private modelRelocate: boolean = false
  private modelCoordinatesTxt: string = 'Model coordinates'
  private inputX: number = 0
  private inputZ: number = 0
  private viewer: Components | any = []
  private fragments: any = null
  private modelPosition!: Vector3
  private highlighter: any = null
  private scene: any = null
  private renderer: any = null
  private culler: any = null
  private loader: any = null
  private camera: any = null
  private transparentized: boolean = true

  private lastOffset = {
    x: 0,
    y: 0
  }

  private initialPoint = {
    x: 0,
    y: 0
  }

  private show = {
    a: true,
    b: true,
    c: true,
    d: true,
    e: true,
    f: true,
    g: true,
    h: true
  }

  private get fileForViewer(): File {
    return Store.fileForViewer as File
  }

  private get properties(): any {
    return Store.properties
  }

  private get selectedExpressIDs(): number[] {
    return Store.selectedExpressIDs
  }

  private get selectedIfcClasses(): string[] {
    return Store.selectedIfcClasses
  }

  private get spatialStructure(): any {
    return Store.spatialStructure
  }

  async mounted() {
    await this.mountMap()
  }

  private async mountMap() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN as string

    const merc = mapboxgl.MercatorCoordinate
    const coords = merc.fromLngLat(this.center, 5)

    map = new mapboxgl.Map({
      container: 'viewer',
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      zoom: 18,
      center: this.center,
      pitch: 60,
      antialias: true,
      localFontFamily: 'Raleway',
      preserveDrawingBuffer: true
    })

    const { viewer, fragments } = await mountViewer()

    this.viewer = viewer
    this.fragments = fragments

    // @ts-ignore
    this.viewer.renderer = new MapboxRenderer(this.viewer, map, coords, true, {
      // @ts-ignore
      terrain: true,
      exageration: 1.5
    })

    this.viewer.scene.get().background = null

    this.hearEvents()

    map.on('load', () => {
      map.setFog({ 'horizon-blend': 1 })
    })

    map.on('idle', () => {
      map.resize()
    })
  }

  private hearEvents() {
    this.$root.$on(
      'change-location',
      (center: [number, number], address: string) => {
        this.center = center

        map.flyTo({
          center
        })

        this.addressForOutputFile = address
      }
    )

    this.$root.$on('confirm-location', () => {
      if (this.fileForViewer) {
        this.loading = true
        this.newAddScene()

        Store.setFirstLocation(false)
      }
    })

    this.$root.$on('screenshotRelocate', () => {
      this.screenCapture()
    })

    map.on('mousedown', event => {
      if (this.modelMove) {
        map.dragPan.disable()

        this.initialPoint = {
          x: event.point.x,
          y: event.point.y
        }

        this.modelRelocate = true

        map.resetNorth({
          animate: true,
          duration: 1500,
          essential: true
        })
      }
    })

    map.on('mousemove', async event => {
      if (this.modelRelocate) {
        const offset = {
          x: event.point.x - this.initialPoint.x,
          y: event.point.y - this.initialPoint.y
        }

        const p = new mapboxgl.Point(
          this.initialPoint.x - offset.x,
          this.initialPoint.y - offset.y
        )

        const pCoords = map.unproject(p)

        this.center = [pCoords.lng, pCoords.lat]

        this.coordinates = [this.center[0], this.center[1]]

        await this.setMovementModel(offset)
      }

      this.initialPoint = {
        x: event.point.x,
        y: event.point.y
      }
    })

    map.on('mouseup', () => {
      if (this.modelRelocate) {
        this.modelRelocate = false

        this.initialPoint = {
          x: 0,
          y: 0
        }
      }

      const layer = map.getLayer('3d')

      map.dragPan.enable()
    })
  }

  private async getAltitude(coords: number[]) {
    const { data } = await axios.get(
      `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${coords[0]},${coords[1]}.json?layers=contour&limit=50&access_token=${mapboxgl.accessToken}`
    )

    const elevations = data.features.map(
      (f: { properties: { ele: any } }) => f.properties.ele
    )

    this.altitude = Math.max(...elevations)
  }

  private getAddresses() {
    const addressesSet: Set<string> = new Set()

    for (const key in Store.properties) {
      const item = Store.properties[key]
      const { type } = item

      if (type === 'IFCPOSTALADDRESS') {
        const { Country, AddressLines, Town } = item

        if (AddressLines) {
          for (const address of AddressLines) {
            addressesSet.add(`${Country} ${Town} ${address}`)
          }
        }
      }
    }

    this.addresses = Array.from(addressesSet)
    this.loading = false
  }

  // private async updateModelAddress() {
  //   const fileStr = await this.fileForViewer.text()
  //   const lines = fileStr.split(';')
  //   let hasIfcPostalAddress = false
  //   let lastIndex = [0, 0]
  //
  //   let defLines = ''
  //
  //   for (const line of lines) {
  //     if (line.includes('IFCPOSTALADDRESS(')) {
  //       hasIfcPostalAddress = true
  //       let lineReplace = line.substring(0, line.indexOf('('))
  //       lineReplace += `($,$,$,$,('${this.addressForOutputFile}'),$,'','','','');`
  //
  //       defLines += lineReplace
  //     } else {
  //       defLines += `${line};`
  //     }
  //
  //     if (!hasIfcPostalAddress) {
  //       const lineVal = line.split('=')[0]
  //
  //       if (lineVal.includes('#')) {
  //         lastIndex = [
  //           parseInt(lineVal.replace('#', '')) + 1,
  //           lines.indexOf(line)
  //         ]
  //       }
  //     }
  //   }
  //
  //   if (!hasIfcPostalAddress) {
  //     lines.splice(
  //       lastIndex[1],
  //       1,
  //       `#${lastIndex[0]}=IFCPOSTALADDRESS($,$,$,$,('${this.addressForOutputFile}'),$,'','','','');`
  //     )
  //   }
  //
  //   const file = new Blob([defLines], {
  //     type: 'text/plain'
  //   })
  //
  //   const link = document.createElement('a')
  //   link.href = URL.createObjectURL(file)
  //   link.download = `ILoveIFC_relocate.ifc`
  //   link.click()
  //   await expendOneCredit()
  // }

  private rotateModel() {
    const rate = 0.01

    setTimeout(() => {
      const container = document.getElementById('rotate-feat') as HTMLElement
      container.addEventListener('drag', ({ clientX }) => {
        if (clientX !== this.previousScrollValue) {
          if (clientX > this.previousScrollValue) this.increase -= rate
          else this.increase += rate

          for (const child of this.viewer.scene.get().children) {
            child.rotation.y +=
              clientX > this.previousScrollValue ? rate : -rate
          }

          this.previousScrollValue = clientX
        }
      })
    }, 100)
  }

  private moveMarker(value: string, type: number) {
    const val = parseFloat(value)

    map.flyTo({
      center:
        type === 1 ? [this.coordinates[0], val] : [val, this.coordinates[1]]
    })

    this.getAltitude(
      type === 1 ? [this.coordinates[0], val] : [val, this.coordinates[1]]
    )

    this.altitudeModel(this.altitude)
  }

  private altitudeModel(value: number) {
    this.altitude = value

    for (const mesh of this.viewer.meshes) {
      mesh.position.y = this.altitude * exagerationRatio + 6
    }
  }

  private async setMovementModel(offset: { x: number; y: number }) {
    for (const mesh of this.viewer.meshes) {
      if (offset.x) mesh.position.x += offset.x / 3.5
      if (offset.y) mesh.position.z += offset.y / 3.5

      this.modelPosition.x = mesh.position.x
      this.modelPosition.z = mesh.position.z
    }
  }

  private async setInputMovementX(value: number) {
    if (!this.modelMove) {
      this.inputX = value

      for (const mesh of this.viewer.meshes) {
        mesh.position.x = this.modelPosition.x + value
      }
    }
  }

  private async setInputMovementZ(value: number) {
    if (!this.modelMove) {
      this.inputZ = value

      for (const mesh of this.viewer.meshes) {
        mesh.position.z = this.modelPosition.z + value
      }
    }
  }

  private rotateModelZ(value: number) {
    if (value && !this.modelMove) {
      this.zRotation = value
      if (!isNaN(value)) {
        for (const mesh of this.viewer.meshes) {
          mesh.rotation.z = MathUtils.degToRad(value)
        }
      }
    }
  }

  private rotateModelY(value: number) {
    if (value && !this.modelMove) {
      this.yRotation = value
      if (!isNaN(value)) {
        for (const mesh of this.viewer.meshes) {
          mesh.rotation.y = MathUtils.degToRad(value)
        }
      }
    }
  }

  private rotateModelX(value: number) {
    if (value && !this.modelMove) {
      this.xRotation = value
      if (!isNaN(value)) {
        for (const mesh of this.viewer.meshes) {
          mesh.rotation.x = MathUtils.degToRad(value)
        }
      }
    }
  }

  private hideInputA() {
    this.show.a = true
  }

  private hideInputB() {
    this.show.b = true
  }

  private hideInputC() {
    this.show.c = true
  }

  private hideInputD() {
    this.show.d = true
  }

  private hideInputE() {
    this.show.e = true
  }

  private hideInputF() {
    this.show.f = true
  }

  private hideInputG() {
    this.show.g = true
  }

  private hideInputH() {
    this.show.h = true
  }

  // private changeMapMode(style: string) {
  //   for (const layer of map.getStyle().layers) {
  //     if (layer) map.removeLayer(layer.id)
  //   }
  //
  //   map.setStyle(style)
  //
  //   map.setFog({ 'horizon-blend': 1 })
  // }

  private userHasCredits(): boolean {
    return Store.userCredits > 0
  }

  private async screenCapture() {
    const link = document.createElement('a')
    link.download = 'screenshot.png'

    link.href = map.getCanvas().toDataURL()
    link.click()

    await expendOneCredit(5, false, '')
  }

  private async newAddScene() {
    const { viewer, fragments, scene, renderer, fragmentIfcLoader, culler } =
      await mountViewer()

    this.viewer = viewer
    this.fragments = fragments
    this.loader = fragmentIfcLoader
    this.culler = culler

    this.scene = scene
    this.renderer = renderer
    this.camera = this.viewer.camera.activeCamera

    const { width, height } = this.viewer.renderer._renderer.domElement

    this.viewer.renderer._renderer.setSize(width, height)

    this.viewer.camera.activeCamera.aspect = width / height
    this.viewer.camera.activeCamera.updateProjectionMatrix()

    this.viewer.renderer._renderer.preserveDrawingBuffer = true

    this.highlighter = new FragmentHighlighter(this.viewer, this.fragments)

    this.highlighter.add('highlight', [
      new MeshBasicMaterial({
        transparent: true,
        opacity: 1,
        color: '#f00',
        side: DoubleSide,
        depthTest: false
      })
    ])

    this.highlighter.add('translucent', [
      new MeshBasicMaterial({
        transparent: true,
        opacity: 0.75,
        color: '#ccc',
        depthTest: true
      })
    ])

    const reader = new FileReader()

    reader.onload = async () => {
      const arrayBuffer = reader.result
      const uint8Array = new Uint8Array(arrayBuffer as ArrayBufferLike)

      await parseIfcFile(
        uint8Array,
        viewer,
        fragmentIfcLoader,
        this.fragments,
        culler,
        true
      )
    }

    this.viewer.scene.get().background = null

    reader.readAsArrayBuffer(this.fileForViewer as Blob)

    const merc = mapboxgl.MercatorCoordinate
    const coords = merc.fromLngLat(this.center, 10)

    // @ts-ignore
    this.viewer.renderer = new MapboxRenderer(this.viewer, map, coords, false)

    this.setCenter()

    this.coordinates = [this.center[0], this.center[1]]

    this.showModelActions = true

    // Dispose culler
    this.culler.dispose()

    await this.getAltitude(this.center)

    setTimeout(() => {
      for (const mesh of this.viewer.meshes) {
        mesh.position.y = this.altitude * exagerationRatio + 6
        mesh.rotation.x = MathUtils.degToRad(-6)
        mesh.rotation.z = MathUtils.degToRad(5)
        mesh.castShadow = true
        mesh.reciveShadow = true
      }
    }, 400)

    this.modelPosition = new Vector3(0, this.altitude, 0)
  }

  private centerMap() {
    map.flyTo({
      center: this.center,
      animate: true,
      duration: 1000,
      essential: true
    })
  }

  private setCenter() {
    const center = new mapboxgl.LngLat(this.center[0], this.center[1])
    map.setCenter(center)
  }

  private selectGeometries() {
    if (this.transparentized) this.transparentized = false
    let groups: any = {}

    for (const expressId of this.selectedExpressIDs) {
      const group = this.fragments.groups[0].items

      for (const g in group) {
        if (group[g].items.includes(expressId)) {
          const meshUuid = group[g].mesh.uuid

          if (groups[meshUuid] !== undefined) {
            groups[meshUuid].push(expressId)
          } else {
            groups = { ...groups, [meshUuid]: [expressId] }
          }
        }
      }
    }

    this.highlighter.highlightByID('highlight', groups, true)
    this.highlighter.update()
  }

  private async selectGeometriesPerClasses() {
    const allTypesArr: any[] = []

    for (const cl of this.selectedIfcClasses) {
      for (const c in allTypes) {
        // @ts-ignore
        if (allTypes[c] === cl) {
          allTypesArr.push(c)
          break
        }
      }
    }

    const expressIds = []

    for (const p in Store.properties) {
      if (p !== 'coordinationMatrix') {
        if (allTypesArr.includes(Store.properties[p].type.toString())) {
          expressIds.push(Store.properties[p].expressID.toString())
        }
      }
    }

    let groups: { [p: string]: string[] } = {}

    for (const id of expressIds) {
      for (const fragment of this.fragments.groups[0].items) {
        if (fragment.items.includes(id)) {
          if (!groups[fragment.mesh.uuid]) {
            groups = { ...groups, [fragment.mesh.uuid]: [id] }
          } else {
            groups[fragment.mesh.uuid].push(id)
          }
        }
      }
    }

    if (this.transparentized) this.transparentized = false

    setTimeout(() => {
      this.highlighter.highlightByID('highlight', groups, true)
      this.highlighter.update()
    }, 50)
  }

  private checkManualModelMove() {
    this.modelMove = !this.modelMove
    this.showAttributesTree = false
    this.modelRotate = false
  }

  private checkRelocate() {
    this.showAddressesPopup = !this.showAddressesPopup
    this.modelMove = false
    this.modelRotate = false
    this.showAttributesTree = false
  }

  private checkAttributes() {
    this.showAttributesTree = !this.showAttributesTree
    this.modelMove = false
    this.modelRotate = false
    this.showAddressesPopup = false
  }

  @Watch('selectedExpressIDs')
  onSelectedExpressIDsChange() {
    if (this.selectedExpressIDs.length) {
      this.selectGeometries()
    } else {
      this.highlighter.clear()
    }
  }

  @Watch('selectedIfcClasses')
  onSelectedIfcClasses() {
    if (this.selectedIfcClasses.length) {
      this.selectGeometriesPerClasses()
    } else {
      this.highlighter.clear('translucent')
      this.highlighter.clear('highlight')
      this.highlighter.update()

      this.transparentized = true
    }
  }

  @Watch('properties')
  onPropertiesChange() {
    if (this.properties) this.getAddresses()
  }

  @Watch('fileForViewer')
  onFileForViewerChange() {
    if (this.fileForViewer) {
      this.showAddressesPopup = true
    }
  }

  @Watch('modelRotate')
  onModelRotateChange() {
    if (this.modelRotate) this.rotateModel()
  }

  @Watch('transparentized')
  onTransparentizedChange() {
    if (!this.transparentized) {
      this.transparentized = true

      let groups = {}

      for (const l in this.fragments.list) {
        groups = { ...groups, [l]: this.fragments.list[l].items }
      }

      this.highlighter.highlightByID('translucent', groups, true)
      this.highlighter.update()
    }
  }

  @Watch('spatialStructure')
  onSpatialStructureChange() {
    if (this.spatialStructure) {
      this.loading = false
    }
  }
  @Emit() openPrompt() {}
}
