import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
// @ts-ignore
import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client'

const cookieName: string = process.env.VUE_APP_COOKIE as string
const httpEndpoint: string = process.env.VUE_APP_GRAPHQL_HTTP as string

const cache = new InMemoryCache()

const authLink = setContext(async (_, { headers }) => {
  const token =
    localStorage.getItem(cookieName) !== undefined
      ? localStorage.getItem(cookieName)
      : ''

  if (token !== null) {
    return {
      headers: {
        ...headers,
        authorization: token,
        'Access-Control-Allow-Origin': '*'
      }
    }
  }
})

const uploadLink = createUploadLink({ uri: httpEndpoint })

const defLink = ApolloLink.from([authLink, uploadLink])

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  uploadLink,
  defLink
)

export const defaultClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true
})

export const apolloProvider = new VueApollo({
  defaultClient
})

Vue.use(VueApollo)

export async function onLogin(apolloClient: any, token: string) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(cookieName, token)
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)

  try {
    await apolloClient.resetStore()
  } catch (error) {
    console.error(
      '%cError on cache reset (login)',
      'color: orange;'
      // error.message
    )
  }
}

export async function onLogout(apolloClient: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(cookieName)
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)

  try {
    await apolloClient.resetStore()
  } catch (error) {
    console.error(
      '%cError on cache reset (logout)',
      'color: orange;'
      // error.message
    )
  }
}
