export default () => {
  const div = document.createElement('div')

  div.style.width = '100vw'
  div.style.height = '100vh'
  div.style.zIndex = '1000'
  div.style.position = 'absolute'
  div.style.top = '0'
  div.style.left = '0'
  div.style.display = 'flex'
  div.style.alignItems = 'center'
  div.style.justifyContent = 'center'
  div.style.color = 'red'
  div.style.fontSize = '2rem'

  const p = document.createElement('p')
  p.textContent = 'Welcome to I❤IFC. We`re loading'

  div.appendChild(p)
  document.body.appendChild(div)

  return div
}
