
import { Component, Vue, Watch } from 'vue-property-decorator'
import Actions from '@/components/ActionsViewer/Actions.vue'
import Viewer from '@/components/ActionsViewer/Viewer.vue'
import Store from '@/store/modules/Viewer'
import MapBox from '@/components/ActionsViewer/MapBox/MapBox.vue'
import ModalRegister from '@/components/Home/ModalRegister.vue'
import Footer from '@/components/ActionsViewer/Footer.vue'
import IconCredits from '@/assets/icons/money-bill-wave.svg'
import expendOneCredit from '@/helpers/expendOneCredit'
import splitModel from '@/controllers/splitModel'
import checkValidSelectionFreeMode from '@/helpers/checkValidSelectionFreeMode'
import SnapshotPopup from '@/components/Home/SnapshotPopup.vue'

@Component({
  components: {
    Actions,
    Footer,
    IconCredits,
    ModalRegister,
    MapBox,
    SnapshotPopup,
    Viewer
  }
})
export default class ActionsViewer extends Vue {
  private filesList: File[] = []
  private isLoadFile: boolean = false
  private showModal: boolean = false
  private loadingFiles: boolean = false
  private showBetaTesterModal: boolean = false
  private fileForSplitIfc: string | null = ''
  private replaceFile: any = null

  private show = {
    spatialTree: false,
    attributesTree: false,
    joinIfc: false
  }

  private get activeAction(): string {
    return Store.activeAction
  }

  private get stylePanel(): boolean {
    return Store.stylePanel
  }

  mounted() {
    this.hearEvents()

    if (this.userId) {
      this.setUserCredits()
    }

    setTimeout(() => {
      if (this.$route.query.find !== undefined && this.$route.query.find) {
        const paymentId = this.$route.query.find

        this.$router.replace({
          query: {}
        })

        // @ts-ignore
        this.proceedPayment(paymentId)
      }
    }, 200)
  }

  private hearEvents() {
    this.$root.$on('show-modal', () => {
      this.showModal = true
    })

    this.$root.$on('action-navbar', (action: string) => {
      if (action === 'Join IFC' || action === 'Get Attributes') {
        Store.setActiveAction(action)
      }
    })

    this.$root.$on('download-csv', () => {
      this.loadingFiles = true
      this.setCsvFile()
    })

    this.$root.$on('split-model', async () => {
      this.fileForSplitIfc = await this.filesList[0].text()
    })

    this.$root.$on('drop-files', (files: File[]) => {
      this.handleUploadedFiles(files)
    })
  }

  private openPrompt() {
    if (Store.userId) {
      const input = document.getElementById('file') as HTMLElement
      input.click()
    } else {
      this.showModal = true
    }
  }

  private async handleUploadedFiles(files: File[]) {
    this.loadingFiles = true

    for (const file of files) {
      this.filesList.push(file)
      Store.setFileForViewer(file)
    }

    if (this.activeAction === 'Split IFC') {
      this.show.spatialTree = true
    } else if (this.activeAction === 'Get Attributes') {
      this.show.attributesTree = true
    } else if (this.activeAction === 'Relocate IFC') {
      this.isLoadFile = true
    }

    this.loadingFiles = false

    setTimeout(() => {
      this.$root.$emit('center-model')
    }, 200)
  }

  private get userId(): string {
    return Store.userId
  }

  private setCsvFile() {
    Store.setFileForCsvDownload(this.filesList[0])
  }

  private get csvProcessed() {
    return Store.csvProcessed
  }

  private downloadCsv() {
    if (this.csvProcessed) {
      const link = document.createElement('a')
      link.download = `${this.csvProcessed.fileTitle}`
      link.href = this.csvProcessed.csvUrl
      link.click()

      this.loadingFiles = false
    }
  }

  private async setUserCredits() {
    try {
      const {
        data: { getUserCredits }
      } = await this.$apollo.query({
        query: require('@/graphql/queries/get-user-credits.graphql')
      })

      Store.setUserCredits(getUserCredits)
    } catch (error) {
      // console.error('error', error)
      this.$toasted.error('Error retrieving IfcHearts')
    }
  }

  private setActiveAction(action: string) {
    Store.setActiveAction(action)
  }

  private get userCannotSplit(): boolean {
    return (
      Store.userCredits === 0 &&
      (Store.selectedIfcClasses.length > 1 || !checkValidSelectionFreeMode())
    )
  }

  private async handleSplitModel() {
    if (this.userCannotSplit) {
      this.$toasted.error('You need IfcHearts to split +1 selections')
    } else {
      this.loadingFiles = true

      setTimeout(() => {
        this.split()
      }, 50)
    }
  }

  private async split() {
    try {
      if (!checkValidSelectionFreeMode() && !Store.userCredits) {
        this.$toasted.error('You need IfcHearts to split multiple-selection')
        this.fileForSplitIfc = null
        this.loadingFiles = false
      } else if (this.fileForSplitIfc) {
        const fileText = splitModel(this.fileForSplitIfc)

        if (fileText) {
          const file = new Blob([fileText])
          const url = URL.createObjectURL(file)

          const link = document.createElement('a')

          link.href = url

          link.download = `${
            Store.selectedIfcClasses.length
              ? Store.selectedIfcClasses[0]
              : this.filesList[0].name
          }_ILoveIFC-split.ifc`

          link.click()

          await this.checkExpendIfcHeart()

          this.fileForSplitIfc = null
          this.loadingFiles = false

          this.$toasted.success('Split IFC done successfully')
        }
      }
    } catch (error) {
      console.error('error')
    }
  }

  private async checkExpendIfcHeart() {
    if (Store.selectedIfcClasses.length > 2 || !checkValidSelectionFreeMode()) {
      await expendOneCredit(4, false, '')
    } else {
      await expendOneCredit(54, true, '')
    }
  }

  private resetActions() {
    Store.setActiveAction('')
    Store.setFlatStructureItems([])
    Store.resetSelectedIfcClass()
    Store.resetSelectedExpressID()
    this.show.spatialTree = false
    this.show.attributesTree = false
  }

  private async proceedPayment(paymentId: string) {
    try {
      const {
        data: { increaseIfcHearts }
      } = await this.$apollo.mutate({
        variables: { paymentId },
        mutation: require('../../graphql/mutations/increase-ifc-hearts.graphql')
      })

      if (increaseIfcHearts) {
        Store.setUserCredits(increaseIfcHearts)
        this.$toasted.success('❤ IfcHearts purchased properly ❤')
      }
    } catch {
      this.$toasted.error('Error during process')
    }
  }

  private refreshFilesList() {
    this.filesList = [new File([], 'ILoveIFC_join.ifc')]
  }

  @Watch('userId')
  onUserIdChange() {
    if (this.userId) {
      this.setUserCredits()
    }
  }

  @Watch('csvProcessed', { deep: true })
  onCsvProcessedChange() {
    if (
      this.csvProcessed &&
      this.csvProcessed.csvUrl &&
      this.csvProcessed.fileTitle
    ) {
      this.downloadCsv()

      if (
        Store.selectedIfcClasses.length > 1 ||
        !checkValidSelectionFreeMode()
      ) {
        52
      }

      Store.setFileForCsvDownload(null)
    }
  }

  @Watch('activeAction')
  onActiveActionChange() {
    this.$root.$emit('close-indication')

    if (Store.fileForViewer !== null) {
      if (this.activeAction === 'Get Attributes') {
        this.show = {
          attributesTree: true,
          spatialTree: false,
          joinIfc: false
        }
      } else if (this.activeAction === 'Split IFC') {
        this.show = {
          attributesTree: false,
          spatialTree: true,
          joinIfc: false
        }
      } else if (this.activeAction === 'Join IFC') {
        this.show = {
          attributesTree: false,
          spatialTree: false,
          joinIfc: true
        }
      }
    }
  }

  @Watch('fileForSplitIfc')
  onFileForSplitIfcChange() {
    if (this.fileForSplitIfc) {
      this.handleSplitModel()
    }
  }
}
