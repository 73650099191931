import { render, staticRenderFns } from "./RegisterCard.vue?vue&type=template&id=dccf5dd2&"
import script from "./RegisterCard.vue?vue&type=script&lang=ts&"
export * from "./RegisterCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports