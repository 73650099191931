import ifcProducts from '@/config/IFC/ifcProducts'

export default (lines: any): boolean => {
  let counter = 0

  for (const key in lines) {
    const item = lines[key]

    if (
      !ifcProducts.includes(lines[key].method) &&
      item.ifcProducts &&
      item.ifcProducts.length &&
      item.params &&
      item.params.length
    ) {
      for (const param of item.params) {
        for (const ifcProduct of item.ifcProducts) {
          if (
            lines[param].ifcProducts &&
            !lines[param].ifcProducts.includes(ifcProduct)
          ) {
            lines[param].ifcProducts.push(ifcProduct)
            lines[param].sender.push(key)
            counter++
            break
          }
        }
      }
    }
  }

  return counter > 0
}
