
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store/modules/Viewer'
import AttributesNoneSelected from '@/components/ActionsViewer/AttributesTree/AttributesNoneSelected.vue'
import AttributesAllSelected from '@/components/ActionsViewer/AttributesTree/AttributesAllSelected.vue'
import { IfcCategoryMap } from 'openbim-components'
import ifcProducts from '@/config/IFC/ifcProducts'

@Component
export default class IFCClasses extends Vue {
  private noneSelected: Vue.Component = AttributesNoneSelected
  private isSelected: Vue.Component = AttributesAllSelected

  private get classes(): string[] {
    const classes: Set<string> = new Set()

    for (const key in Store.properties) {
      const className = IfcCategoryMap[Store.properties[key].type]

      if (ifcProducts.includes(className)) {
        classes.add(className)
      }
    }

    return Array.from(classes).sort()
  }

  private get selected(): string[] {
    return Store.selectedIfcClasses
  }

  private select(classe: string, status: boolean) {
    if (this.selected.includes(classe) && !status) {
      Store.removeSelectedIfcClass(classe)
    } else if (!this.selected.includes(classe) && status) {
      Store.addSelectedIfcClass(classe)
    }
  }
}
