export default {
  axes: {
    show: false,
    size: 10,
    xAxisColor: '#63c735',
    yAxisColor: '#f55193',
    zAxisColor: '#d7c226'
  },
  floor: {
    show: false,
    width: 1000,
    depth: 1000,
    color: '#888',
    opacity: 0.5,
    yPosition: -5
  },
  global: {
    backgroundColor: '#fff',
    ambientLightIntensity: 0.25,
    directionalLightIntensity: 0.25
  },
  grid: {
    show: false,
    divisions: 10,
    size: 100,
    color1: '#eee',
    color2: '#eee'
  }
}
